@font-face {
  font-family: "Helvetica";
  src: url("../../../fonts/HelveticaNeue-Thin.eot");
  src: url("../../../fonts/HelveticaNeue-Thin.eot?#iefix") format("embedded-opentype"), url("../../../fonts/HelveticaNeue-Thin.woff2") format("woff2"), url("../../../fonts/HelveticaNeue-Thin.woff") format("woff"), url("../../../fonts/HelveticaNeue-Thin.ttf") format("truetype"), url("../../../fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../../fonts/HelveticaNeue-ThinItalic.eot");
  src: url("../../../fonts/HelveticaNeue-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../../../fonts/HelveticaNeue-ThinItalic.woff2") format("woff2"), url("../../../fonts/HelveticaNeue-ThinItalic.woff") format("woff"), url("../../../fonts/HelveticaNeue-ThinItalic.ttf") format("truetype"), url("../../../fonts/HelveticaNeue-ThinItalic.svg#HelveticaNeue-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../../fonts/HelveticaNeue-Light.eot");
  src: url("../../../fonts/HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"), url("../../../fonts/HelveticaNeue-Light.woff2") format("woff2"), url("../../../fonts/HelveticaNeue-Light.woff") format("woff"), url("../../../fonts/HelveticaNeue-Light.ttf") format("truetype"), url("../../../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../../fonts/HelveticaNeue.eot");
  src: url("../../../fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"), url("../../../fonts/HelveticaNeue.woff2") format("woff2"), url("../../../fonts/HelveticaNeue.woff") format("woff"), url("../../../fonts/HelveticaNeue.ttf") format("truetype"), url("../../../fonts/HelveticaNeue.svg#HelveticaNeue") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../../fonts/HelveticaNeue-Medium.eot");
  src: url("../../../fonts/HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"), url("../../../fonts/HelveticaNeue-Medium.woff2") format("woff2"), url("../../../fonts/HelveticaNeue-Medium.woff") format("woff"), url("../../../fonts/HelveticaNeue-Medium.ttf") format("truetype"), url("../../../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
:root {
  --black: #000000;
  --white: #FFFFFF;
  --gray: #e6e6e6;
  --gray-dark: #4D4D4D;
  --gray-light: #b3b3b3;
  --gray-medium: #666666;
  --beige: #e7d6c8;
  --beige-dark: #7c4848;
  --beige: #e7d6c8;
  --beige-dark: #d1b8a6;
  --brown: #231f20;
  --green: #A1C5C5;
  --green-dark: #224344;
  --green-darker: #1C3A3A;
  --green-darkest: #0b2525;
  --green-light: #EDF5F5;
  --red: #ff0000;
  --font-default: "Helvetica";
  --bloat: 20%;
}
.title--big, .offwhite-collections__visit .details__title, .category-listing__group__display__wrap .title, .homepage__social--title, .homepage__special-offers--title, .homepage__shop-the-look--title, .homepage__best-sellers--title, .homepage__sub-recommended--title, .homepage__recommended--title, .homepage__section_six--title, .homepage__landing--cta span:last-child, .homepage__landing--title {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 4.84375vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
}
.title--medium, .title--team {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 3.28125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.title--small, .offwhite-collections__visit .details__text span, .offwhite-collections__visit .details__text a, .offwhite-collections__visit .details__subtitle, .account-titles, .account-title, .title--out-of-stock, .category-listing__item.out-of-stock .card_view--image:after {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}

.text {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}
.text--team {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.text--breadcrumbs, .js-select2-custom-dropdown .select2-results__option, .js-select2-custom.select2-container--default .select2-selection--single, .select2-container--default .select2-results__option {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.text--breadcrumbs--static {
  font-family: var(--font-default), sans-serif;
  font-size: max(10px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.text--light {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.text--light--big, .account-texts, .account-text {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.text--normal, .jobs-container .job--description p {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}
.text--small {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}

.details {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}
.details--small {
  font-family: var(--font-default), sans-serif;
  font-size: max(8px, 0.4166666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.details--big {
  font-family: var(--font-default), sans-serif;
  font-size: max(24px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.details--big-thin {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.25vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--medium-thin {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.09375vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--discover, .details--pre-order {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--view-product {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--appointment {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.9375vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.details--pop-up-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.details--pop-up-list {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--pop-up-cta {
  font-family: var(--font-default), sans-serif;
  font-size: max(17px, 1.25vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--shop-the-look {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.1458333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.details--italic {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: italic;
  line-height: 1;
}
.details--sizes {
  font-family: var(--font-default), sans-serif;
  font-size: max(10px, 0.625vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}

.menu__category, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.menu__sub-category, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .menu .main-menu, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .title-submenu, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-category-link {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

*:visited,
*:focus,
*:active {
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

::-moz-selection {
  color: var(--white);
  background: var(--green-dark);
}

::selection {
  color: var(--white);
  background: var(--green-dark);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html,
body {
  height: auto;
  width: 100%;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-family: var(--font-default), sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0;
}
body .header--wrapper {
  background-color: var(--white);
}
body #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
  background-color: var(--white);
}
body.page_story {
  background-color: #d8d8d8;
}
body.page_story .header--wrapper {
  background-color: #d8d8d8;
}
body.page_story #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
  background-color: #d8d8d8;
}
body.home, body.page_blog, body.page_careers, body.page_team, body.page_store, body.page_stocklist, body.page_previous_collection {
  background-color: var(--beige);
}
body.home .header--wrapper, body.page_blog .header--wrapper, body.page_careers .header--wrapper, body.page_team .header--wrapper, body.page_store .header--wrapper, body.page_stocklist .header--wrapper, body.page_previous_collection .header--wrapper {
  background-color: var(--beige);
}
body.home #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_blog #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_careers #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_team #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_store #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_stocklist #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu, body.page_previous_collection #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
  background-color: var(--beige);
}
body.page_blog.page_home {
  background-color: var(--white);
}
body.page_blog.page_home .header--wrapper {
  background-color: var(--white);
}
body.page_blog.page_home #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
  background-color: var(--white);
}

a:hover, a:visited, a:focus {
  color: unset;
}

main {
  display: flex;
  flex-direction: column;
}

h1 {
  margin: unset;
}

h2 {
  margin: unset;
}

h3 {
  margin: unset;
}

h4 {
  margin: unset;
}

h5 {
  margin: unset;
}

h6 {
  margin: unset;
}

p {
  margin: unset;
  margin-bottom: 15px;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
a:visited, a:hover, a:active, a:focus {
  text-decoration: none;
  outline: none;
}

b,
strong {
  font-weight: bold;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

img,
picture {
  border: none;
  height: auto;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
}

svg {
  display: inline-block;
}

.post,
.page {
  margin: 0;
}

body {
  padding-top: var(--header-height);
}

.cta, #quickcheckoutconfirm .popup-login .login-fields--wrap button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(12px, 0.625vw);
  padding-bottom: max(12px, 0.625vw);
  padding-left: max(12px, 0.625vw);
  padding-right: max(12px, 0.625vw);
  border-radius: unset;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--brown);
  color: var(--white);
  border-color: var(--brown);
}
.cta:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}
.cta--full {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(9px, 0.46875vw);
  padding-bottom: max(9px, 0.46875vw);
  padding-left: max(12px, 0.625vw);
  padding-right: max(12px, 0.625vw);
  border-radius: unset;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--brown);
  color: var(--white);
  border-color: var(--brown);
  width: 100%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.cta--full:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}
.cta--shop-the-look {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(8.5px, 0.4427083333vw);
  padding-bottom: max(8.5px, 0.4427083333vw);
  padding-left: max(61.5px, 3.203125vw);
  padding-right: max(61.5px, 3.203125vw);
  border-radius: unset;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--brown);
  color: var(--white);
  border-color: var(--brown);
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.cta--shop-the-look:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}
.cta--outlined {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(8.5px, 0.4427083333vw);
  padding-bottom: max(8.5px, 0.4427083333vw);
  padding-left: max(61.5px, 3.203125vw);
  padding-right: max(61.5px, 3.203125vw);
  border-radius: unset;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--brown);
  color: var(--white);
  border-color: var(--brown);
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.cta--outlined:hover {
  background-color: transparent;
  color: #000;
  border-color: #000;
}
.cta--outlined--white {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(8.5px, 0.4427083333vw);
  padding-bottom: max(8.5px, 0.4427083333vw);
  padding-left: max(61.5px, 3.203125vw);
  padding-right: max(61.5px, 3.203125vw);
  border-radius: unset;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--white);
  color: var(--black);
  border-color: var(--white);
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  border-radius: 100px;
  color: var(--black) !important;
}
.cta--outlined--white:hover {
  background-color: var(--black);
  color: var(--black);
  border-color: var(--black);
}
.cta--outlined--white:hover {
  border-color: var(--white);
  color: var(--white) !important;
  background-color: transparent;
}
.cta--account {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(20px, 1.0416666667vw);
  padding-bottom: max(20px, 1.0416666667vw);
  padding-left: max(20px, 1.0416666667vw);
  padding-right: max(20px, 1.0416666667vw);
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #333333;
  color: var(--white) !important;
  border-color: #333333;
  display: flex;
  width: 66.666%;
  max-width: 400px;
  margin: auto;
  text-decoration: none !important;
}
.cta--account:hover {
  background-color: transparent;
  color: #333333 !important;
  border-color: #333333 !important;
}
.cta--account-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1;
  padding-top: max(12px, 0.625vw);
  padding-bottom: max(12px, 0.625vw);
  padding-left: max(12px, 0.625vw);
  padding-right: max(12px, 0.625vw);
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--white);
  color: var(--black);
  border-color: var(--white);
  width: 100%;
}
.cta--account-small:hover {
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
}

.modal-open {
  overflow: hidden;
}

.modal-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.9375vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}

.modal-body {
  padding: clamp(15px, 2.0833vw, 40px);
}
.modal-body h2,
.modal-body h2,
.modal-body h3,
.modal-body h4,
.modal-body h5,
.modal-body h6 {
  font-weight: 500;
}

.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.overlay_card {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.65);
  opacity: 0;
  pointer-events: none;
}
.overlay_card-description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.0625vw;
  width: 23.2291666667vw;
  margin: auto;
  color: var(--white);
  width: 100%;
  max-width: 90%;
}
.overlay_card-description .out-of-stock {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.1458333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
}
.overlay_card-description .pre-order {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .overlay_card-description {
    max-width: 82%;
  }
}
.overlay_card-options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1.0416666667vw;
  padding-left: 1.8229166667vw;
  padding-right: 1.8229166667vw;
}
.overlay_card-options > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  flex: 1;
}
.overlay_card-center {
  text-align: center;
  flex: 2;
  margin-bottom: 10px;
}

.card_view--active {
  opacity: 0;
  pointer-events: none;
}
.card_view--image:hover .overlay_card {
  opacity: 1;
}
.card_view:hover .card_view--image.is-hidden {
  visibility: visible;
  opacity: 1;
}
.card_view--image {
  display: block;
  position: relative;
}
.card_view--image a {
  display: flex;
}
.card_view--image.is-hidden {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.card_view--image .cta-wrap {
  -webkit-transition: all 0 ease-in-out;
  -moz-transition: all 0 ease-in-out;
  -o-transition: all 0 ease-in-out;
  transition: all 0 ease-in-out;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
}
.card_view--image .cta-wrap .cta, .card_view--image .cta-wrap #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .card_view--image .cta-wrap button {
  padding-left: 3.125vw;
  padding-right: 3.125vw;
}
.card_view--footer {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  padding-top: 15px;
  padding-bottom: 5px;
  height: auto;
  max-height: 70px;
  padding-right: 3px;
}
.card_view--footer--space {
  padding-left: 5px;
  padding-right: 5px;
}
.card_view--footer > * {
  display: flex;
  justify-content: space-between;
}
.card_view--footer-top {
  margin-bottom: 5px;
}
.card_view--footer-bottom {
  min-height: 24px;
  align-items: flex-end;
}
.card_view--footer-bottom .card_view--footer-cta .underline--black:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #d1b8a6;
  transition: width 0.8s;
}
.card_view--footer-bottom .card_view--footer-cta .underline--black:hover:after {
  width: 101%;
}
.card_view--footer-title:hover {
  color: inherit;
  text-decoration: none;
}
.card_view--footer-favorites {
  display: flex;
  cursor: pointer;
}
.card_view--footer-favorites svg,
.card_view--footer-favorites img {
  width: 14px;
  height: 12px;
}
.card_view--footer-favorites svg g path:first-child {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  fill: transparent;
}
.card_view--footer-favorites:hover svg g path:first-child, .card_view--footer-favorites.is-favorite svg g path:first-child {
  fill: unset;
}
.card_view--footer-price span {
  display: block;
}
.card_view--footer-price span.old-price {
  text-decoration: line-through;
  font-style: italic;
}
.card_view--favorites {
  display: flex;
  cursor: pointer;
}
.card_view--favorites svg,
.card_view--favorites img {
  width: 14px;
  height: 12px;
}
.card_view--favorites svg g path:first-child {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  fill: transparent;
}
.card_view--favorites:hover svg g path:first-child, .card_view--favorites.is-favorite svg g path:first-child {
  fill: unset;
}
.card_view--item-trigger {
  position: absolute;
  top: var(--trigger-top);
  left: var(--trigger-left);
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.card_view.onPoint .card_view--active {
  opacity: 1;
  pointer-events: all;
}
.card_view.onPoint .card_view--footer {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  padding: 0;
}
.card_view.onPoint .card_view--image .cta-wrap {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  pointer-events: all;
}
.card_view.onPoint .card_view--item-trigger {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  pointer-events: all;
}

.swiper-slide.onPoint .card_view--active-cta-view {
  opacity: 1 !important;
  pointer-events: all !important;
}

.underline--white, .underline--gray, .underline--black, .underline--black--rs-swap, .underline--team {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 3px;
}
.underline--white a, .underline--gray a, .underline--black a, .underline--black--rs-swap a, .underline--team a {
  color: inherit;
  text-decoration: none;
}
.underline--white:before, .underline--gray:before, .underline--black:before, .underline--black--rs-swap:before, .underline--team:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  display: flex;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1px;
}
.underline--white.small-cta, .underline--gray.small-cta, .underline--black.small-cta, .underline--black--rs-swap.small-cta, .underline--team.small-cta {
  font-size: 12px;
}
.underline--white:hover, .underline--white:focus, .underline--gray:hover, .underline--gray:focus, .underline--black:hover, .underline--black:focus, .underline--black--rs-swap:hover, .underline--black--rs-swap:focus, .underline--team:hover, .underline--team:focus {
  text-decoration: none;
}
.underline--white:before {
  background-color: var(--white);
}
.underline--white.hover-anim:hover, .underline--white.hover-anim:focus {
  color: var(--black);
}
.underline--white.hover-anim:hover:before, .underline--white.hover-anim:focus:before {
  background-color: var(--black);
}
.underline--black:before {
  background-color: var(--black);
}
.underline--black.hover-anim:hover, .underline--black.hover-anim:focus {
  color: var(--black);
}
.underline--black.hover-anim:hover:before, .underline--black.hover-anim:focus:before {
  background-color: var(--black);
}
.underline--black--rs-swap:before {
  background-color: var(--black);
}
@media screen and (max-width: 991px) {
  .underline--black--rs-swap:before {
    background-color: var(--white);
  }
}
.underline--black--rs-swap.hover-anim:hover, .underline--black--rs-swap.hover-anim:focus {
  color: var(--black);
}
@media screen and (max-width: 991px) {
  .underline--black--rs-swap.hover-anim:hover, .underline--black--rs-swap.hover-anim:focus {
    color: var(--white);
  }
}
.underline--black--rs-swap.hover-anim:hover:before, .underline--black--rs-swap.hover-anim:focus:before {
  background-color: var(--black);
}
@media screen and (max-width: 991px) {
  .underline--black--rs-swap.hover-anim:hover:before, .underline--black--rs-swap.hover-anim:focus:before {
    background-color: var(--white);
  }
}
.underline--gray:before {
  background-color: var(--gray-light);
}
.underline--gray.hover-anim:hover, .underline--gray.hover-anim:focus {
  color: var(--black);
}
.underline--gray.hover-anim:hover:before, .underline--gray.hover-anim:focus:before {
  background-color: var(--black);
}
.underline--team:before {
  background-color: var(--white);
}
.underline--team.hover-anim:hover, .underline--team.hover-anim:focus {
  color: var(--white);
}
.underline--team.hover-anim:hover:before, .underline--team.hover-anim:focus:before {
  background-color: var(--white);
}
.underline--space {
  padding-left: 5px;
  padding-right: 5px;
}

.swiper-button-disabled {
  opacity: 0;
}

.slider-handle {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  justify-content: space-between;
  margin: auto;
  pointer-events: none;
  max-height: 30.4166666667vw;
}
.slider-handle > div > span {
  display: block;
  width: max(15px, 1.4583333333vw);
}
.slider-handle.recommended {
  top: 5.2083333333vw;
  width: 30.2083333333vw;
}
@media screen and (max-width: 991px) {
  .slider-handle.recommended {
    width: 69.2230070636vw;
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.recommended {
    width: 81.8666666667vw;
  }
}
.slider-handle.sub-recommended, .slider-handle.best-sellers, .slider-handle.special-offers {
  top: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .slider-handle.sub-recommended, .slider-handle.best-sellers, .slider-handle.special-offers {
    top: -35px;
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.prev-collection {
  top: 0;
}
@media screen and (max-width: 767px) {
  .slider-handle.prev-collection--cover-flow {
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.single-post {
  top: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .slider-handle.single-post--cover-flow {
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.shop-the-look {
  top: 5.2083333333vw;
  width: 33.3333333333vw;
}
@media screen and (max-width: 991px) {
  .slider-handle.shop-the-look {
    width: 69.2230070636vw;
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.shop-the-look {
    width: 81.8666666667vw;
  }
}
.slider-handle.social {
  top: 0;
  width: 100%;
}
.slider-handle.social .homepage__social__item-prev {
  right: 3.3854166667vw;
}
.slider-handle.social .homepage__social__item-next {
  left: 3.3854166667vw;
}
.slider-handle > * {
  cursor: pointer;
  pointer-events: all;
  padding: 15px 20px;
}
@media screen and (min-width: 992px) {
  .slider-handle > *:first-child {
    padding-left: 0;
  }
  .slider-handle > *:last-child {
    padding-right: 0;
  }
}
.slider-handle.store-handles {
  top: 0;
  width: 100%;
  width: 106%;
  left: -3%;
}
@media screen and (max-width: 1440px) {
  .slider-handle.store-handles {
    width: 107%;
    left: -3.5%;
  }
}
@media screen and (max-width: 1024px) {
  .slider-handle.store-handles {
    width: 110%;
    left: -5%;
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.store-handles {
    width: 110%;
    left: -5%;
  }
  .slider-handle.store-handles > *:first-child {
    padding-left: 0;
  }
  .slider-handle.store-handles > *:last-child {
    padding-right: 0;
  }
}
@media screen and (max-width: 580px) {
  .slider-handle.store-handles {
    width: 120%;
    left: -10%;
  }
}

@media screen and (max-width: 767px) {
  .slider-type--inline, .the-team__arc, .homepage__special-offers, .homepage__shop-the-look, .homepage__best-sellers, .homepage__sub-recommended, .homepage__recommended, .homepage__section_six {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .slider-type--inline .swiper, .the-team__arc .swiper, .homepage__special-offers .swiper, .homepage__shop-the-look .swiper, .homepage__best-sellers .swiper, .homepage__sub-recommended .swiper, .homepage__recommended .swiper, .homepage__section_six .swiper {
    margin-left: 0;
    margin-right: 0;
  }
  .slider-type--inline .swiper-slide, .the-team__arc .swiper-slide, .homepage__special-offers .swiper-slide, .homepage__shop-the-look .swiper-slide, .homepage__best-sellers .swiper-slide, .homepage__sub-recommended .swiper-slide, .homepage__recommended .swiper-slide, .homepage__section_six .swiper-slide {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
  .slider-type--inline .swiper-slide .card_view--footer, .the-team__arc .swiper-slide .card_view--footer, .homepage__special-offers .swiper-slide .card_view--footer, .homepage__shop-the-look .swiper-slide .card_view--footer, .homepage__best-sellers .swiper-slide .card_view--footer, .homepage__sub-recommended .swiper-slide .card_view--footer, .homepage__recommended .swiper-slide .card_view--footer, .homepage__section_six .swiper-slide .card_view--footer {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  .slider-type--inline .swiper-slide-active .card_view--footer, .the-team__arc .swiper-slide-active .card_view--footer, .homepage__special-offers .swiper-slide-active .card_view--footer, .homepage__shop-the-look .swiper-slide-active .card_view--footer, .homepage__best-sellers .swiper-slide-active .card_view--footer, .homepage__sub-recommended .swiper-slide-active .card_view--footer, .homepage__recommended .swiper-slide-active .card_view--footer, .homepage__section_six .swiper-slide-active .card_view--footer {
    opacity: 1;
    pointer-events: all;
  }
}

.discover {
  display: flex;
  justify-content: flex-end;
  margin-top: max(85px, 5.2083333333vw);
  margin-bottom: max(80px, 5.2083333333vw);
}
.discover--mb-large {
  margin-bottom: max(100px, 13.5416666667vw);
}
@media screen and (max-width: 767px) {
  .discover {
    justify-content: center;
  }
}
.discover a:hover {
  color: inherit;
  text-decoration: none;
}

label:not(.non-text) {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 10px;
}
label:not(.non-text).checkbox-label {
  text-transform: unset;
}
label.non-text {
  display: unset;
  max-width: unset;
  margin-bottom: unset;
  font-weight: unset;
}
label.non-text input[type=radio],
label.non-text input[type=checkbox] {
  border: unset;
  margin: 0;
  position: absolute;
  height: 100% !important;
  line-height: unset;
  cursor: pointer;
}

input {
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  padding: max(10px, 1.0416666667vw) 20px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--gray-dark);
  border-color: var(--gray-dark);
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--black);
}
input::placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
  opacity: 1;
}
input:-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
}
input::-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
}
input:hover, input:active {
  color: var(--gray-dark);
  border-color: var(--gray-dark);
}
input:hover::placeholder, input:active::placeholder {
  color: var(--gray-dark);
  opacity: 1;
}
input:hover:-ms-input-placeholder, input:active:-ms-input-placeholder {
  color: var(--gray-dark);
}
input:hover::-ms-input-placeholder, input:active::-ms-input-placeholder {
  color: var(--gray-dark);
}
input:focus::placeholder {
  color: transparent;
  opacity: 1;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}
input:focus::-ms-input-placeholder {
  color: transparent;
}
input.wpcf7-not-valid, input.has-error {
  color: var(--red);
  border-color: var(--red);
}
input.wpcf7-not-valid::placeholder, input.has-error::placeholder {
  color: var(--red);
  opacity: 1;
}
input.wpcf7-not-valid:-ms-input-placeholder, input.has-error:-ms-input-placeholder {
  color: var(--red);
}
input.wpcf7-not-valid::-ms-input-placeholder, input.has-error::-ms-input-placeholder {
  color: var(--red);
}
input.input--white, .account-single-block input[name=email],
.account-single-block input[name=password] {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
  color: var(--white);
}
input.input--white::placeholder, .account-single-block input[name=email]::placeholder,
.account-single-block input[name=password]::placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
  opacity: 1;
}
input.input--white:-ms-input-placeholder, .account-single-block input[name=email]:-ms-input-placeholder,
.account-single-block input[name=password]:-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
}
input.input--white::-ms-input-placeholder, .account-single-block input[name=email]::-ms-input-placeholder,
.account-single-block input[name=password]::-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
}
input.input--white:hover, .account-single-block input[name=email]:hover,
.account-single-block input[name=password]:hover, input.input--white:active, .account-single-block input[name=email]:active,
.account-single-block input[name=password]:active {
  color: var(--white);
  border-color: var(--white);
}
input.input--white:hover::placeholder, .account-single-block input[name=email]:hover::placeholder,
.account-single-block input[name=password]:hover::placeholder, input.input--white:active::placeholder, .account-single-block input[name=email]:active::placeholder,
.account-single-block input[name=password]:active::placeholder {
  color: var(--white);
  opacity: 1;
}
input.input--white:hover:-ms-input-placeholder, .account-single-block input[name=email]:hover:-ms-input-placeholder,
.account-single-block input[name=password]:hover:-ms-input-placeholder, input.input--white:active:-ms-input-placeholder, .account-single-block input[name=email]:active:-ms-input-placeholder,
.account-single-block input[name=password]:active:-ms-input-placeholder {
  color: var(--white);
}
input.input--white:hover::-ms-input-placeholder, .account-single-block input[name=email]:hover::-ms-input-placeholder,
.account-single-block input[name=password]:hover::-ms-input-placeholder, input.input--white:active::-ms-input-placeholder, .account-single-block input[name=email]:active::-ms-input-placeholder,
.account-single-block input[name=password]:active::-ms-input-placeholder {
  color: var(--white);
}
input.input--white:focus::placeholder, .account-single-block input[name=email]:focus::placeholder,
.account-single-block input[name=password]:focus::placeholder {
  color: transparent;
  opacity: 1;
}
input.input--white:focus:-ms-input-placeholder, .account-single-block input[name=email]:focus:-ms-input-placeholder,
.account-single-block input[name=password]:focus:-ms-input-placeholder {
  color: transparent;
}
input.input--white:focus::-ms-input-placeholder, .account-single-block input[name=email]:focus::-ms-input-placeholder,
.account-single-block input[name=password]:focus::-ms-input-placeholder {
  color: transparent;
}
input.input--white.wpcf7-not-valid, .account-single-block input.wpcf7-not-valid[name=email],
.account-single-block input.wpcf7-not-valid[name=password], input.input--white.has-error, .account-single-block input.has-error[name=email],
.account-single-block input.has-error[name=password] {
  color: var(--red);
  border-color: var(--red);
}
input.input--white.wpcf7-not-valid::placeholder, .account-single-block input.wpcf7-not-valid[name=email]::placeholder,
.account-single-block input.wpcf7-not-valid[name=password]::placeholder, input.input--white.has-error::placeholder, .account-single-block input.has-error[name=email]::placeholder,
.account-single-block input.has-error[name=password]::placeholder {
  color: var(--red);
  opacity: 1;
}
input.input--white.wpcf7-not-valid:-ms-input-placeholder, .account-single-block input.wpcf7-not-valid[name=email]:-ms-input-placeholder,
.account-single-block input.wpcf7-not-valid[name=password]:-ms-input-placeholder, input.input--white.has-error:-ms-input-placeholder, .account-single-block input.has-error[name=email]:-ms-input-placeholder,
.account-single-block input.has-error[name=password]:-ms-input-placeholder {
  color: var(--red);
}
input.input--white.wpcf7-not-valid::-ms-input-placeholder, .account-single-block input.wpcf7-not-valid[name=email]::-ms-input-placeholder,
.account-single-block input.wpcf7-not-valid[name=password]::-ms-input-placeholder, input.input--white.has-error::-ms-input-placeholder, .account-single-block input.has-error[name=email]::-ms-input-placeholder,
.account-single-block input.has-error[name=password]::-ms-input-placeholder {
  color: var(--red);
}
input::-webkit-input-placeholder {
  font-style: italic;
}
input.checkbox-input, .checkout input[type=checkbox], .filters__product .bf-cell input[type=checkbox] {
  display: inline-flex;
}
input.checkbox-input, .checkout input[type=checkbox], .filters__product .bf-cell input[type=checkbox], input.checkbox-wrap {
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0 10px -3px 0;
  width: 17px;
  height: 17px !important;
  border: 1px solid var(--gray-dark);
  position: relative;
  padding: 4px;
  border-radius: 4px;
}
input.checkbox-input:before, .checkout input[type=checkbox]:before, .filters__product .bf-cell input[type=checkbox]:before, input.checkbox-wrap:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: scale(0) translate(-50%, -50%);
  background-color: var(--white);
  transform-origin: bottom left;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input.checkbox-input:checked, .checkout input[type=checkbox]:checked, .filters__product .bf-cell input[type=checkbox]:checked, input.checkbox-wrap:checked {
  background-color: var(--gray-dark);
  border-color: var(--gray-dark);
}
input.checkbox-input:checked:before, .checkout input[type=checkbox]:checked:before, .filters__product .bf-cell input[type=checkbox]:checked:before, input.checkbox-wrap:checked:before {
  transform: scale(1) translate(-50%, -50%);
}
input.checkbox-input.error-checkbox, .checkout input.error-checkbox[type=checkbox], .filters__product .bf-cell input.error-checkbox[type=checkbox], input.checkbox-wrap.error-checkbox {
  border-color: var(--red);
}
input.checkbox-input.error-checkbox:checked, .checkout input.error-checkbox[type=checkbox]:checked, .filters__product .bf-cell input.error-checkbox[type=checkbox]:checked, input.checkbox-wrap.error-checkbox:checked {
  border-color: var(--gray-dark);
}
input.checkbox-input:disabled, .checkout input[type=checkbox]:disabled, .filters__product .bf-cell input[type=checkbox]:disabled, input.checkbox-wrap:disabled {
  border-color: var(--gray-dark);
  cursor: not-allowed;
}

/* RADIO STRACTURE */
/* RADIO */
.radio-input:not(#guest):not(#register), .checkout input[type=radio]:not(#guest):not(#register) {
  position: relative !important;
  display: inline-grid;
  place-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 1px solid var(--black);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  margin-right: 9px;
  border-radius: 50%;
}
.radio-input:not(#guest):not(#register):before, .checkout input[type=radio]:not(#guest):not(#register):before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--gray-dark);
}
.radio-input:not(#guest):not(#register):checked:before, .checkout input[type=radio]:not(#guest):not(#register):checked:before {
  transform: scale(1);
}

.checkbox-label {
  display: inline-flex;
  align-content: flex-start;
  align-items: flex-start;
}
.checkbox-label span {
  flex: 1;
  font-size: 1rem;
}
.checkbox-label span a {
  font-weight: bold;
  text-decoration: underline;
}
.checkbox-label span a:hover, .checkbox-label span a:focus {
  color: var(--black);
}

@media screen and (min-width: 768px) {
  .d-none {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .m-none {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .mb-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.fade-children > * {
  opacity: 0;
}

.fade-in {
  opacity: 0;
}

.default-photo-text-container {
  display: flex;
  margin-bottom: max(35px, 4.6875vw);
  align-items: flex-end;
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .default-photo-text-container {
    flex-direction: column-reverse;
    align-items: unset;
  }
}
.default-photo-text-container .default-inner-text-container {
  flex-basis: 38%;
  margin-left: 40px;
}
@media screen and (max-width: 1280px) {
  .default-photo-text-container .default-inner-text-container {
    margin-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .default-photo-text-container .default-inner-text-container {
    margin-left: 0;
  }
}
.default-photo-text-container .default-inner-text-container .default-info {
  width: 100%;
  max-width: 500px;
}
@media screen and (max-width: 767px) {
  .default-photo-text-container .default-inner-text-container .default-info {
    max-width: 100%;
    padding: 15px 10px;
  }
}
.default-photo-text-container .default-inner-text-container .default-info h2 {
  font-weight: 100;
  font-size: max(22px, 3.28125vw);
  margin-bottom: 30px;
}
@media screen and (max-width: 1280px) {
  .default-photo-text-container .default-inner-text-container .default-info h2 {
    margin-bottom: 15px;
  }
}
.default-photo-text-container .default-inner-text-container .default-info .default-text {
  font-size: 15px;
  font-weight: 100;
  line-height: 18px;
  margin-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .default-photo-text-container .default-inner-text-container .default-info .default-text {
    font-size: 13px;
  }
}
.default-photo-text-container .default-inner-text-container .default-info a:not(.underline--black) {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #a6a6a6;
}
.default-photo-text-container .default-inner-text-container .default-info a:hover, .default-photo-text-container .default-inner-text-container .default-info a:focus {
  text-decoration: none;
  color: var(--black);
}
.default-photo-text-container.reversed {
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .default-photo-text-container.reversed {
    flex-direction: column-reverse;
  }
}
.default-photo-text-container.reversed .default-inner-text-container {
  margin-right: 15px;
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .default-photo-text-container.reversed .default-inner-text-container {
    margin-right: 0;
  }
}
.default-photo-text-container .default-inner-photo-container {
  flex-basis: 62%;
}
.default-photo-text-container .default-inner-photo-container .desktop-photo {
  display: block;
}
@media screen and (max-width: 420px) {
  .default-photo-text-container .default-inner-photo-container .desktop-photo {
    display: none;
  }
}
.default-photo-text-container .default-inner-photo-container .mobile-photo {
  display: none;
}
@media screen and (max-width: 420px) {
  .default-photo-text-container .default-inner-photo-container .mobile-photo {
    display: block;
  }
}

.breadcrumbs-wrap {
  padding-top: max(30px, 2.6041666667vw);
  padding-left: max(15px, 3.125vw);
  padding-right: max(15px, 3.125vw);
  padding-bottom: max(10px, 1.5625vw);
}
@media screen and (max-width: 767px) {
  .breadcrumbs-wrap {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.breadcrumbs-wrap.in-search {
  display: flex;
  justify-content: space-between;
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
  padding-bottom: max(10px, 1.5625vw);
  align-items: center;
}
.breadcrumbs-wrap.in-search .breadcrumb {
  padding-bottom: 0;
}
.breadcrumbs-wrap .breadcrumb {
  margin: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background-color: transparent;
}
.breadcrumbs-wrap .breadcrumb li {
  padding: 0;
}
.breadcrumbs-wrap .breadcrumb li.text--breadcrumbs--static {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.breadcrumbs-wrap .breadcrumb li a {
  text-transform: uppercase;
  display: inline-block;
}
.breadcrumbs-wrap .breadcrumb li a:hover {
  color: inherit;
  text-decoration: none;
}
.breadcrumbs-wrap .breadcrumb li::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.6 10.7' style='enable-background:new 0 0 6.6 10.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4.4 5.9 5-6 5'/%3E%3C/svg%3E");
  display: inline-block;
  width: 6px;
  margin: 0 7px 0 10px;
}
.breadcrumbs-wrap .breadcrumb li:last-child::after {
  display: none;
}
.breadcrumbs-wrap .breadcrumb li + li:before {
  display: none;
}

.video-wrap {
  display: flex;
  width: 100%;
  height: 100%;
}
.video-wrap video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card_view--available-sizes > * {
  border: 1px solid var(--white);
  display: inline-flex;
  pointer-events: all;
}
.card_view--available-sizes > *:not(:last-child) {
  margin-right: 5px;
}
.card_view--available-sizes > * > label, .card_view--available-sizes > * > span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: max(12px, 0.8333333333vw);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: max(22px, 1.5625vw);
  height: max(22px, 1.5625vw);
  margin: 2px;
  cursor: pointer;
}
@media screen and (max-width: 1140px) {
  .card_view--available-sizes > * > label, .card_view--available-sizes > * > span {
    min-width: max(19px, 1.5625vw);
  }
}
.card_view--available-sizes > *:hover > label, .card_view--available-sizes > *:hover > span, .card_view--available-sizes > *.selected-size > label, .card_view--available-sizes > *.selected-size > span {
  background-color: var(--black);
  color: var(--white);
}
.card_view--available-sizes.theme-black > * {
  border-color: var(--black);
}
.card_view--available-sizes.theme-black > * > label, .card_view--available-sizes.theme-black > * > span {
  color: var(--black);
}
.card_view--available-sizes.theme-black > *:hover > label, .card_view--available-sizes.theme-black > *:hover > span, .card_view--available-sizes.theme-black > *.selected-size > label, .card_view--available-sizes.theme-black > *.selected-size > span {
  background-color: var(--black);
  color: var(--white);
}
.card_view--available-sizes.theme-black--rs-swap > * {
  border-color: var(--black);
}
@media screen and (max-width: 991px) {
  .card_view--available-sizes.theme-black--rs-swap > * {
    border-color: var(--white);
  }
}
.card_view--available-sizes.theme-black--rs-swap > * > label, .card_view--available-sizes.theme-black--rs-swap > * > span {
  color: var(--black);
}
@media screen and (max-width: 991px) {
  .card_view--available-sizes.theme-black--rs-swap > * > label, .card_view--available-sizes.theme-black--rs-swap > * > span {
    color: var(--white);
  }
}
.card_view--available-sizes.theme-black--rs-swap > *:hover > label, .card_view--available-sizes.theme-black--rs-swap > *:hover > span, .card_view--available-sizes.theme-black--rs-swap > *.selected-size > label, .card_view--available-sizes.theme-black--rs-swap > *.selected-size > span {
  background-color: var(--black);
  color: var(--white);
}
@media screen and (max-width: 991px) {
  .card_view--available-sizes.theme-black--rs-swap > *:hover > label, .card_view--available-sizes.theme-black--rs-swap > *:hover > span, .card_view--available-sizes.theme-black--rs-swap > *.selected-size > label, .card_view--available-sizes.theme-black--rs-swap > *.selected-size > span {
    background-color: var(--white);
    color: var(--black);
  }
}
.card_view--available-sizes.theme-white > *:hover > label, .card_view--available-sizes.theme-white > *:hover > span, .card_view--available-sizes.theme-white > *.selected-size > label, .card_view--available-sizes.theme-white > *.selected-size > span {
  background-color: var(--white);
  color: var(--black);
}

.select2-container--default .select2-selection--single {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  padding: max(10px, 1.0416666667vw) 20px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: var(--gray-dark);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: unset;
  background-color: transparent;
  color: var(--black);
  width: 100% !important;
  line-height: 1 !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 0;
  right: 20px;
  width: 11px;
  height: 100%;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: unset;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border: unset;
  width: 11px;
  height: 11px;
  transform: rotate(-90deg) translatex(5px) scale(0.9);
  opacity: 0.5;
}
.select2-container--default .select2-selection__rendered {
  line-height: 18px !important;
  color: var(--gray-dark) !important;
  padding-right: 10px;
}
.select2-container--default .select2-results__option {
  margin: 0 25px;
  padding: 15px 0;
}
.select2-container--default .select2-results__option:hover, .select2-container--default .select2-results__option--selected {
  background-color: var(--gray-dark) !important;
  color: var(--white) !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 100px;
  padding: 15px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--gray-dark) !important;
  color: var(--white) !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 100px;
  padding: 15px;
}
.select2-container--default.select2-container--open .select2-selection--single {
  border-radius: 0;
  background-color: var(--white) !important;
  border: 1px solid var(--gray-dark);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.8 6.7' style='enable-background:new 0 0 10.8 6.7' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23333;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m.4 6.2 5-5.9 5 6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border: unset;
  width: 11px;
  height: 11px;
  transform: rotate(-180deg) translatey(7px) scale(0.9);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__rendered {
  color: var(--gray-dark) !important;
}
.select2-container--default.select2-container--open .select2-dropdown--below {
  border-color: var(--gray-dark);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 15px 0 15px 0;
}
.select2-container--default.select2-container--open .select2-dropdown--above {
  border-color: var(--gray-dark);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px 0 15px 0;
}

.js-select2-custom.select2-container {
  width: 150px !important;
}
.js-select2-custom.select2-container--default .select2-selection--single {
  padding: 0;
  border: unset;
}
.js-select2-custom-dropdown {
  width: 200px;
  border: none;
  box-shadow: 10px 10px 15px -10px #000;
  margin-left: -20px;
}
.js-select2-custom-dropdown .select2-results__option {
  margin: 10px 0;
  padding: 0;
  margin-left: 28px !important;
}
.js-select2-custom-dropdown .select2-results__option:hover, .js-select2-custom-dropdown .select2-results__option--selected {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--black) !important;
  margin-left: unset;
  margin-right: unset;
  border-radius: unset;
  padding: unset;
}
.js-select2-custom-dropdown .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--black) !important;
  margin-left: unset;
  margin-right: unset;
  border-radius: unset;
  padding: unset;
}

.filters__product.open,
.select2-results__options {
  margin-right: 9px;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: scroll;
}
.filters__product.open::-webkit-scrollbar,
.select2-results__options::-webkit-scrollbar {
  width: 6px;
}
.filters__product.open::-webkit-scrollbar-track,
.select2-results__options::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
.filters__product.open::-webkit-scrollbar-thumb,
.select2-results__options::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--gray-light);
  -moz-box-shadow: inset 0 0 6px var(--gray-light);
  box-shadow: inset 0 0 6px var(--gray-light);
  background-color: var(--gray-light);
  border-radius: 4px;
}

.required-label label:before, .required .account-form--field label:before, .field__wrap--input.required label:before {
  content: "*";
}

.show--hidden {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.homepage__recommended .slider-wrap,
.homepage__sub-recommended .slider-wrap,
.homepage__best-sellers .slider-wrap,
.homepage__shop-the-look .slider-wrap,
.homepage__special-offers .slider-wrap,
.the-team__arc .slider-wrap {
  opacity: 0;
}
.homepage__recommended .slider-wrap.show--hidden,
.homepage__sub-recommended .slider-wrap.show--hidden,
.homepage__best-sellers .slider-wrap.show--hidden,
.homepage__shop-the-look .slider-wrap.show--hidden,
.homepage__special-offers .slider-wrap.show--hidden,
.the-team__arc .slider-wrap.show--hidden {
  opacity: 1;
}

.go-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  text-decoration: underline;
}
.go-back:hover, .go-back:focus, .go-back:visited {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .go-back {
    text-decoration: underline;
  }
}

.page_category .select2-results__option--disabled {
  display: none !important;
}
.page_category .select2-container--default .select2-selection__arrow {
  height: 11px;
}

.js-show {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.js-show-slow {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.js-restrict-overflow {
  overflow: hidden;
}
@media (hover: hover) {
  .js-restrict-overflow {
    padding-right: 17px;
  }
}

.decor--underline, .decor--underline:hover {
  text-decoration: underline !important;
}

.mini-decorated .account-form--field {
  margin-bottom: 15px !important;
}
.mini-decorated .account-form--field span {
  flex: 1;
  font-size: 1rem;
}
.mini-decorated .account-form--field span a {
  font-weight: bold;
  text-decoration: underline;
}
.mini-decorated .account-form--field span a:hover, .mini-decorated .account-form--field span a:focus {
  color: var(--black);
}

.eye-pass {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-36px, -50%);
  cursor: pointer;
}

.cc-settings-dialog .checkbox-label span {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 500;
}
.cc-settings-dialog .cc-btn {
  font-weight: 500 !important;
}
.cc-settings-dialog .cc-btn.cc-btn-close-settings {
  border: 1px solid var(--white);
}

.cc-btn {
  font-weight: 500 !important;
}
.cc-btn.cc-btn-save-settings:hover, .cc-btn.cc-btn-save-settings:focus {
  color: var(--black);
  font-weight: 500;
}

.cc-settings-dialog div.cc-highlight .cc-btn:first-child {
  font-size: unset !important;
  position: relative !important;
}

.card_view.js-pre-touch a,
.category-listing__item.js-pre-touch a {
  pointer-events: none;
}

.video-handler {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  cursor: pointer;
}
.video-handler img {
  display: block;
  width: 20px;
  height: 20px;
  object-fit: unset !important;
  min-height: unset !important;
}
.video-handler.sound-on img {
  display: block;
  content: url("../../../../default/assets/icons/sound-on.svg");
  width: 20px;
  height: 20px;
}

/*========================================================*/
/* Header style_m */
/*========================================================*/
.header--wrapper {
  align-items: flex-start;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.header--upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray);
  padding: 15px 30px 15px 0;
  z-index: 99;
}
@media (max-width: 767px) {
  .header--upper {
    padding: 15px 15px 15px 0;
  }
}
.header--bottom {
  z-index: 98;
}
.header--promo {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 0.9375vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  margin: 0;
}
@media (max-width: 767px) {
  .header--promo {
    font-size: 12px;
  }
}
.header--account__items {
  display: flex;
  align-items: center;
}
.header--account__items li {
  max-height: 20px;
}
.header--account__items li:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .header--account__items li:not(:last-child) {
    margin-right: 10px;
  }
}
.header--account__items li a {
  display: inline-block;
}
.header--icon {
  display: inline-block;
  width: auto;
  height: 18px;
}
.header--icon.wishlist-icon svg g path:first-child {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  fill: transparent;
}
.header--icon.wishlist-icon.has-wishlist-items svg g path:first-child {
  fill: unset;
}
@media (max-width: 767px) {
  .header--icon {
    height: 17px;
  }
}
.header--icon svg {
  display: inline-block;
  width: auto;
  height: 18px;
}
.header--icon svg .st0 {
  fill: var(--gray-dark);
}
@media (max-width: 767px) {
  .header--icon svg {
    height: 17px;
  }
}
.header--icon.wishlist-icon .st0 {
  fill: none;
  stroke: var(--gray-dark);
  stroke-width: 0.2835;
  stroke-miterlimit: 10;
}
.header--logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
}
@media (max-width: 767px) {
  .header--logo {
    padding: 15px;
  }
}
.header--logo .logo {
  z-index: 99;
  display: inline-block;
  width: 177px;
  height: 55px;
}
@media (max-width: 767px) {
  .header--logo .logo {
    width: 135px;
    height: auto;
  }
}
.header--logo .logo svg {
  display: inline-block;
  width: 177px;
  height: 55px;
}
@media (max-width: 767px) {
  .header--logo .logo svg {
    width: 135px;
    height: auto;
  }
}
.header--logo .logo svg .logo-color {
  fill: #000;
}
.header--logo .logo.white-logo svg .logo-color {
  fill: #fff;
}
.header--logo .logo.dark-logo svg .logo-color {
  fill: #000;
}

#cart {
  width: 43px;
  height: 20px;
  position: relative;
}
#cart .cart-btn {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 20px;
  padding: 0;
  display: flex;
  position: relative;
  top: -3px;
}
#cart .cart-btn .cart-icon {
  display: inline-block;
  width: 23px;
  height: 18px;
  position: relative;
  background-image: url("../../../icons/cart-icon.svg");
  background-repeat: no-repeat;
  background-size: auto;
}
#cart .cart-btn #cart-total {
  width: 20px;
  height: 20px;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

@media (max-width: 1200px) {
  .header-search {
    display: inline-block;
    margin-left: 20px;
  }
}
@media (max-width: 420px) {
  .header-search {
    margin-left: 10px;
  }
}
.header-search .open-search-bar.active svg .st0 {
  fill: transparent;
}
.header-search .open-search-bar .search-icon {
  display: block;
}

.open-search-input {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--header--upper));
  left: 0;
  top: var(--header--upper);
  background: rgba(80, 80, 80, 0.8);
  z-index: 999;
  -webkit-transition: visibility 0.3s ease, opacity 0.3s ease;
  -moz-transition: visibility 0.3s ease, opacity 0.3s ease;
  -o-transition: visibility 0.3s ease, opacity 0.3s ease;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  display: flex;
  flex-direction: column;
}
.open-search-input.active {
  visibility: visible;
  opacity: 1;
}
.open-search-input .close-search {
  position: absolute;
  top: 15px;
  right: 40px;
  z-index: 9999;
}
@media (max-width: 767px) {
  .open-search-input .close-search {
    right: 25px;
  }
}
.open-search-input .close-search .close-btn-icon {
  cursor: pointer;
}
.open-search-input .close-search .close-btn-icon svg .st0 {
  stroke: var(--white);
}
.open-search-input .open-search-input-wrapper {
  width: 100%;
  max-width: 475px;
  margin: 0 auto;
  padding: max(200px, 10.4166666667vw) 15px;
}
.open-search-input .open-search-input-wrapper .search-btn {
  z-index: 9;
  padding: max(15px, 1.8229166667vw) max(10px, 0.78125vw);
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.open-search-input .open-search-input-wrapper .search-btn .search-icon {
  display: flex;
  width: max(25px, 2.34375vw);
  height: max(25px, 2.34375vw);
}
.open-search-input .open-search-input-wrapper .search-btn .search-icon svg {
  display: flex;
  width: max(25px, 2.34375vw);
  height: max(25px, 2.34375vw);
}
.open-search-input .open-search-input-wrapper .search-btn .search-icon svg .st0 {
  fill: #fff;
}
.open-search-input #search {
  width: 100%;
  position: relative;
}
.open-search-input #search input[type=search] {
  position: relative;
  border-width: 0;
  border-style: solid;
  border-radius: 0;
  padding: 35px 15px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--white);
  border-color: var(--white);
  font-family: var(--font-default), sans-serif;
  font-size: max(26px, 1.875vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  padding: max(15px, 1.8229166667vw) max(10px, 0.78125vw);
  height: unset;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-bottom: 2px solid var(--white);
  text-align: center;
}
.open-search-input #search input[type=search]::placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
  opacity: 1;
}
.open-search-input #search input[type=search]:-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
}
.open-search-input #search input[type=search]::-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--white);
}
.open-search-input #search input[type=search]:hover, .open-search-input #search input[type=search]:active {
  color: var(--white);
  border-color: var(--white);
}
.open-search-input #search input[type=search]:hover::placeholder, .open-search-input #search input[type=search]:active::placeholder {
  color: var(--white);
  opacity: 1;
}
.open-search-input #search input[type=search]:hover:-ms-input-placeholder, .open-search-input #search input[type=search]:active:-ms-input-placeholder {
  color: var(--white);
}
.open-search-input #search input[type=search]:hover::-ms-input-placeholder, .open-search-input #search input[type=search]:active::-ms-input-placeholder {
  color: var(--white);
}
.open-search-input #search input[type=search]:focus::placeholder {
  color: transparent;
  opacity: 1;
}
.open-search-input #search input[type=search]:focus:-ms-input-placeholder {
  color: transparent;
}
.open-search-input #search input[type=search]:focus::-ms-input-placeholder {
  color: transparent;
}
.open-search-input #search input[type=search].wpcf7-not-valid, .open-search-input #search input[type=search].has-error {
  color: var(--red);
  border-color: var(--red);
}
.open-search-input #search input[type=search].wpcf7-not-valid::placeholder, .open-search-input #search input[type=search].has-error::placeholder {
  color: var(--red);
  opacity: 1;
}
.open-search-input #search input[type=search].wpcf7-not-valid:-ms-input-placeholder, .open-search-input #search input[type=search].has-error:-ms-input-placeholder {
  color: var(--red);
}
.open-search-input #search input[type=search].wpcf7-not-valid::-ms-input-placeholder, .open-search-input #search input[type=search].has-error::-ms-input-placeholder {
  color: var(--red);
}
.open-search-input #search input[type=search]::-webkit-input-placeholder { /* Chrome/Opera/Safari/Edge */
  font-family: var(--font-default), sans-serif;
  font-size: max(26px, 1.875vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--white);
}
.open-search-input #search input[type=search]::-ms-input-placeholder { /* Microsoft Edge */
  font-family: var(--font-default), sans-serif;
  font-size: max(26px, 1.875vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--white);
}
.open-search-input #search input[type=search]:-ms-input-placeholder { /* IE 10+ */
  font-family: var(--font-default), sans-serif;
  font-size: max(26px, 1.875vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--white);
}
.open-search-input #search #search-autocomplite {
  padding: 35px 15px;
  height: unset;
  position: absolute;
  top: 0;
}
@media (max-width: 767px) {
  .open-search-input #search #search-autocomplite {
    padding: 20px 10px;
  }
}
.open-search-input #search #d_ajax_search_results {
  max-height: max(270px, 18.2291666667vw);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  margin: 0;
  background-color: transparent;
  padding: 10px;
}
.open-search-input #search #d_ajax_search_results::-webkit-scrollbar {
  width: 6px;
}
.open-search-input #search #d_ajax_search_results::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
.open-search-input #search #d_ajax_search_results::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--gray-light);
  -moz-box-shadow: inset 0 0 6px var(--gray-light);
  box-shadow: inset 0 0 6px var(--gray-light);
  background-color: var(--gray-light);
  border-radius: 4px;
}
.open-search-input #search #d_ajax_search_results .d_ajax_search_results_body {
  max-height: max(270px, 18.2291666667vw);
  overflow-y: auto;
}
.open-search-input #search #d_ajax_search_results .all_results {
  padding: 5px 0;
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 1.0416666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.open-search-input #search #d_ajax_search_results .all_results:hover, .open-search-input #search #d_ajax_search_results .all_results:focus {
  color: var(--white);
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item {
  display: flex;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item:not(:last-child) {
  margin-bottom: 10px;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item:hover {
  color: var(--white);
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-category {
  flex-basis: 50%;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-category p {
  margin-bottom: 0;
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 2.0833333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-image {
  flex-basis: 20%;
  max-width: 70px;
  margin-right: 8px;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-info {
  flex-basis: 30%;
  width: 100%;
  max-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  word-wrap: break-word;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-info .search-item-name {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.open-search-input #search #d_ajax_search_results a.result-link.sort-item .search-item .search-item-info .search-item-price {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.open-search-input #search #d_ajax_search_results .no-results {
  text-transform: uppercase;
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  color: var(--white);
}
.open-search-input #search #d_ajax_search_results .no-results i.fa.fa-exclamation-circle {
  display: none;
}

footer {
  margin: 0;
  background-color: var(--gray);
}
footer .footer--container {
  padding: 50px max(30px, 7.2916666667vw) 24px;
}
@media (max-width: 1440px) {
  footer .footer--container {
    padding: 50px 50px 24px;
  }
}
@media (max-width: 991px) {
  footer .footer--container {
    padding: 50px 30px 0;
  }
}
@media (max-width: 767px) {
  footer .footer--container {
    padding: 0 0 50px;
  }
}
footer .footer--wrapper {
  display: flex;
}
@media (max-width: 1280px) {
  footer .footer--wrapper {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  footer .footer--wrapper {
    flex-direction: column;
  }
}
footer .footer--column {
  width: 100%;
}
footer .footer--column:nth-child(1) {
  max-width: 220px;
}
@media (max-width: 767px) {
  footer .footer--column:nth-child(1)._open .footer--body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  footer .footer--column:nth-child(1)._open .footer--body .payment--cards {
    padding-top: 0;
    max-width: 135px;
  }
}
footer .footer--column:nth-child(2) {
  max-width: 330px;
}
footer .footer--column:nth-child(3) {
  max-width: 245px;
}
footer .footer--column:nth-child(4) {
  max-width: 290px;
}
footer .footer--column:nth-child(5) {
  max-width: 235px;
}
footer .footer--column:nth-child(6) {
  max-width: 240px;
}
footer .footer--column:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 767px) {
  footer .footer--column:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 1280px) {
  footer .footer--column {
    flex-basis: calc(33.3333% - 15px);
    margin-bottom: 50px;
    width: unset;
  }
  footer .footer--column:nth-child(1) {
    max-width: unset;
  }
  footer .footer--column:nth-child(2) {
    max-width: unset;
  }
  footer .footer--column:nth-child(3) {
    max-width: unset;
  }
  footer .footer--column:nth-child(4) {
    max-width: unset;
  }
  footer .footer--column:nth-child(5) {
    max-width: unset;
  }
  footer .footer--column:nth-child(6) {
    max-width: unset;
  }
}
@media (max-width: 767px) {
  footer .footer--column {
    flex-basis: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid var(--black);
  }
  footer .footer--column:first-child {
    border-top: 1px solid var(--black);
  }
  footer .footer--column.follow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer .footer--column._open .footer--body {
    display: block;
  }
}
footer .footer--column._open .footer--title.extend:after {
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s ease;
}
footer .footer--title {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--black);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  footer .footer--title {
    margin: 0;
    padding: 15px;
    position: relative;
  }
  footer .footer--title.extend:after {
    display: inline-block;
    content: "";
    background-image: url("../../../icons/arrow-down.svg");
    padding: 7px 12px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }
}
@media (max-width: 767px) {
  footer .footer--body {
    display: none;
    padding: 0 15px;
  }
  footer .footer--body.open {
    display: block;
  }
  footer .footer--body .footer--list {
    margin-bottom: 10px;
  }
}
footer .footer--list li {
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 0;
}
footer .footer--social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  footer .footer--social {
    height: 20px;
  }
}
footer .footer--social li {
  display: flex;
  margin-bottom: 0;
}
footer .footer--social li:not(:last-child) {
  margin-right: 15px;
}
footer .footer--link {
  display: inline-block;
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
footer .footer--link:hover, footer .footer--link:focus, footer .footer--link:visited {
  color: var(--black);
  text-decoration: none;
}
footer .credits-container {
  font-size: 12px;
  letter-spacing: 1px;
  color: #000000;
  font-weight: 300;
  text-align: right;
  padding: 0 max(30px, 3.125vw) 17px;
}
@media (max-width: 767px) {
  footer .credits-container {
    text-align: center;
    padding: 0 0 17px;
  }
}
footer .credits-container .credits-wrapper {
  padding-top: 5px;
  border-top: 1px solid #000000;
}
footer .credits-container .credits-wrapper .saintAthens {
  margin-right: 15px;
}
footer .credits-container .credits-wrapper .eight8-logo {
  display: inline-block;
  width: 100%;
  max-width: 41px;
  position: relative;
  top: 4px;
}
footer .credits-container .credits-wrapper .eight8-logo svg {
  width: 100%;
  max-width: 41px;
}
footer .credits-container .credits-wrapper a {
  text-decoration: underline;
}
footer .payment--cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 170px;
  padding-top: 10px;
}
footer .payment--cards li {
  margin-bottom: 5px;
}
footer .payment--cards li:not(:last-child) {
  margin-right: 8px;
}
footer .payment--icon {
  display: inline-block;
  width: auto;
}
footer .payment--icon svg {
  display: inline-block;
  width: auto;
  height: 15px;
}
@media (max-width: 420px) {
  footer .payment--icon svg {
    height: 13px;
  }
}
footer .payment--icon.maestro-icon svg, footer .payment--icon.american-express-icon svg {
  height: 25px;
}
@media (max-width: 420px) {
  footer .payment--icon.maestro-icon svg, footer .payment--icon.american-express-icon svg {
    height: 22px;
  }
}
@media (max-width: 991px) {
  footer .footer-newsletter .newsletter-form {
    max-width: 320px;
  }
}
footer .footer-newsletter .newsletter-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 420px) {
  footer .footer-newsletter .newsletter-wrapper {
    max-width: 200px;
  }
}
footer .footer-newsletter .newsletter-wrapper input[type=email] {
  padding: 12px 20px;
}
footer .footer-newsletter .newsletter-subscribe-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 2px;
  padding: 12px 20px;
}
footer .footer-newsletter .newsletter-subscribe-btn .newsletter-submit-arrow {
  display: flex;
  width: 10px;
  height: 16px;
}
footer .footer-newsletter .newsletter-subscribe-btn .newsletter-submit-arrow svg {
  display: flex;
  width: 10px;
  height: 16px;
}
footer .footer-newsletter .newsletter-subscribe-btn .newsletter-submit-arrow svg .st0 {
  fill: none;
  stroke: var(--gray-light);
  stroke-width: 1.0006;
  stroke-linejoin: bevel;
  stroke-miterlimit: 10;
}
footer .mobile--logo--wrapper {
  display: none;
}
@media (max-width: 767px) {
  footer .mobile--logo--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
  }
  footer .mobile--logo--wrapper .mobile--logo {
    width: 100px;
    height: 30px;
    display: inline-block;
  }
  footer .mobile--logo--wrapper .mobile--logo svg {
    width: 100px;
    height: 30px;
    display: inline-block;
  }
}

#subscribe_result * {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.social--icon {
  display: inline-block;
  height: 29px;
  width: auto;
}
@media (max-width: 767px) {
  .social--icon {
    height: 20px;
  }
}
.social--icon svg {
  display: inline-block;
  height: 29px;
  width: auto;
}
@media (max-width: 767px) {
  .social--icon svg {
    height: 20px;
  }
}
.social--icon svg .st0 {
  fill: #333333;
}
.social--icon svg .st1 {
  fill: none;
  stroke: #333333;
  stroke-width: 0.8504;
  stroke-linejoin: bevel;
  stroke-miterlimit: 10;
}
.social--icon.youtube {
  height: 24px;
}
@media (max-width: 767px) {
  .social--icon.youtube {
    height: 15px;
  }
}
.social--icon.youtube svg {
  height: 24px;
}
@media (max-width: 767px) {
  .social--icon.youtube svg {
    height: 15px;
  }
}

/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
body .body-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media screen and (max-width: 580px) {
  body .body-cover {
    transition: opacity 0.8s ease;
  }
}
body .header-cover {
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  right: 0;
  background-color: var(--black);
  display: none;
}
@media (max-width: 991px) {
  body .header-cover {
    display: block;
  }
}
body.search-is-open {
  overflow: hidden;
}
body.cart-is-open, body.menu-is-open {
  overflow: hidden;
}
@media (hover: hover) {
  body.cart-is-open, body.menu-is-open {
    padding-right: 17px;
  }
}
body.cart-is-open .body-cover, body.menu-is-open .body-cover {
  z-index: 99;
  opacity: 0.7;
  visibility: visible;
  transition: opacity 0.3s ease;
  pointer-events: unset;
}
body.cart-is-open .header--bottom {
  pointer-events: none;
}
body.cart-is-open .header--upper::after, body.cart-is-open .header--bottom::after {
  display: inline-block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.7;
}
body.cart-is-open .header--upper::after {
  z-index: -1;
}
body.size-guide-is-open {
  overflow: hidden;
}
body.size-guide-is-open .body-cover {
  z-index: 999;
  opacity: 0.7;
  visibility: visible;
  transition: opacity 0.3s ease;
}
body.cart-is-open .header-cover {
  opacity: 0.7;
  visibility: visible;
}

#cart .minicart-body {
  position: fixed;
  padding: 40px 30px 30px 15px;
  background-color: var(--beige);
  border: none;
  min-width: 540px;
  top: 0;
  right: -100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: var(--screen-height);
  margin: 0;
}
#cart .minicart-body.open {
  right: 0;
}
@media (max-width: 767px) {
  #cart .minicart-body {
    padding: 15px;
    min-width: auto;
    width: 100%;
    overflow-y: auto;
  }
}
#cart .minicart-body .mini-cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
#cart .minicart-body .mini-cart-header .mini-cart-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(32px, 1.6666666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: #333333;
}
@media (max-width: 767px) {
  #cart .minicart-body .mini-cart-header .mini-cart-title {
    font-size: 22px;
  }
}
#cart .minicart-body .mini-cart-header a.close-mini-cart-btn {
  cursor: pointer;
  display: flex;
}
#cart .minicart-body .all-products-w-button {
  position: relative;
  height: calc(var(--screen-height) - 177px);
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button {
    height: calc(var(--screen-height) - 137px);
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: calc(var(--screen-height) - 325px);
}
#cart .minicart-body .all-products-w-button .scrollable-products::-webkit-scrollbar {
  width: 6px;
}
#cart .minicart-body .all-products-w-button .scrollable-products::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
#cart .minicart-body .all-products-w-button .scrollable-products::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--gray-light);
  -moz-box-shadow: inset 0 0 6px var(--gray-light);
  box-shadow: inset 0 0 6px var(--gray-light);
  background-color: var(--gray-light);
  border-radius: 4px;
}
#cart .minicart-body .all-products-w-button .scrollable-products .text-center {
  padding-top: 35px;
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products:not(:last-child) {
  padding-bottom: 25px;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .product-img {
  margin-right: 20px;
  max-width: max(115px, 8.5416666667vw);
}
@media (max-width: 580px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .product-img {
    margin-right: 15px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .product-img a {
  display: inline-block;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .product-img a .img-thumbnail {
  padding: 0;
  border-radius: 0;
  border: none;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 215px;
}
@media (max-width: 580px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item {
    max-width: 160px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info {
  width: 100%;
  max-width: 170px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info {
    max-width: 155px;
  }
}
@media (max-width: 420px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info {
    max-width: 110px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-name {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  color: #333333;
  text-transform: uppercase;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-name:hover, #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-name:focus {
  text-decoration: none;
}
@media (max-width: 580px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-name {
    font-size: 11px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-options {
  display: flex;
  margin-bottom: 5px;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-options li {
  margin: 0 !important;
  font-family: var(--font-default), sans-serif;
  font-size: max(11px, 0.5729166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-options li:not(:last-child)::after {
  content: "-";
  display: inline-block;
  font-family: var(--font-default), sans-serif;
  font-size: max(11px, 0.5729166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin: 0 5px;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-price {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  color: #333333;
  margin-bottom: 20px;
}
@media (max-width: 580px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .prd-item .prd-item-info .prd-price {
    font-size: 11px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .remove-cart-item {
  border: none;
  background-color: transparent;
  margin-top: 5px;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: italic;
  line-height: 1;
  color: #333333;
  padding: 0;
}
#cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .remove-cart-item:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #333333;
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 580px) {
  #cart .minicart-body .all-products-w-button .scrollable-products .mini-cart-products .remove-cart-item {
    font-size: 10px;
  }
}
#cart .minicart-body .all-products-w-button .mini-cart-total {
  width: 100%;
}
#cart .minicart-body .all-products-w-button .mini-cart-total .col-total {
  font-family: var(--font-default), sans-serif;
  font-size: max(22px, 1.3020833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: #333333;
  margin-top: 40px;
}
#cart .minicart-body .all-products-w-button .mini-cart-total .col-total:nth-child(2) {
  display: none;
}
#cart .minicart-body .all-products-w-button .mobile-bottom-cart {
  position: absolute;
  bottom: 0;
  width: 100%;
}
#cart .minicart-body .all-products-w-button .mobile-bottom-cart .mini-cart-checkout {
  display: flex;
  justify-content: center;
}
#cart .minicart-body .all-products-w-button .mobile-bottom-cart .mini-cart-checkout a {
  width: 100%;
  max-width: 200px;
  border: 1px solid #333333;
  color: #333333;
  text-align: center;
  padding: 15px;
  font-family: var(--font-default), sans-serif;
  font-size: max(23px, 1.71875vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
#cart .minicart-body .all-products-w-button .mobile-bottom-cart .mini-cart-checkout a:hover {
  text-decoration: none;
}

.close-btn-icon {
  display: inline-block;
  width: 35px;
  height: 35px;
}
@media (max-width: 580px) {
  .close-btn-icon {
    width: 30px;
    height: 30px;
  }
}
.close-btn-icon svg {
  display: inline-block;
  width: 35px;
  height: 35px;
}
@media (max-width: 580px) {
  .close-btn-icon svg {
    width: 30px;
    height: 30px;
  }
}
.close-btn-icon svg .st0 {
  stroke: #333333;
}

.minicart-quantity-input-field {
  width: 100%;
  max-width: 120px;
}
@media (max-width: 580px) {
  .minicart-quantity-input-field {
    max-width: 95px;
  }
}
.minicart-quantity-input-field .minicart-quantity {
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
}
.minicart-quantity-input-field .minicart-quantity .number-input {
  position: relative;
}
.minicart-quantity-input-field .minicart-quantity input[type=text] {
  background-color: transparent;
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 1.1458333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  padding: 0;
  color: var(--black);
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  min-height: 23px;
}
.minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav .quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-left: 1px solid var(--black);
  border-right: 1px solid var(--black);
  cursor: pointer;
}
.minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav .quantity-button.quantity-down:after {
  content: "";
  background-image: url("../../../assets/icons/minus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 580px) {
  .minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav .quantity-button.quantity-down:after {
    width: 10px;
    height: 10px;
  }
}
.minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav .quantity-button.quantity-up:after {
  content: "";
  background-image: url("../../../assets/icons/plus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 580px) {
  .minicart-quantity-input-field .minicart-quantity .minicart-quantity-nav .quantity-button.quantity-up:after {
    width: 10px;
    height: 10px;
  }
}

.homepage > * {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
}
.homepage > * .swiper-slide {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .homepage__landing {
    padding-top: 2.0833333333vw;
  }
}
@media screen and (max-width: 991px) {
  .homepage__landing {
    padding-left: 0;
    padding-right: 0;
  }
}
.homepage__landing--banner {
  width: 65.2604166667vw;
  height: 42.5vw;
  margin: auto;
  margin-bottom: 103px;
}
@media screen and (max-width: 991px) {
  .homepage__landing--banner {
    width: 100%;
    height: auto;
  }
  .homepage__landing--banner img {
    object-fit: cover;
    min-height: calc(var(--screen-height) - var(--header-height));
  }
}
.homepage__landing--banner .video-wrap {
  width: 100%;
  height: 100%;
}
.homepage__landing--banner .video-wrap video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.homepage__landing--title {
  position: absolute;
  top: 25px;
  left: 25px;
  color: var(--white);
}
.homepage__landing--cta {
  position: absolute;
  bottom: 20px;
  right: 40px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--white);
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .homepage__landing--cta span:first-child {
    margin-bottom: 10px;
  }
}
.homepage__landing--cta:hover {
  color: var(--white);
  text-decoration: none;
}
.homepage__recommended--title, .homepage__section_six--title {
  margin-bottom: -7.2916666667vw;
}
@media screen and (max-width: 991px) {
  .homepage__recommended--title, .homepage__section_six--title {
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 991px) {
  .homepage__recommended .slider-wrap, .homepage__section_six .slider-wrap {
    max-width: 515px;
    margin: auto;
  }
}
.homepage__recommended .arc, .homepage__section_six .arc {
  display: flex;
  justify-self: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.homepage__recommended .arc img, .homepage__section_six .arc img {
  width: max(332px, 35.6770833333vw);
  height: 100%;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .homepage__recommended .arc img, .homepage__section_six .arc img {
    width: 100%;
    max-width: 90%;
    content: var(--arc-mob);
  }
}
.homepage__recommended--slider, .homepage__section_six--slider {
  display: flex;
  align-items: center;
  min-height: 41.71875vw;
}
@media screen and (min-width: 992px) and (max-width: 1140px) {
  .homepage__recommended--slider, .homepage__section_six--slider {
    padding: 40px 0;
  }
}
.homepage__recommended__sliderwrap, .homepage__section_six__sliderwrap {
  align-items: center;
  left: -10.9375vw;
  margin-bottom: -6.7708333333vw;
}
@media screen and (max-width: 1140px) {
  .homepage__recommended__sliderwrap, .homepage__section_six__sliderwrap {
    margin-bottom: -5.701754386vw;
    min-height: 41.71875vw;
  }
}
@media screen and (max-width: 515px) {
  .homepage__recommended__sliderwrap, .homepage__section_six__sliderwrap {
    min-height: max(444px, 111.6504854369vw);
  }
}
@media screen and (max-width: 991px) {
  .homepage__recommended__sliderwrap, .homepage__section_six__sliderwrap {
    margin-bottom: unset;
    left: unset;
    margin-top: 85px;
  }
}
.homepage__recommended .swiper-slide, .homepage__section_six .swiper-slide {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .homepage__recommended .swiper-slide, .homepage__section_six .swiper-slide {
    margin-top: 70px;
  }
  .homepage__recommended .swiper-slide .card_view .card_view--image > img, .homepage__section_six .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 265px;
    max-width: 265px;
    min-height: max(165px, 18.0729166667vw);
    max-height: max(165px, 18.0729166667vw);
    object-fit: cover;
  }
  .homepage__recommended .swiper-slide-active, .homepage__section_six .swiper-slide-active {
    margin-top: 0;
  }
  .homepage__recommended .swiper-slide-active .card_view .card_view--image > img, .homepage__section_six .swiper-slide-active .card_view .card_view--image > img {
    min-width: max(257px, 23.2291666667vw);
    max-width: max(257px, 23.2291666667vw);
    min-height: max(337px, 30.3645833333vw);
    max-height: max(337px, 30.3645833333vw);
    object-fit: cover;
  }
}
.homepage__recommended .swiper-slide-active, .homepage__section_six .swiper-slide-active {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  .homepage__recommended .swiper-slide-active, .homepage__section_six .swiper-slide-active {
    width: 23.2291666667vw !important;
    margin-left: 6.6145833333vw;
    margin-right: 6.6145833333vw !important;
  }
}
.homepage__recommended .swiper-slide:hover .card_view--active, .homepage__section_six .swiper-slide:hover .card_view--active {
  background-color: rgba(51, 51, 51, 0.65);
}
.homepage__recommended .swiper-slide .card_view--active, .homepage__section_six .swiper-slide .card_view--active {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.homepage__recommended .swiper-slide .card_view--active-description, .homepage__section_six .swiper-slide .card_view--active-description {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.0625vw;
  width: 23.2291666667vw;
  margin: auto;
  color: var(--white);
  width: 100%;
  max-width: 65%;
}
.homepage__recommended .swiper-slide .card_view--active-description .out-of-stock, .homepage__section_six .swiper-slide .card_view--active-description .out-of-stock {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.1458333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
}
.homepage__recommended .swiper-slide .card_view--active-description .pre-order, .homepage__section_six .swiper-slide .card_view--active-description .pre-order {
  text-align: center;
  font-family: var(--font-default), sans-serif;
  font-size: max(10px, 0.9375vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
@media screen and (max-width: 1440px) {
  .homepage__recommended .swiper-slide .card_view--active-description, .homepage__section_six .swiper-slide .card_view--active-description {
    max-width: 82%;
  }
}
.homepage__recommended .swiper-slide .card_view--active-options, .homepage__section_six .swiper-slide .card_view--active-options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1.0416666667vw;
  padding-left: 1.8229166667vw;
  padding-right: 1.8229166667vw;
}
@media screen and (max-width: 1225px) {
  .homepage__recommended .swiper-slide .card_view--active-options, .homepage__section_six .swiper-slide .card_view--active-options {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 991px) {
  .homepage__recommended .swiper-slide .card_view--active-options, .homepage__section_six .swiper-slide .card_view--active-options {
    padding-left: 1.8229166667vw;
    padding-right: 1.8229166667vw;
  }
}
.homepage__recommended .swiper-slide .card_view--active-options > *, .homepage__section_six .swiper-slide .card_view--active-options > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  flex: 1;
}
.homepage__recommended .swiper-slide .card_view--active-options > *.disabled, .homepage__section_six .swiper-slide .card_view--active-options > *.disabled {
  pointer-events: none;
}
.homepage__recommended .swiper-slide .card_view--active-left > *:not(:last-child), .homepage__section_six .swiper-slide .card_view--active-left > *:not(:last-child) {
  margin-bottom: 10px;
}
.homepage__recommended .swiper-slide .card_view--active-left .card_view--active-title, .homepage__section_six .swiper-slide .card_view--active-left .card_view--active-title {
  word-break: break-word;
}
.homepage__recommended .swiper-slide .card_view--active-center, .homepage__section_six .swiper-slide .card_view--active-center {
  text-align: center;
  flex: 2;
  margin-bottom: 10px;
}
.homepage__recommended .swiper-slide .card_view--active-right, .homepage__section_six .swiper-slide .card_view--active-right {
  text-align: right;
  margin-bottom: 22px;
  opacity: 0 !important;
  display: none;
}
.homepage__recommended .swiper-slide .card_view--active-available-sizes-static span:not(:last-child), .homepage__section_six .swiper-slide .card_view--active-available-sizes-static span:not(:last-child) {
  margin-right: 15px;
}
.homepage__recommended .swiper-slide .card_view--active-cta-pre, .homepage__section_six .swiper-slide .card_view--active-cta-pre {
  margin-bottom: 10px;
  cursor: pointer;
}
.homepage__recommended .swiper-slide .card_view--active-cta-add, .homepage__section_six .swiper-slide .card_view--active-cta-add {
  cursor: pointer;
}
.homepage__recommended .swiper-slide .card_view--active-cta-view, .homepage__section_six .swiper-slide .card_view--active-cta-view {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  opacity: 0;
  pointer-events: none;
}
.homepage__recommended .swiper-slide .card_view--active-cta-view .underline--black:after, .homepage__section_six .swiper-slide .card_view--active-cta-view .underline--black:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #e7d6c8;
  transition: width 0.8s;
}
.homepage__recommended .swiper-slide .card_view--active-cta-view .underline--black:hover:after, .homepage__section_six .swiper-slide .card_view--active-cta-view .underline--black:hover:after {
  width: 101%;
}
.homepage__recommended .swiper-slide .card_view--active-cta-view .card_view--favorites, .homepage__section_six .swiper-slide .card_view--active-cta-view .card_view--favorites {
  margin-left: 20px;
}
.homepage__recommended .swiper-slide-active .card_view--footer, .homepage__section_six .swiper-slide-active .card_view--footer {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  padding: 0;
}
.homepage__sub-recommended--slider {
  margin-left: 5vw;
  margin-right: 5vw;
}
.homepage__sub-recommended--title {
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .homepage__sub-recommended--title {
    margin-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) {
  .homepage__sub-recommended .swiper-slide-active {
    scale: 0.85;
  }
  .homepage__sub-recommended .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 15.3645833333vw;
    max-width: 15.3645833333vw;
    min-height: 23.125vw;
    max-height: 23.125vw;
    object-fit: cover;
  }
}
@media screen and (min-width: 1441px) {
  .homepage__best-sellers__sliderwrap {
    left: -1px;
  }
}
.homepage__best-sellers--slider {
  margin-left: 3.2291666667vw;
  margin-right: 5.9895833333vw;
}
.homepage__best-sellers--title {
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .homepage__best-sellers--title {
    margin-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) and (hover: hover) {
  .homepage__best-sellers .swiper-slide-active {
    scale: 0.8;
    transform-origin: right;
  }
}
@media screen and (min-width: 992px) {
  .homepage__best-sellers .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 21.3020833333vw;
    max-width: 21.3020833333vw;
    min-height: 32.1875vw;
    max-height: 32.1875vw;
    object-fit: cover;
  }
}
@media screen and (min-width: 992px) and (hover: hover) {
  .homepage__best-sellers__sliderwrap.less-than-expected .swiper-slide-active {
    scale: 1;
  }
}
.homepage__shop-the-look--title {
  margin-bottom: -2.6041666667vw;
}
@media screen and (max-width: 767px) {
  .homepage__shop-the-look--title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 991px) {
  .homepage__shop-the-look .slider-wrap {
    max-width: 515px;
    margin: auto;
  }
}
.homepage__shop-the-look--slider {
  display: flex;
  align-items: center;
  min-height: 41.71875vw;
}
.homepage__shop-the-look--slider .arc {
  display: flex;
  justify-self: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.homepage__shop-the-look--slider .arc img {
  width: max(332px, 36.40625vw);
  height: 100%;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .homepage__shop-the-look--slider .arc img {
    width: 100%;
    max-width: 90%;
    content: var(--arc-mob);
  }
}
.homepage__shop-the-look__sliderwrap {
  align-items: center;
}
@media screen and (min-width: 992px) {
  .homepage__shop-the-look__sliderwrap {
    min-height: 41.9791666667vw;
    left: -13.3854166667vw;
  }
}
@media screen and (max-width: 991px) {
  .homepage__shop-the-look__sliderwrap {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 991px) {
  .homepage__shop-the-look__sliderwrap .swiper-slide {
    margin-top: 21.3333333333vw;
  }
}
@media screen and (min-width: 992px) {
  .homepage__shop-the-look__sliderwrap .swiper-slide {
    min-height: max(165px, 11.4583333333vw);
    max-height: max(165px, 11.4583333333vw);
    margin-bottom: -4.1666666667vw;
  }
  .homepage__shop-the-look__sliderwrap .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 176px;
    max-width: 176px;
    min-height: max(165px, 11.4583333333vw);
    max-height: max(165px, 11.4583333333vw);
    object-fit: cover;
  }
  .homepage__shop-the-look__sliderwrap .swiper-slide-active {
    min-width: 25.2083333333vw;
    max-width: 25.2083333333vw;
    min-height: max(345px, 31.5104166667vw);
    max-height: max(345px, 31.5104166667vw);
  }
  .homepage__shop-the-look__sliderwrap .swiper-slide-active .card_view .card_view--image > img {
    min-width: 25.2083333333vw;
    max-width: 25.2083333333vw;
    min-height: max(345px, 31.5104166667vw);
    max-height: max(345px, 31.5104166667vw);
    object-fit: cover;
  }
}
.homepage__shop-the-look__item {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .homepage__shop-the-look__item.swiper-slide-active {
    width: 23.2291666667vw !important;
    margin-left: 5.5729166667vw;
    margin-right: 5.5729166667vw !important;
  }
  .homepage__shop-the-look__item.swiper-slide-active ~ * {
    opacity: 0;
    pointer-events: none;
  }
}
.homepage__shop-the-look__item.swiper-slide-active .card_view--item-trigger {
  display: unset;
}
.homepage__shop-the-look__item.swiper-slide-active .card_view--footer {
  display: none;
}
.homepage__shop-the-look__item.swiper-slide-active .card_view--active.selection-open {
  opacity: 1;
  pointer-events: all;
}
.homepage__special-offers {
  margin-top: 7.2916666667vw;
}
.homepage__special-offers--slider {
  margin-left: 5.9895833333vw;
  margin-right: 5.9895833333vw;
}
.homepage__special-offers--title {
  margin-bottom: max(70px, 3.90625vw);
}
@media screen and (max-width: 767px) {
  .homepage__special-offers--title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) and (hover: hover) {
  .homepage__special-offers .swiper-slide:hover {
    scale: 0.85;
  }
}
@media screen and (min-width: 992px) {
  .homepage__special-offers .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 15.3645833333vw;
    max-width: 15.3645833333vw;
    min-height: 23.125vw;
    max-height: 23.125vw;
    object-fit: cover;
  }
}
.homepage__social {
  margin-top: max(100px, 13.0208333333vw);
  margin-bottom: 5.7291666667vw;
  margin-left: 3.3854166667vw;
  margin-right: 3.3854166667vw;
}
.homepage__social--title {
  text-align: center;
  margin-bottom: max(30px, 5.7291666667vw);
}
.homepage__social__item {
  width: 26.40625vw;
  height: 26.40625vw;
  margin-right: 4.1666666667vw;
}
.homepage__social__item:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26.1 26.5' style='enable-background:new 0 0 26.1 26.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23333333;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M20,26.5H6c-3.3,0-6-2.7-6-6V6c0-3.3,2.7-6,6-6h14c3.3,0,6,2.7,6,6v14.4C26,23.8,23.3,26.5,20,26.5z M6,0.3 C2.8,0.3,0.2,2.9,0.2,6v14.4c0,3.2,2.6,5.8,5.8,5.8h14c3.2,0,5.8-2.6,5.8-5.8V6c0-3.2-2.6-5.8-5.8-5.8H6z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M13,20.3c-3.9,0-7.1-3.2-7.1-7.1S9.1,6.2,13,6.2s7.1,3.2,7.1,7.1S16.9,20.3,13,20.3z M13,6.4 c-3.8,0-6.8,3.1-6.8,6.8s3.1,6.8,6.8,6.8s6.8-3.1,6.8-6.8S16.8,6.4,13,6.4z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M20.7,7.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C22.7,6.3,21.8,7.2,20.7,7.2z M20.7,3.4 c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S21.6,3.4,20.7,3.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: max(19px, 4.3229166667vw);
  background-repeat: no-repeat;
  background-position: center;
}
.homepage__social__item a {
  display: flex;
  width: 100%;
  height: 100%;
}
.homepage__social__item a img {
  object-fit: cover;
  height: 100%;
}
.homepage__social__item .social-view a img {
  width: 26.40625vw;
  height: 26.40625vw;
  object-fit: cover;
}
@media (hover: hover) {
  .homepage__social__item:hover {
    scale: 0.8;
    transform-origin: center;
  }
}
.homepage__social__item:hover:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.selections {
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  z-index: 2;
  position: absolute;
  width: max(200px, 13.0208333333vw);
  top: 4.3229166667vw;
  right: 13.2291666667vw;
}
@media screen and (max-width: 1366px) {
  .selections {
    right: 7.8125vw;
  }
}
@media screen and (max-width: 991px) {
  .selections {
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: calc(var(--screen-height) - var(--header-height));
    display: flex;
    justify-content: center;
    pointer-events: none;
  }
  .selections.overlay {
    pointer-events: all;
    background-color: rgba(43, 40, 38, 0.85);
    color: var(--white);
    overflow: auto;
    overscroll-behavior-y: contain;
    overflow-y: auto;
  }
}
@media screen and (max-width: 991px) {
  .selections .selections-wrap {
    width: 200px;
  }
}
.selections .card_view--active {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
@media screen and (min-width: 992px) {
  .selections .card_view--active {
    top: -30px;
  }
}
.selections .card_view--active.selection-open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
@media screen and (max-width: 991px) {
  .selections .card_view--active.selection-open {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.selections .card_view--active .details--pop-up-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selections .card_view--active .details--pop-up-title .card_view--favorites {
  display: flex;
  cursor: pointer;
}
.selections .card_view--active-close {
  position: absolute;
  top: 8px;
  right: 10px;
  max-width: 31px;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .selections .card_view--active-close {
    position: fixed;
    top: calc(var(--header-height) + 15px);
    right: 15px;
    filter: invert(1);
  }
}
.selections .card_view--active-image {
  padding: 12.5px;
  border: 1px solid var(--black);
}
.selections .card_view--active-image > a {
  display: flex;
  position: relative;
}
.selections .card_view--active-image > a img {
  min-height: max(221px, 16.1458333333vw);
  max-height: max(221px, 16.1458333333vw);
  min-width: max(178px, 13.0208333333vw);
  max-width: max(178px, 13.0208333333vw);
  object-fit: cover;
  object-position: top;
}
.selections .card_view--active-image > a .pre-order, .selections .card_view--active-image > a .out-of-stock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.65);
  opacity: 1;
  pointer-events: none;
  line-height: 1.2;
  color: var(--white);
}
.selections .card_view--active-image > a .pre-order {
  font-size: 14px;
  padding: 0 5px;
}
.selections .card_view--active-image > a .out-of-stock {
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .selections .card_view--active-image {
    border-color: var(--white);
  }
}
.selections .card_view--active-description {
  padding: 10px;
  min-height: 200px;
}
@media screen and (max-width: 580px) {
  .selections .card_view--active-description {
    min-height: unset;
  }
}
.selections .card_view--active-description ul {
  margin-top: 20px;
}
.selections .card_view--active-description ul li {
  margin-bottom: 5px;
}
.selections .card_view--active-description ul li:before {
  content: var(--list-icon);
  margin-right: 5px;
}
@media screen and (max-width: 991px) {
  .selections .card_view--active-description ul li:before {
    filter: invert(1);
  }
}
.selections .card_view--active-options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
}
.selections .card_view--active-options > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  flex: 1;
}
.selections .card_view--active-options > *.disabled {
  pointer-events: none;
}
.selections .card_view--active-add-to-cart {
  padding-top: 15px;
}
.selections .card_view--active-cta-add {
  cursor: pointer;
}
.selections .card_view--active-cta-view {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  align-self: center;
}
.selections--all-current {
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  background-color: rgba(43, 40, 38, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--screen-height);
  max-height: var(--screen-height);
  overscroll-behavior-y: contain;
  overflow: auto;
  display: unset;
  justify-content: unset;
  right: unset;
  z-index: -100;
  opacity: 0;
  pointer-events: none;
}
.selections--all-current-close {
  position: absolute;
  top: 8px;
  right: 10px;
  max-width: 31px;
  cursor: pointer;
  filter: invert(1);
  z-index: 1;
}
.selections--all-current--show {
  opacity: 1;
  pointer-events: all;
  z-index: 100;
}
.selections--all-current .selections-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 30px;
}
@media screen and (max-width: 580px) {
  .selections--all-current .selections-wrap {
    justify-content: center;
  }
}
.selections--all-current .selections-wrap .card_view--active {
  opacity: 1;
  pointer-events: all;
  position: unset;
  top: unset;
  left: unset;
  color: var(--white);
  margin-right: 10px;
  margin-left: 8.3333333333vw;
  visibility: visible;
  margin-top: 150px;
}
@media screen and (max-width: 1440px) {
  .selections--all-current .selections-wrap .card_view--active {
    margin-top: 7.8125vw;
  }
}
@media screen and (max-width: 991px) {
  .selections--all-current .selections-wrap .card_view--active {
    margin-top: max(30px, 7.0635721493vw);
  }
}
.selections--all-current .selections-wrap .card_view--active-image {
  border-color: var(--white);
}
.selections--all-current .selections-wrap .card_view--active-description ul li:before {
  filter: invert(1);
}

.homepage__social .slider-wrap {
  display: none;
}

.details--pre-order {
  margin-bottom: 10px;
  cursor: pointer;
}

.old-price {
  text-decoration: line-through;
  font-style: italic;
}

.has-page-message, .has-page-message-error, .has-page-message-success {
  margin: auto;
  max-width: 600px;
  text-align: center;
  border: 1px solid;
  border-radius: 100px;
  padding: 15px;
  margin-bottom: 20px;
}
.has-page-message-wrap {
  margin-left: 15px;
  margin-right: 15px;
}
.has-page-message-error {
  color: var(--red);
}
.has-page-message-success {
  color: var(--green-dark);
}

.account-titles--custom, .account-block-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(18px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}
.account--ul, .account-block ul {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 1.0416666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.account-table--head, .account-table thead > tr > td {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.account-table--body, .account-table tbody > tr > td {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.account-page-height {
  min-height: calc(var(--screen-height) - var(--header-height));
}
.account-max--small {
  max-width: 600px;
  margin-bottom: 75px;
  margin-top: 35px;
}
.account-max--big {
  max-width: 900px;
  margin-bottom: 75px;
  margin-top: 35px;
}
.account-title {
  margin-bottom: max(20px, 2.0833333333vw);
  color: #333333;
  text-transform: uppercase;
}
.account-text {
  margin-bottom: max(20px, 2.0833333333vw);
  color: var(--green-dark);
}
.account-cta {
  margin-top: auto;
}
.account-cta--forgot {
  text-align: right;
}
.account-cta--forgot a {
  background: transparent;
  border: none;
  color: inherit;
  text-decoration: underline;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: -20px;
}
.account-cta--forgot a:hover {
  background-color: unset;
  color: unset;
  border-color: unset;
}
.account-page-heading {
  margin-bottom: max(20px, 2.0833333333vw);
  color: #333333;
  display: flex;
  flex-direction: column;
}
.account-page-heading h2 {
  font-family: var(--font-default), sans-serif;
  font-size: max(22px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-align: center;
  color: inherit;
  margin-bottom: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.account-page-heading > span {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  margin-bottom: 0;
  display: inline;
  text-align: center;
  max-width: 375px;
  margin: auto;
}
.account-page-heading > span.expand-width {
  max-width: unset;
}
.account-page-heading--logo {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}
.account-page-heading--logo svg {
  width: 200px;
}
.account-block {
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-block-title {
  margin-bottom: 5px;
  text-transform: uppercase;
}
.account-block ul li {
  margin-bottom: 5px;
}
.account-content .js-custom-field {
  display: none;
}
.account-content .if-no-socials {
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-content .select2-container {
  min-width: 100%;
}
@media screen and (max-width: 767px) {
  .account-content .table-responsive {
    border: unset;
  }
}
.account-content .input-group button {
  background-color: transparent !important;
  border: unset !important;
}
.account-content .input-group button:active {
  box-shadow: unset !important;
}
.account-content a:hover {
  color: inherit;
  text-decoration: underline;
}
.account-content p a {
  text-decoration: underline;
}
.account-content select {
  border-color: #333333;
  color: #333333;
}
.account-content input:-webkit-autofill, .account-content input:-webkit-autofill:hover, .account-content input:-webkit-autofill:focus {
  box-shadow: unset !important;
}
.account-content .inline-option,
.account-content ._newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
.account-content .inline-option > *,
.account-content ._newsletter > * {
  text-align: center;
}
.account-content .inline-option--not-centered,
.account-content ._newsletter--not-centered {
  align-items: initial;
}
.account-content .inline-option--not-centered > *,
.account-content ._newsletter--not-centered > * {
  text-align: left;
}
.account-content .inline-option label,
.account-content ._newsletter label {
  left: 0;
  top: 0;
}
.account-content .inline-option label.radio-inline,
.account-content ._newsletter label.radio-inline {
  padding: 5px;
}
@media screen and (min-width: 992px) {
  .account-content .inline-option label.radio-inline,
.account-content ._newsletter label.radio-inline {
    font-size: 75%;
  }
}
.account-content #d_social_login {
  font-size: 12px;
}
.account-content .order-pag {
  font-size: 14px;
}
.account-content thead tr td {
  color: var(--green-dark);
}
.account-content tfoot {
  font-size: 14px;
}
.account-content tfoot tr:last-of-type {
  text-transform: uppercase;
}
.account-dual-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: max(20px, 4.6875vw);
  padding-top: max(20px, 4.6875vw);
  border-bottom: 1px solid var(--green-dark);
}
@media screen and (max-width: 991px) {
  .account-dual-block {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    padding-bottom: 42px;
  }
}
.account-dual-block--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  max-width: 512px;
}
@media screen and (max-width: 991px) {
  .account-dual-block--item {
    width: 100%;
    text-align: center;
  }
  .account-dual-block--item:first-child {
    margin-top: 25px;
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block--item:first-child {
    margin-right: 58px;
  }
  .account-dual-block--item:last-child {
    margin-left: 58px;
  }
}
@media screen and (min-width: 992px) {
  .account-dual-block--item p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991px) {
  .account-dual-block--item p {
    text-align: center !important;
  }
}
.account-single-block {
  display: flex;
  justify-content: center;
  margin-bottom: max(20px, 4.6875vw);
  margin-top: max(20px, 4.6875vw);
  padding: unset;
  color: var(--white) !important;
  min-height: 547px;
}
@media screen and (min-width: 992px) and (max-width: 1550px) {
  .account-single-block {
    margin-top: -20px;
    transform: scale(0.9);
    transform-origin: top;
  }
}
.account-single-block input[name=email],
.account-single-block input[name=password] {
  padding-top: 12px;
  padding-bottom: 12px;
}
.account-single-block.arc:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 477.7 547' style='enable-background:new 0 0 477.7 547' xml:space='preserve'%3E%3Cpath d='M477.5 233.5C475 104.1 369.1 0 238.9 0S2.9 104.1.3 233.5L0 547h478l-.5-313.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 480px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .account-single-block.arc:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 332 521' style='enable-background:new 0 0 332 521' xml:space='preserve'%3E%3Cpath d='m331.3 378-.3-216.6C329.2 71.9 256.1 0 166.1 0S3 71.9 1.2 161.4L1 378h.1L1 521h330.3l-.2-143h.2z'/%3E%3C/svg%3E");
    max-width: 332px;
  }
}
.account-single-block--logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  margin-bottom: 50px;
  width: 150px;
}
@media screen and (max-width: 767px) {
  .account-single-block--logo {
    margin-top: 70px;
    margin-bottom: 40px;
  }
}
.account-single-block--logo svg {
  width: 150px;
}
.account-single-block--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  max-width: max(285px, 18.2291666667vw);
}
.account-single-block--item.account-success {
  width: 75%;
  max-width: 75%;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 991px) {
  .account-single-block--item.account-success {
    width: 300px;
    max-width: 300px;
  }
}
.account-single-block--item.account-success .account-success-bottom {
  position: absolute;
  width: 100%;
  bottom: 85px;
}
.account-single-block--item .account-success-content {
  font-family: var(--font-default), sans-serif;
  font-size: max(17px, 0.8854166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 10px;
}
@media screen and (max-width: 991px) {
  .account-single-block--item {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .account-single-block--item:first-child {
    margin-right: 58px;
  }
  .account-single-block--item:last-child {
    margin-left: 58px;
  }
}
.account-single-block--item .account-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-single-block--item .account-form--field {
  width: 100%;
}
.account-single-block--item .account-form--field:first-child {
  margin-bottom: 10px;
}
.account-single-block--item .account-form--field:not(:first-child) {
  margin-bottom: 10px;
}
.account-single-block--item .account-form--field .account-create {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-top: 20px;
}
.account-single-block--item .account-form--field .account-create a:hover {
  text-decoration: none;
}
.account-single-block--item .account-cta--forgot {
  line-height: 0;
}
.account-single-block--item .account-cta--forgot a {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  position: relative;
  right: unset;
  bottom: unset;
}
.account-single-block--item .account-socials #d_social_login {
  margin: unset;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__label {
  display: none;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button {
  width: 40px;
  height: 40px;
  background-color: unset;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button__icon, .account-single-block--item .account-socials #d_social_login .d-social-login__button__label {
  display: none;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button-list {
  display: flex;
  justify-content: center;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button:before {
  content: "";
  display: flex;
  width: 40px;
  height: 40px;
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button--facebook:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 27.5 27.4' style='enable-background:new 0 0 27.5 27.4' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M15.3 23.9h-3.8v-9.1h-3v-3.9h3V8.4c0-.7.1-1.3.3-1.9.5-1.4 1.5-2.3 3-2.7.4-.1.8-.2 1.3-.2.8 0 1.6 0 2.4.1h.6v3.5h-2c-.3 0-.5 0-.8.1-.5.1-.8.5-.9 1v2.5h3.5l-.5 3.9h-3v1l-.1 8.2zm-3.6-.3H15v-9.1h3.1l.4-3.3H15V8.3c.1-.6.4-1.1 1.1-1.2.3-.1.6-.2.9-.2h1.8v-3h-.3c-.8-.1-1.6-.1-2.4-.1-.4 0-.8.1-1.2.2-1.4.4-2.3 1.2-2.8 2.5-.2.6-.3 1.1-.3 1.8v2.8h-3v3.3h2.9v9.2z'/%3E%3Cpath fill='%23fff' d='M13.7 27.4C6.1 27.4 0 21.3 0 13.7S6.1 0 13.7 0s13.7 6.2 13.7 13.7-6.1 13.7-13.7 13.7zm0-27.2C6.3.2.2 6.2.2 13.7s6 13.5 13.5 13.5 13.5-6 13.5-13.5S21.1.2 13.7.2z'/%3E%3C/svg%3E");
}
.account-single-block--item .account-socials #d_social_login .d-social-login__button--google:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.8 27.8' style='enable-background:new 0 0 27.8 27.8' xml:space='preserve'%3E%3Cpath d='M13.9.1C6.3.1.1 6.3.1 13.9s6.2 13.8 13.8 13.8 13.8-6.2 13.8-13.8S21.5.1 13.9.1zm3.4 13.8c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3S7.5 7.6 11 7.6c1.4 0 2.7.4 3.8 1.3l-1.5 1.9c-.7-.5-1.5-.8-2.4-.8C8.8 10 7 11.7 7 13.9s1.7 3.9 3.9 3.9c1.7 0 3.2-1.1 3.7-2.7h-3.7v-2.4h6.3l.1 1.2zm6.6.8h-1.8v1.8h-1.6v-1.8h-1.8v-1.6h1.8v-1.8h1.6v1.8h1.8v1.6z' style='fill:none;stroke:%23fff;stroke-width:.25;stroke-miterlimit:10'/%3E%3C/svg%3E");
}
.account-single-block--item .or {
  margin-top: 20px;
  margin-bottom: 10px;
}
.account-form--field:not(.account-cta) {
  margin-bottom: max(15px, 1.5625vw);
}
.account-form--field > div:not(.d-f-column) > label:last-child {
  margin-left: 10px;
}
.account-form--field > div.d-f-column {
  display: flex;
  flex-direction: column;
}
.account-form--field .checkbox-label span {
  font-size: 1.3rem;
}
.account-form--field .flex-row label {
  margin-right: 20px;
}
.account-form--field.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-form--field--hidden {
  display: none !important;
  pointer-events: none;
}
.account-table-themed {
  border: 1px solid #333333;
  border-radius: 100px;
  padding: max(20px, 2.0833333333vw);
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-table-themed table > tbody > tr > td,
.account-table-themed table > tbody > tr > th,
.account-table-themed table > tfoot > tr > td,
.account-table-themed table > tfoot > tr > th,
.account-table-themed table > thead > tr > td,
.account-table-themed table > thead > tr > th {
  border-bottom: unset !important;
}
.account-table {
  width: 100%;
  margin-bottom: max(20px, 2.0833333333vw);
}
.account-table .nowrap {
  white-space: nowrap;
}
.account-table > tbody > tr > td,
.account-table > tbody > tr > th,
.account-table > tfoot > tr > td,
.account-table > tfoot > tr > th,
.account-table > thead > tr > td,
.account-table > thead > tr > th {
  border: none;
  border-bottom: 1px solid var(--gray-light);
  padding: 20px 15px;
}
.account-table td.text-left a {
  color: #000;
  text-decoration: none;
}
.account-table td.text-right > a {
  text-decoration: none;
}
.account-table td.text-right > a.option-hidden {
  display: none;
}
.account-table .add-to-cart-icon-wrap {
  margin-right: 10px;
  margin-left: 10px;
}
.account-table .add-to-cart-icon svg,
.account-table .add-to-cart-icon {
  display: inline-block;
  width: 23px;
  height: 18px;
}
.account-table .remove-item-icon svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.account-table .remove-item-icon svg,
.account-table .remove-item-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.account-table thead > tr > td {
  color: #000;
}
.account-table tbody > tr > td {
  color: #000;
}
.account-table .table-hover > tbody > tr {
  opacity: 0.7;
  background-color: transparent;
}
.account-table .table-hover > tbody > tr:hover {
  opacity: 1;
}
.account-table td.text-right > a.edit-address-btn,
.account-table td.text-right > a.remove-address-btn,
.account-table td.text-right > a.btn-view-item {
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #000;
  border: none;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  text-decoration: underline;
}

#d_social_login {
  display: block;
  width: 100%;
  max-width: max(285px, 18.2291666667vw);
  margin: max(20px, 2.0833333333vw) auto max(20px, 2.0833333333vw) auto;
}
#d_social_login .d-social-login__label {
  height: auto;
  margin: auto;
}
#d_social_login .d-social-login__button {
  display: block;
  width: 100%;
  color: var(--white);
  text-decoration: none;
}
#d_social_login .d-social-login__button__icon {
  max-width: 100%;
  text-align: left;
  width: 100%;
  display: block;
  position: relative;
  padding-left: 45px;
}
#d_social_login .d-social-login__button__icon img {
  max-width: 30px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
#d_social_login .d-social-login__button--facebook img {
  max-width: 10px;
}
#d_social_login .d-social-login__button--google img {
  max-width: 25px;
}

.custom-input-group button {
  background-color: transparent !important;
  border: unset !important;
  outline: unset !important;
}
.custom-input-group button:active {
  box-shadow: unset !important;
}
.custom-input-group .input-group-btn {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  width: 60px;
  display: flex;
  pointer-events: none;
}

.js-has-error {
  font-size: 12px;
  color: var(--red);
  margin-left: 20px;
}

.alert-message-danger {
  color: var(--red);
}
.alert-message-danger a {
  color: var(--black);
}

#account-account ul li a {
  text-transform: capitalize;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.checkout-container #main-header,
.checkout-container #search {
  display: none;
}
.checkout-container #d_logo {
  padding: clamp(15px, 2.6042vw, 50px);
}
.checkout-container #d_logo a {
  display: inline-block;
  margin: 0 auto;
}
.checkout-container .checkout-header {
  margin: 0 0 clamp(25px, 5.2083vw, 100px);
  padding: 0;
}
.checkout-container .header-2 {
  margin: 0;
}
.checkout-container a {
  text-decoration: underline;
}
.checkout-container .guest-login-register {
  display: flex;
}
.checkout-container .guest-login-register .btn-group {
  flex-basis: 33.333333%;
}
@media (max-width: 580px) {
  .checkout-container .guest-login-register .btn-group:nth-child(1), .checkout-container .guest-login-register .btn-group:nth-child(2) {
    flex-basis: 50%;
    margin: 0 0 5px;
  }
  .checkout-container .guest-login-register .btn-group:nth-child(3) {
    flex-basis: 100%;
  }
}
.checkout-container .checkout-left {
  border-right: 1px solid;
}
@media (max-width: 991px) {
  .checkout-container .checkout-left {
    border-right: unset;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .checkout-container .checkout-right {
    padding-right: 0;
    padding-left: 0;
  }
}
.checkout-container #d_quickcheckout {
  padding: 0 clamp(25px, 5.2083vw, 100px);
  max-width: 1800px;
  width: 100%;
}
.checkout-container .form-horizontal .radio,
.checkout-container .form-horizontal .checkbox,
.checkout-container .form-horizontal .radio-inline,
.checkout-container .form-horizontal .checkbox-inline {
  padding-top: 0;
}
@media (max-width: 767px) {
  .checkout-container .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
.checkout-container .well {
  box-shadow: none;
}
.checkout-container #qc_confirm_order {
  padding: 20px 15px;
}
.checkout-container #qc_confirm_order:disabled {
  background-color: var(--white);
  transition: all linear 0.3s;
}
@media (max-width: 580px) {
  .checkout-container #qc_confirm_order {
    padding: 15px;
  }
}
.checkout-container .text-danger {
  margin: 0 !important;
}
.checkout-container button.decrease, .checkout-container button.increase {
  display: none;
}
.checkout-container button.delete {
  background-color: unset;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: unset;
}
.checkout-container .panel {
  border-radius: 0;
  border: none;
  background: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .checkout-container .panel {
    margin-bottom: 0;
  }
}
.checkout-container .panel .panel-body {
  padding: 15px 0;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-body {
    padding: 10px;
  }
}
.checkout-container .panel .panel-heading {
  background-color: unset;
  border-radius: 0;
  border: none;
  padding: 10px 0;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-heading {
    padding: 10px 10px 0 10px;
  }
}
.checkout-container #payment_address_heading_heading > i {
  display: none;
}
.checkout-container .btn {
  box-shadow: none !important;
  text-shadow: none !important;
}
.checkout-container #login_view .guest-login-register {
  display: flex;
  flex-wrap: wrap;
}
.checkout-container #cart_view .qc-name a {
  text-decoration: none;
}
.checkout-container #cart_view .table,
.checkout-container #cart_view .table td {
  border: none;
}
@media (max-width: 580px) {
  .checkout-container #cart_view .table,
.checkout-container #cart_view .table td {
    font-size: 1.2rem;
  }
}
.checkout-container #cart_view .table thead {
  border-bottom: 1px solid;
}
.checkout-container #cart_view .qc-coupon {
  margin: 0 0 30px;
}
.checkout-container #cart_view .qc-coupon .col-sm-12 {
  padding: 0;
}
.checkout-container #cart_view .qc-coupon #coupon,
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  height: 40px;
}
.checkout-container #cart_view .qc-coupon #coupon {
  width: 100%;
  min-height: 35px;
  padding: 5px 15px;
}
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  display: block;
  max-width: 135px;
  width: 100%;
  padding: 5px 20px;
  height: 40px;
}
.checkout-container #cart_view .qc-totals label {
  text-align: left !important;
  font-weight: 600;
  padding: 5px 0;
}
.checkout-container #cart_view .qc-totals .row {
  margin: 0;
  text-align: right;
  border-bottom: 1px solid;
  padding: 10px 0;
}
.checkout-container #cart_view .qc-totals .row:last-child {
  border: 0;
  margin-top: 0;
}
.checkout-container #cart_view .qc-product-qantity {
  max-width: 50px;
  min-height: 35px;
  padding: 5px;
}
.checkout-container #cart_view .qc-quantity {
  width: 20%;
}
.checkout-container #cart_view .qc-checkout-product table.table tbody td {
  vertical-align: middle;
}
.checkout-container #payment_address_form,
.checkout-container #shipping_address_form {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.checkout-container #payment_address_form > div,
.checkout-container #shipping_address_form > div {
  flex-basis: calc(50% + 15px);
}
.checkout-container #payment_address_newsletter_input,
.checkout-container #payment_address_email_input,
.checkout-container #payment_address_email_confirm_input,
.checkout-container #payment_address_company_input,
.checkout-container #payment_address_address_1_input,
.checkout-container #payment_address_city_input,
.checkout-container #payment_address_postcode_input,
.checkout-container #payment_address_customer_group_id_input,
.checkout-container #payment_address_custom_field\.account\.1_input,
.checkout-container #payment_address_shipping_address_input,
.checkout-container #payment_address_agree_input,
.checkout-container #payment_address_heading_heading {
  flex-basis: calc(100% + 30px) !important;
}
.checkout-container #payment_address_customer_group_id_input .col-xs-12:first-child {
  display: none;
}
.checkout-container #confirm_form {
  margin-bottom: clamp(30px, 3.125vw, 60px);
}
.checkout-container #confirm_comment {
  min-height: 140px;
  resize: vertical;
}
.checkout-container #payment_address_newsletter_input label,
.checkout-container #payment_address_shipping_address_input label,
.checkout-container #payment_address_agree_input label,
.checkout-container #payment_address_heading_heading label {
  text-align: left;
}
.checkout-container #payment_address_heading_heading {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .checkout-container #payment_address_heading_heading {
    margin-bottom: 10px;
  }
}
.checkout-container #login_model {
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .checkout-container #payment_address_form > div,
.checkout-container #shipping_address_form > div {
    flex-basis: calc(100% + 30px) !important;
  }
  .checkout-container #payment_address_form > div > div,
.checkout-container #shipping_address_form > div > div,
.checkout-container #confirm_comment_input > div,
.checkout-container #confirm_agree_input > div {
    padding: 0;
  }
  .checkout-container #payment_view {
    padding: 10px;
  }
}

.shopping-cart-product-title, .shopping-cart__total > div, .shopping-cart__item-info--details, .shopping-cart__item-info--title {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

.shopping-cart {
  display: flex;
  flex-direction: column;
}
.shopping-cart__block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: max(30px, 2.6041666667vw);
}
.shopping-cart__block > * {
  flex: 1;
}
@media screen and (max-width: 767px) {
  .shopping-cart__block > *.shopping-cart__item-info {
    max-width: 170px;
  }
}
.shopping-cart__image {
  max-width: max(60px, 4.1666666667vw);
}
.shopping-cart__item-info {
  margin-left: 10px;
}
.shopping-cart__item-info--title {
  margin-bottom: 5px;
}
.shopping-cart__item-info--details {
  display: inline-flex;
  margin-bottom: 5px;
  font-size: 85%;
}
@media screen and (max-width: 767px) {
  .shopping-cart__quantity {
    margin-left: auto;
    max-width: 160px;
  }
}
.shopping-cart__quantity--wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .shopping-cart__quantity--wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
.shopping-cart__quantity--control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
}
.shopping-cart__quantity--control > * button {
  padding: 5px;
}
.shopping-cart__quantity--inputs {
  display: flex;
  margin-right: 10px;
}
.shopping-cart__quantity--inputs > div {
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  background-color: var(--white);
  line-height: 0;
}
.shopping-cart__quantity--inputs > div:not(:nth-child(2)) {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  border-right: 1px solid var(--black);
  border-left: 1px solid var(--black);
  cursor: pointer;
}
.shopping-cart__quantity--inputs > div input {
  background-color: transparent;
  outline: unset;
  border: none;
  border-radius: unset;
  box-shadow: none;
  pointer-events: none;
  text-align: center;
}
.shopping-cart__quantity--input {
  min-width: 70px;
}
.shopping-cart__quantity--refresh button {
  background-color: var(--white);
  color: var(--black);
  border: unset;
  outline: none;
}
.shopping-cart__quantity--refresh button:hover {
  background-color: var(--white);
  border: unset;
  color: var(--black);
}
@media screen and (max-width: 767px) {
  .shopping-cart__quantity .shopping-cart__quantity--inputs {
    transform: scale(0.6);
  }
  .shopping-cart__quantity .shopping-cart__quantity--wrap {
    justify-content: flex-start;
  }
  .shopping-cart__quantity .shopping-cart__quantity--refresh {
    position: absolute;
    top: 0;
    left: 125px;
    bottom: 0;
    transform: scale(0.8);
  }
  .shopping-cart__quantity .shopping-cart__remove-item {
    position: absolute;
    bottom: -17px;
    left: 52px;
  }
}
.shopping-cart__remove-item button {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: italic;
  line-height: 1;
  margin-bottom: 10px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: unset;
}
.shopping-cart__remove-item button .icon--delete {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.shopping-cart__remove-item button .icon--delete svg {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.shopping-cart__remove-item button .icon--delete svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.shopping-cart__total {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-top: 30px;
  padding-bottom: 30px;
}
.shopping-cart__total > div {
  text-transform: uppercase;
}
.about-video-container {
  width: 100%;
  padding: 0 max(30px, 5.2083333333vw);
  max-width: 100%;
  margin: max(15px, 3.6458333333vw) auto max(20px, 6.5104166667vw);
}
@media screen and (max-width: 991px) {
  .about-video-container {
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .about-video-container {
    padding: 0;
  }
}
.about-video-container video {
  width: 100%;
  max-width: 1710px;
  margin: 0 auto;
}

.about-container {
  padding: 0 max(15px, 2.6041666667vw);
}
.about-container .about-text-container {
  font-family: var(--font-default), sans-serif;
  text-align: center;
  width: 100%;
  max-width: max(260px, 41.40625vw);
  margin: 0 auto;
  font-weight: 300;
}
.about-container .about-text-container .about-title {
  font-weight: 100;
  font-style: normal;
  font-size: max(22px, 3.28125vw);
  margin-bottom: max(20px, 2.6041666667vw);
}
.about-container .about-text-container p {
  font-weight: 300;
  font-style: normal;
  display: block;
  font-size: 15px;
}
@media screen and (max-width: 580px) {
  .about-container .about-text-container p {
    font-size: 13px;
  }
}

.about-photo-container {
  width: 100%;
  max-width: max(329px, 51.3020833333vw);
  margin: max(15px, 7.03125vw) auto max(35px, 11.1979166667vw);
}
@media screen and (max-width: 580px) {
  .about-photo-container {
    margin: 15px auto 35px;
  }
}

.category > * {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
}
.category__intro {
  margin-top: max(70px, 5.7291666667vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.category__intro--title {
  margin-bottom: max(25px, 2.34375vw);
}
.category__intro--content {
  max-width: max(275px, 38.0208333333vw);
  margin-bottom: max(25px, 3.125vw);
}
.category-listing {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.0416666667vw;
  padding-left: max(15px, 3.125vw);
  padding-right: max(15px, 3.125vw);
}
@media screen and (max-width: 767px) {
  .category-listing {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.category-listing__item {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  width: var(--listing-view);
  margin-bottom: 45px;
  padding: 3px;
  opacity: 0;
}
.category-listing__item a {
  display: flex;
}
.category-listing__item a:hover {
  color: inherit;
  text-decoration: none;
}
@media (hover: hover) {
  .category-listing__item:hover {
    scale: 0.8;
  }
}
.category-listing__item.out-of-stock .card_view--image:after {
  content: "OUT OF STOCK";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.35);
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .category-listing > *:nth-child(2) {
    margin-left: 50%;
  }
  .category-listing > *:nth-child(9) {
    width: 100%;
  }
}
.category-listing__group {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .category-listing__group {
    flex-wrap: wrap;
  }
}
.category-listing__group__display {
  position: absolute;
  bottom: 3px;
  right: 3px;
  width: calc(50% - 6px);
  height: calc(50% - 6px - 51px);
}
@media screen and (max-width: 767px) {
  .category-listing__group__display {
    height: calc(33.3333333333% - 6px);
    left: 3px;
    right: unset;
    top: calc(100% + 3px);
    bottom: unset;
    display: flex;
    align-items: flex-end;
    padding-bottom: 72px;
  }
}
.category-listing__group__display .underline--black {
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .category-listing__group__display .underline--black {
    font-size: 12px;
    font-weight: 500;
  }
}
.category-listing__group__display__wrap {
  width: max(150px, 16.1979166667vw);
  margin-left: 11.1979166667vw;
  margin-top: 4.1666666667vw;
}
@media screen and (max-width: 991px) {
  .category-listing__group__display__wrap {
    margin-top: unset;
  }
}
@media screen and (max-width: 767px) {
  .category-listing__group__display__wrap {
    margin-left: unset;
  }
}
@media screen and (max-width: 767px) {
  .category-listing__group__display__wrap .title {
    font-size: 40px;
  }
}
.category-listing__group > *:first-child {
  width: 50%;
}
@media screen and (max-width: 991px) {
  .category-listing__group > *:first-child {
    width: 100%;
  }
}
.category-listing__group.reversed {
  align-items: flex-end;
}
.category-listing__group.reversed > *:first-child {
  width: 25%;
}
@media screen and (max-width: 991px) {
  .category-listing__group.reversed > *:first-child {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .category-listing__group.reversed > *:first-child {
    margin-right: 50%;
  }
}
.category-listing__group.reversed > *:nth-child(3) {
  width: 50%;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .category-listing__group.reversed > *:nth-child(3) {
    width: 100%;
  }
}
.category-listing__group.reversed .category-listing__group__display {
  top: 3px;
  left: 3px;
  bottom: unset;
  right: unset;
}
@media screen and (max-width: 767px) {
  .category-listing__group.reversed .category-listing__group__display {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    left: unset;
    right: 3px;
    padding-bottom: 110px;
    bottom: 50%;
    top: unset;
  }
}
.category-bridal__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 93.5416666667vw;
  margin: auto;
}
.category-bridal__introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 33px;
  padding-top: 57px;
}
.category-bridal__introduction__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 3.125vw);
  font-weight: 100;
  font-style: normal;
  line-height: max(42px, 3.75vw);
  text-transform: uppercase;
  margin-bottom: 36px;
}
.category-bridal__introduction__content {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  color: var(--gray-medium);
  width: 100%;
  max-width: max(340px, 46.3541666667vw);
}

.filters-wrap {
  padding-left: max(15px, 3.125vw);
  padding-right: max(15px, 3.125vw);
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .filters-wrap {
    padding-top: 15px;
    padding-left: 15px;
  }
}
.filters-wrap--left {
  display: inline-flex;
  align-items: center;
}
.filters-wrap--left .filters--label {
  cursor: pointer;
}
@media screen and (max-width: 580px) {
  .filters-wrap--left .filters--label {
    margin-right: 10px;
  }
  .filters-wrap--left .filters--label span:first-child, .filters-wrap--left .filters--label span:nth-child(2) {
    display: none;
  }
}
.filters-wrap--right .view__selection {
  cursor: pointer;
  margin-left: 5px;
}
.filters-wrap--right .view__selection:hover, .filters-wrap--right .view__selection.view--selected {
  text-decoration: underline;
}
@media screen and (min-width: 992px) {
  .filters-wrap--right .view__selection:not(:nth-child(4), :nth-child(6)) {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .filters-wrap--right .view__selection:not(:nth-child(1), :nth-child(2), :nth-child(4)) {
    display: none;
  }
}
.filters__product {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 25px;
  width: max(375px, 26.0416666667vw);
  max-width: max(375px, 26.0416666667vw);
  z-index: 1;
  box-shadow: 10px 10px 20px -10px var(--black);
  padding: 10px;
  background-color: var(--white);
  opacity: 0;
  pointer-events: none;
  margin-left: min(-5px, -0.5208333333vw);
  overflow-y: auto;
  max-height: max(375px, 39.5833333333vw);
}
@media screen and (max-width: 580px) {
  .filters__product {
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    max-width: 100%;
    max-height: calc(var(--screen-height) - var(--header-height));
    margin-left: unset;
  }
}
.filters__product.open {
  opacity: 1;
  pointer-events: all;
}
.filters__product .list-group,
.filters__product .swiper-viewport {
  display: none;
}
.filters__product .box {
  border: none;
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
}
.filters__product .box-heading {
  display: none;
}
.filters__product .ui-slider-range.ui-widget-header {
  background: var(--black);
}
.filters__product span.ui-slider-handle {
  background: var(--white) !important;
  color: var(--black);
  width: auto !important;
  height: 20px !important;
  border: 1px solid var(--black) !important;
  border-radius: 8px !important;
  text-align: center;
  padding: 0 7px;
  font-size: 1.1rem;
  top: -8px;
  display: flex;
  align-items: center;
}
.filters__product span.ui-slider-handle:last-child {
  -webkit-transform: translate(-69%, 0);
  -moz-transform: translate(-69%, 0);
  -o-transform: translate(-69%, 0);
  transform: translate(-69%, 0);
}
.filters__product .bf-clickable {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  background-color: transparent !important;
  padding: 7px 0;
}
.filters__product .bf-attr-header {
  border: unset;
}
.filters__product .bf-count {
  background-color: var(--black) !important;
}
.filters__product .bf-buttonclear-box {
  display: none;
  border: 1px solid var(--black);
  background-color: var(--black);
}
@media (max-width: 767px) {
  .filters__product .bf-buttonclear-box {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    z-index: 9;
  }
}
.filters__product .bf-buttonclear-box .bf-buttonclear {
  background: none !important;
  background-repeat: no-repeat;
  text-decoration: none;
  padding-left: 0;
  line-height: initial;
  color: var(--white);
}
.filters__product .bf-buttonclear-box .bf-buttonclear:hover {
  background: none !important;
}
.filters__product .bf-slider-container.ui-slider-horizontal, .filters__product .bf-slider-range.ui-slider-horizontal {
  height: 3px;
}
.filters__product .bf-price-container.box-content.bf-attr-filter > .bf-cur-symb {
  display: none;
}
.filters__product .bf-attr-block .bf-close-btn,
.filters__product .bf-attr-block .bf-arrow {
  position: absolute;
  top: 40%;
  right: 0;
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%);
}
.filters__product .bf-cell {
  border-bottom: none !important;
}
.filters__product .bf-cell.bf-cascade-1 {
  background-image: none;
  padding-left: 0;
}
.filters__product .bf-cell.bf-cascade-2 {
  background-image: none !important;
}
.filters__product .bf-cell input[type=checkbox]:disabled {
  opacity: 0.1;
}
.filters__product .bf-attr-block-cont {
  padding: 0;
}
.filters__product .bf-attr-filter {
  display: block;
  margin-bottom: 15px;
}
.filters__product .bf-attr-filter .bf-cell:last-child {
  display: none;
}
.filters__product .bf-attr-filter .bf-cell label {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  margin-bottom: unset;
}
.filters__product .bf-attr-filter .bf-cell label:after {
  display: inline-block;
  content: "";
  width: 0;
  background-color: #000000;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.filters__product .bf-attr-filter .bf-cell label:hover:after {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.filters__product .bf-attr-block .bf-attr-block-cont {
  padding: 10px 0;
}
@media (max-width: 767px) {
  .filters__product .bf-cross, .filters__product .bf-close-btn, .filters__product .bf-arrow {
    background-image: url(../../../image/brainyfilter/sprite.png) !important;
  }
}
.filters__product input[type=reset] {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  background-color: transparent;
  color: var(--white);
  border-color: var(--black);
  text-align: center;
  width: auto;
  margin: auto;
}
.filters__product input[type=text] {
  border: 1px solid #000000;
}
.filters--underlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 580px) {
  .filters--underlay {
    position: absolute;
    left: unset;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: 99;
  }
}
.filters--underlay .icon-x {
  display: block;
  width: 35px;
  height: 35px;
  padding: 10px;
}
@media screen and (min-width: 581px) {
  .filters--underlay .icon-x {
    display: none;
  }
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination > li > a, .pagination > li > span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #000000;
  background-color: transparent;
  border: none;
  padding: 12px;
}
.pagination > li > a:focus, .pagination > li > a:hover {
  color: #000000;
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.pagination > li > span:focus, .pagination > li > span:hover {
  color: #000000;
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.025em;
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.flex-row.category-pagination {
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.category-pagination span.arrow-left-start {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-left-first.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-right-end {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-right-last.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-next {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-next.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-prev {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-prev.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

@media (max-width: 767px) {
  .close-filters {
    display: none !important;
    position: absolute;
    top: 0;
    right: 0;
  }
  .close-filters-icon svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 5px;
  }
  .close-filters-icon svg .st0 {
    fill: var(--black);
  }
}
ul.pagination,
.load-more {
  display: none;
}

.results-info {
  color: white;
  text-align: center;
}

.basel-spinner:after {
  content: "";
  display: inline-block;
  border: 2px solid #cccccc;
  border-top: 2px solid #333333;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 500ms linear infinite;
}

.basel-spinner.light:after {
  border: 2px solid #cccccc;
  border-top: 2px solid #ffffff;
}

.basel-spinner.ajax-call:after {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -18px auto auto -18px;
}

.basel-spinner.ajax-call {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  display: inline-block;
  background: transparent;
  border-radius: 3px;
  margin: -40px auto auto -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 767px) {
  body.strip--products .category-listing__item {
    margin-bottom: 0;
  }
  body.strip--products .category-listing__item .card_view--image .overlay_card {
    display: none;
  }
  body.strip--products .category-listing__item .card_view--footer {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  .modal#bookModal .modal-dialog {
    width: 85.15625vw;
  }
}
.modal#bookModal .modal-content {
  padding-top: 53px;
  padding-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .modal#bookModal .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.modal#bookModal .modal-content .modal-close {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.modal#bookModal .modal-content .modal-close:hover {
  opacity: 0.5;
}
.modal#bookModal .modal-content .modal-close span.icon-close {
  display: flex;
  flex-shrink: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.843 23.486'%3E%3Cg data-name='Group 833' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='1.015'%3E%3Cpath data-name='Line 89' d='m.364.354 22.114 22.779'/%3E%3Cpath data-name='Line 90' d='M22.478.354.364 23.133'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: 22.843px;
  height: 23.486px;
}

/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
}

ul.brand-anchor {
  padding-bottom: 30px;
}

ul.brand-anchor li {
  max-width: 30px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 7px 7px 0;
  background-color: #f0f0f0;
}

ul.brand-anchor li > a {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  padding: 7px 0;
}

ul.brand-anchor li:hover {
  background-color: #000;
}

ul.brand-anchor li:hover a {
  color: #fff;
}

.brand-letter {
  display: inline-block;
  width: 100%;
  padding: 0 0 20px;
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #eee;
}

.letter_only {
  display: inline-block;
  float: left;
  width: 10%;
}

.brands_per_letter {
  display: inline-block;
  float: left;
  width: 90%;
}

#product-manufacturer .brand-name {
  margin: 0 0 20px 0;
  font-weight: 800;
}

.brands_per_letter a {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
  padding: max(35px, 4.1666666667vw) max(15px, 4.6875vw) max(35px, 7.03125vw);
}
@media (max-width: 991px) {
  .product-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  .product-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.product-container .breadcrumbs-wrap {
  padding: 0;
}
.product-container .product-row {
  max-width: 1460px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .product-container .product-row {
    flex-direction: column;
  }
}
.product-container .product-row .left-col {
  width: 100%;
  max-width: 755px;
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .product-container .product-row .left-col {
    padding-bottom: 0;
  }
}
.product-container .product-row .left-col .product--images {
  max-width: max(340px, 39.3229166667vw);
  display: flex;
  flex-direction: row;
  height: max(440px, 46.875vw);
  max-height: max(440px, 46.875vw);
}
.product-container .product-row .left-col .product--images .gallery-top img {
  width: 100%;
  object-fit: cover;
  max-width: max(285px, 31.25vw);
  height: 100%;
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images .gallery-top img {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images {
    height: 85vw;
    min-height: 600px;
    max-height: 600px;
    max-width: 560px;
  }
}
@media (max-width: 580px) {
  .product-container .product-row .left-col .product--images {
    max-height: 530px;
    min-height: 530px;
  }
}
@media (max-width: 420px) {
  .product-container .product-row .left-col .product--images {
    height: 95vw;
    max-height: 465px;
    min-height: 465px;
  }
}
.product-container .product-row .left-col .product--images .swiper-container {
  overflow: hidden;
  max-height: 46.875vw;
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images .swiper-container {
    max-height: 95vw;
  }
}
.product-container .product-row .left-col .product--images .gallery-top {
  margin-right: 5px;
  max-width: max(285px, 31.25vw);
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images .gallery-top {
    max-width: 80vw;
  }
}
.product-container .product-row .left-col .product--images .gallery-top .MagicZoom {
  z-index: 999 !important;
}
.product-container .product-row .left-col .product--images .gallery-top video {
  width: 100%;
  object-fit: cover;
  max-width: max(285px, 31.25vw);
  height: 100%;
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images .gallery-top video {
    max-width: 100%;
  }
}
.product-container .product-row .left-col .product--images .gallery-thumbnail {
  position: relative;
  width: 100%;
  max-width: max(70px, 7.7604166667vw);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide {
  cursor: pointer;
  width: 100%;
  max-width: max(70px, 7.7604166667vw);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide img {
  object-fit: cover;
  width: 100%;
  max-width: max(70px, 7.7604166667vw);
  height: 100%;
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide span.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide span.video-overlay .video-play {
  cursor: pointer;
  display: flex;
  width: max(35px, 3.6458333333vw);
  height: max(35px, 3.6458333333vw);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide span.video-overlay .video-play svg {
  display: flex;
  width: max(35px, 3.6458333333vw);
  height: max(35px, 3.6458333333vw);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide span.video-overlay .video-play svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.5669;
  stroke-miterlimit: 10;
}
.product-container .product-row .left-col .product--images .gallery-thumbnail video {
  width: 100%;
  object-fit: cover;
  max-height: max(90px, 11.71875vw);
}
@media (max-width: 767px) {
  .product-container .product-row .left-col .product--images .gallery-thumbnail {
    max-width: 16vw;
  }
  .product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide {
    max-width: 16vw;
  }
  .product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-slide img {
    max-width: 16vw;
  }
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-button-next {
  margin: 0 !important;
  top: unset;
  bottom: 0;
  width: 26px;
  height: 18px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-button-next:after {
  content: "";
  background-image: url("../../../icons/arrow-next-white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 18px;
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-button-prev {
  margin: 0 !important;
  top: 0;
  width: 26px;
  height: 18px;
  right: unset;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.product-container .product-row .left-col .product--images .gallery-thumbnail .swiper-button-prev:after {
  content: "";
  background-image: url("../../../icons/arrow-prev-white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 18px;
}
.product-container .product-row .right-col {
  width: 100%;
  max-width: 460px;
  position: sticky;
  top: calc(var(--header-height) + 32px);
}
@media (max-width: 767px) {
  .product-container .product-row .right-col {
    max-width: 100%;
    padding-top: 25px;
    position: unset;
    top: unset;
  }
}
.product-container .product-row .right-col .product-details {
  width: 100%;
}

.product-container .product-row .left-col.off-white .product--images .gallery-thumbnail .swiper-button-next:after {
  background-image: url("../../../icons/arrow-next-gray.svg");
}
.product-container .product-row .left-col.off-white .product--images .gallery-thumbnail .swiper-button-prev:after {
  background-image: url("../../../icons/arrow-prev-gray.svg");
}

.photo-slider-handlers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: max(250px, 31.25vw);
  margin-top: 20px;
}
.photo-slider-handlers li {
  display: flex;
}
.photo-slider-handlers li:not(:last-child) {
  margin-right: max(10px, 1.3020833333vw);
}
.photo-slider-handlers li #go-to-first-element {
  cursor: pointer;
  display: flex;
  width: max(20px, 2.0833333333vw);
  height: max(20px, 2.0833333333vw);
}
.photo-slider-handlers li #go-to-first-element svg {
  width: max(20px, 2.0833333333vw);
  height: max(20px, 2.0833333333vw);
}
.photo-slider-handlers li #go-to-first-element svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.5669;
  stroke-miterlimit: 10;
}
.photo-slider-handlers li #go-to-video {
  cursor: pointer;
  width: max(20px, 2.0833333333vw);
  height: max(20px, 2.0833333333vw);
}
.photo-slider-handlers li #go-to-video svg {
  width: max(20px, 2.0833333333vw);
  height: max(20px, 2.0833333333vw);
}
.photo-slider-handlers li #go-to-video svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.5669;
  stroke-miterlimit: 10;
}

.fancybox-navigation {
  top: 50%;
}

.fancybox-thumbs__list a:before {
  border-color: var(--beige);
}

.fancybox-progress {
  background: var(--beige);
}

/***Product info***/
h1.product-name {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.875vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-bottom: 5px;
}

h2.product-description {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 1.5104166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--gray-light);
}

.price-range {
  margin: 20px 0;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 1.25vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}

.product-price-list {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
}
.product-price-list li:not(:last-child) {
  margin-right: 35px;
}
.product-price-list li .product-price {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 1.25vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--brown);
}
.product-price-list li .product-price.old {
  font-style: italic;
  text-decoration: line-through;
}

.product-color-title,
.product-quantity-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.0416666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--brown);
  padding-bottom: 10px;
  padding-top: 10px;
}

.product-colors {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.product-colors li:not(:last-child) {
  margin-right: 15px;
}
.product-colors li .product-color {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.0416666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--brown);
  position: relative;
}
.product-colors li .product-color:after {
  display: inline-block;
  content: "";
  height: 1px;
  width: 0;
  background-color: var(--brown);
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.product-colors li .product-color:hover, .product-colors li .product-color:focus {
  text-decoration: none;
}
.product-colors li .product-color:hover:after, .product-colors li .product-color:focus:after {
  width: 100%;
}
.product-colors li .product-color.color-selected:after {
  width: 100%;
}

#product .form-group .control-label,
#gift-card-form .form-group .control-label {
  display: none;
}
#product .form-group .input-radio-inline,
#gift-card-form .form-group .input-radio-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#product .form-group .input-radio-inline .radio,
#gift-card-form .form-group .input-radio-inline .radio {
  margin: 0;
}
#product .form-group .input-radio-inline .radio:not(:last-child),
#gift-card-form .form-group .input-radio-inline .radio:not(:last-child) {
  margin-right: 6px;
}
#product .form-group .input-radio-inline .radio label,
#gift-card-form .form-group .input-radio-inline .radio label {
  display: block;
  position: relative;
  padding-left: 44px;
  cursor: pointer;
  font-family: var(--font-default), sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 46px;
  height: 46px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);
  margin-bottom: 0;
}
#product .form-group .input-radio-inline .radio label input[type=radio],
#gift-card-form .form-group .input-radio-inline .radio label input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#product .form-group .input-radio-inline .radio label input[type=radio]:checked ~ span,
#gift-card-form .form-group .input-radio-inline .radio label input[type=radio]:checked ~ span {
  background-color: var(--black);
  color: var(--white);
}
#product .form-group .input-radio-inline .radio label input[type=radio]:checked ~ span:after,
#gift-card-form .form-group .input-radio-inline .radio label input[type=radio]:checked ~ span:after {
  display: block;
}
#product .form-group .input-radio-inline .radio label span,
#gift-card-form .form-group .input-radio-inline .radio label span {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#product .form-group .input-radio-inline .radio label span input[type=radio],
#gift-card-form .form-group .input-radio-inline .radio label span input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#product .form-group .input-radio-inline .radio label span input[type=radio]:checked ~ span,
#gift-card-form .form-group .input-radio-inline .radio label span input[type=radio]:checked ~ span {
  background-color: var(--black);
  color: var(--white);
}
#product .form-group .input-radio-inline .radio label span input[type=radio]:checked ~ span:after,
#gift-card-form .form-group .input-radio-inline .radio label span input[type=radio]:checked ~ span:after {
  display: block;
}
#product .form-group .input-radio-inline .radio label span span,
#gift-card-form .form-group .input-radio-inline .radio label span span {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#product .form-group .input-radio-inline .radio label span:hover input ~ span,
#gift-card-form .form-group .input-radio-inline .radio label span:hover input ~ span {
  background-color: var(--gray);
}
#product .form-group .input-radio-inline .radio label:not(:last-child),
#gift-card-form .form-group .input-radio-inline .radio label:not(:last-child) {
  margin-right: 5px;
}

.quantity-input-field {
  width: 100%;
  max-width: 120px;
}
@media (max-width: 580px) {
  .quantity-input-field {
    max-width: 95px;
  }
}
.quantity-input-field .quantity {
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
}
.quantity-input-field .quantity .number-input {
  position: relative;
}
.quantity-input-field .quantity input[type=text] {
  background-color: transparent;
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 1.1458333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  padding: 0;
  color: var(--black);
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  min-height: 23px;
}
.quantity-input-field .quantity .quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quantity-input-field .quantity .quantity-nav .quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-left: 1px solid var(--black);
  border-right: 1px solid var(--black);
  cursor: pointer;
}
.quantity-input-field .quantity .quantity-nav .quantity-button.quantity-down:after {
  content: "";
  background-image: url("../../../assets/icons/minus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 580px) {
  .quantity-input-field .quantity .quantity-nav .quantity-button.quantity-down:after {
    width: 10px;
    height: 10px;
  }
}
.quantity-input-field .quantity .quantity-nav .quantity-button.quantity-up:after {
  content: "";
  background-image: url("../../../assets/icons/plus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 580px) {
  .quantity-input-field .quantity .quantity-nav .quantity-button.quantity-up:after {
    width: 10px;
    height: 10px;
  }
}

.add-to-cart-cta {
  margin-top: 15px;
}
@media (max-width: 580px) {
  .add-to-cart-cta {
    position: fixed;
    bottom: 42px;
    left: 0;
    z-index: 10;
    margin-bottom: 0;
    width: 100%;
    margin-top: 0;
  }
}
.add-to-cart-cta #button-cart.cta--outlined {
  width: 100%;
  max-width: 330px;
  font-size: max(24px, 1.25vw);
  font-weight: 100;
  padding: 8px;
}
@media (max-width: 580px) {
  .add-to-cart-cta #button-cart.cta--outlined {
    max-width: 100%;
  }
  .add-to-cart-cta #button-cart.cta--outlined:hover, .add-to-cart-cta #button-cart.cta--outlined:focus {
    background-color: var(--black);
    color: var(--white);
  }
}

.wishlist-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  margin: 25px 0 10px;
}
@media (max-width: 580px) {
  .wishlist-btn {
    margin-top: 15px;
  }
}
.wishlist-btn span {
  display: inline-block;
}
.wishlist-btn span.wishlist-text {
  margin-right: 10px;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 1.0416666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--brown);
}
.wishlist-btn span.wishlist-fill-icon {
  display: block;
  width: 15px;
}
.wishlist-btn span.wishlist-fill-icon svg {
  display: block;
  width: 15px;
}

.share-section {
  display: flex;
  align-items: center;
}
.share-section .share-link {
  display: inline-block;
  margin: 0;
}
.share-section .share-link .share-text {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 1.0416666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--brown);
  margin-right: 8px;
}
.share-section .share-link .share-icon {
  display: inline-block;
  width: 16px;
  height: 15px;
}
.share-section .share-link .share-icon svg {
  display: inline-block;
  width: 16px;
  height: 15px;
}
.share-section .share-link .share-icon svg .st0 {
  fill: none;
  stroke: #231F20;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}
.share-section .share-choices {
  display: none;
  flex-direction: row;
  padding-left: 15px;
}
.share-section .share-choices.active {
  display: flex;
}
.share-section .share-choices .share-icons {
  display: flex;
}

.free-delivery-msg {
  display: block;
  width: 100%;
  max-width: 275px;
  margin: 35px 0 50px;
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.product-info.panel-group {
  width: 100%;
  max-width: 460px;
  min-width: 460px;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .product-info.panel-group {
    max-width: max(350px, 23.9583333333vw);
    min-width: max(350px, 23.9583333333vw);
  }
}
@media (max-width: 580px) {
  .product-info.panel-group {
    max-width: 100%;
    min-width: 100%;
  }
}
.product-info.panel-group .panel.panel-default {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
}
.product-info.panel-group .panel.panel-default:last-child {
  border: none;
}
.product-info.panel-group .panel.panel-default + .panel {
  margin: 0;
}
.product-info.panel-group .panel.panel-default .panel-heading {
  padding: 0;
  background-color: transparent;
}
.product-info.panel-group .panel.panel-default .panel-heading a {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-default), sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  color: var(--black);
}
@media (max-width: 767px) {
  .product-info.panel-group .panel.panel-default .panel-heading a {
    font-size: 14px;
  }
}
.product-info.panel-group .panel.panel-default .panel-heading a:after {
  display: inline-flex;
  content: "";
  background-image: url("../../../assets/icons/arrow-down.svg");
  width: 12px;
  height: 8px;
  background-repeat: no-repeat;
  margin-left: 5px;
}
.product-info.panel-group .panel.panel-default .panel-heading a.collapsed:after {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.product-info.panel-group .panel.panel-default .panel-heading a:hover, .product-info.panel-group .panel.panel-default .panel-heading a:focus {
  text-decoration: none;
}
.product-info.panel-group .panel.panel-default .panel-heading + .panel-collapse > .panel-body {
  border: none;
  padding-top: 0;
}
.product-info.panel-group .panel.panel-default .panel-heading + .panel-collapse > .panel-body p {
  margin-bottom: 0;
}
.product-info.panel-group .panel.panel-default .panel-collapse .panel-body {
  padding: 0 0 5px 0;
}
.product-info.panel-group .panel.panel-default .panel-collapse .panel-body * {
  font-family: var(--font-default), sans-serif;
  font-size: 14px;
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
  color: var(--black);
}
@media (max-width: 767px) {
  .product-info.panel-group .panel.panel-default .panel-collapse .panel-body * {
    font-size: 14px;
  }
}
.product-info.panel-group .panel.panel-default .panel-collapse .panel-body b {
  font-weight: bold;
}

.extra--links li a {
  display: inline-block;
  position: relative;
  font-family: var(--font-default), sans-serif;
  font-size: 16px;
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
@media (max-width: 767px) {
  .extra--links li a {
    font-size: 14px;
  }
}
.extra--links li a:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.extra--links li a:hover, .extra--links li a:focus {
  color: var(--black);
}

.product-info .table {
  margin-bottom: 0;
}

.product-info .table > thead > tr > td,
.product-info .table > tbody > tr > td {
  padding-left: 0;
}

.product__bottom--title {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 2.1354166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
  text-align: right;
  padding-right: 9.4791666667vw;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .product__bottom--title {
    padding-right: 0;
    text-align: center;
    margin-bottom: 35px;
  }
}
@media (max-width: 580px) {
  .product__bottom--title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
.product__bottom--title.related {
  padding-right: 6.5104166667vw;
}
@media (max-width: 767px) {
  .product__bottom--title.related {
    padding-right: 0;
  }
}
.product__bottom--section {
  padding-top: max(35px, 6.7708333333vw);
  padding-bottom: max(35px, 6.7708333333vw);
  display: flex;
  width: 100%;
  max-width: 1740px;
}
@media (max-width: 767px) {
  .product__bottom--section {
    flex-direction: column;
  }
}
.product__bottom--section--left {
  width: 100%;
  max-width: max(350px, 58.8541666667vw);
}
@media (max-width: 767px) {
  .product__bottom--section--left {
    max-width: 100%;
  }
}
.product__bottom--section--left .related-products,
.product__bottom--section--left .combined-products {
  position: relative;
  margin: 0 50px;
}
@media (max-width: 767px) {
  .product__bottom--section--left .related-products,
.product__bottom--section--left .combined-products {
    padding: 0;
    margin: 0 30px;
  }
}
@media (max-width: 420px) {
  .product__bottom--section--left .related-products,
.product__bottom--section--left .combined-products {
    margin: 0 15px;
  }
}
.product__bottom--section--left .related-products .swiper-slide,
.product__bottom--section--left .combined-products .swiper-slide {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
@media (hover: hover) {
  .product__bottom--section--left .related-products .swiper-slide:hover,
.product__bottom--section--left .combined-products .swiper-slide:hover {
    scale: 0.8;
  }
}
.product__bottom--section--left .combined-products {
  margin-bottom: max(45px, 2.8645833333vw);
}
.product__bottom--section--right {
  width: 100%;
  max-width: 480px;
}
.product__bottom--section--right .product-summary {
  position: sticky;
  top: var(--header-height);
  padding-top: 80px;
  left: 0;
  margin-left: max(30px, 4.6875vw);
}
.product__bottom--section--right .product-summary .cta--outlined {
  width: 100%;
  max-width: 330px;
  font-size: max(24px, 1.25vw);
  font-weight: 100;
  padding: 8px;
}
@media (max-width: 767px) {
  .product__bottom--section--right {
    display: none;
  }
}

.swiper-button-prev-related,
.swiper-button-next-related,
.swiper-button-prev-combined,
.swiper-button-next-combined {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 9;
}
.swiper-button-prev-related span,
.swiper-button-next-related span,
.swiper-button-prev-combined span,
.swiper-button-next-combined span {
  display: inline-block;
  width: max(15px, 1.4583333333vw);
}

.swiper-button-prev-related,
.swiper-button-prev-combined {
  left: -50px;
}
@media (max-width: 767px) {
  .swiper-button-prev-related,
.swiper-button-prev-combined {
    left: -30px;
  }
}
@media (max-width: 420px) {
  .swiper-button-prev-related,
.swiper-button-prev-combined {
    left: -20px;
  }
}

.swiper-button-next-related,
.swiper-button-next-combined {
  right: -50px;
}
@media (max-width: 767px) {
  .swiper-button-next-related,
.swiper-button-next-combined {
    right: -30px;
  }
}
@media (max-width: 420px) {
  .swiper-button-next-related,
.swiper-button-next-combined {
    right: -20px;
  }
}

/*add to cart message*/
@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}
.size-guide-popup {
  display: none;
  width: 85%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-bottom: 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  max-height: 85vh;
  overflow-y: auto;
}
.size-guide-popup::-webkit-scrollbar {
  width: 6px;
}
.size-guide-popup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
.size-guide-popup::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--gray-light);
  -moz-box-shadow: inset 0 0 6px var(--gray-light);
  box-shadow: inset 0 0 6px var(--gray-light);
  background-color: var(--gray-light);
  border-radius: 4px;
}
.size-guide-popup.active {
  display: block;
}
.size-guide-popup .size-guide-container {
  padding: max(15px, 2.6041666667vw);
  border: none;
}
.size-guide-popup .size-guide-container .close-size-guide-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 99;
}

.product-details .main-info .right {
  display: none;
}
@media (max-width: 767px) {
  .product-details .main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .product-details .main-info {
    max-width: 345px;
  }
}
@media (max-width: 767px) {
  .product-details .main-info .right {
    display: block;
  }
  .product-details .main-info .right .share-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-details .main-info .right .share-section .share-choices {
    padding: 5px 0 0;
  }
  .product-details .main-info .right .wishlist-btn {
    margin-top: 0;
  }
  .product-details .main-info .right .wishlist-btn .desktop-only {
    display: none;
  }
}
.product-details .desktop-only {
  display: flex;
}
@media (max-width: 767px) {
  .product-details .desktop-only {
    display: none;
  }
}

.popup-alert {
  margin: 0 !important;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 50px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: note 6500ms forwards;
}
@media (max-width: 767px) {
  .popup-alert {
    width: 85%;
  }
}
.popup-alert .fa {
  display: none;
}
.popup-alert .alert {
  margin: 0;
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}
.popup-alert.alert-dismissible .close {
  position: relative;
  top: -30px;
  right: 0;
  color: inherit;
}
@media (max-width: 767px) {
  .popup-alert.alert-dismissible .close {
    top: -40px;
  }
}
@media (max-width: 420px) {
  .popup-alert.alert-dismissible .close {
    top: -50px;
  }
}
.popup-alert.alert-dismissible .close .close-btn-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.popup-alert.alert-dismissible .close .close-btn-icon svg {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.hidden-color-select {
  display: none;
}

.back-in-stock__cta {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  margin-top: 10px;
}
.back-in-stock__cta .icon--bell {
  display: inline-block;
  width: 15px;
}
.back-in-stock__cta .icon--bell svg {
  margin-bottom: -3px;
}
.back-in-stock__pop-up {
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: calc(var(--screen-height) - var(--header-height));
  background: rgba(51, 51, 51, 0.65);
  z-index: 97;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@media screen and (max-width: 580px) {
  .back-in-stock__pop-up {
    align-items: flex-start;
  }
}
.back-in-stock__pop-up.js-has-scrolled {
  top: 0;
  height: var(--screen-height);
  z-index: 100;
}
@media screen and (max-width: 580px) {
  .back-in-stock__pop-up.js-has-scrolled {
    top: 50px;
  }
}
.back-in-stock__pop-up.js-pop {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.back-in-stock__pop-up .icon-pop-up--close {
  position: absolute;
  top: 25px;
  right: 30px;
  display: inline-block;
  cursor: pointer;
  width: max(22px, 1.5625vw);
  z-index: 1;
}
@media screen and (max-width: 580px) {
  .back-in-stock__pop-up .icon-pop-up--close {
    top: 40px;
    right: 22px;
  }
}
.back-in-stock__pop-up .icon-pop-up--close svg {
  fill: none;
  stroke: black;
}
.back-in-stock__pop-up--wrap {
  background-color: var(--white);
  padding: max(28px, 2.6041666667vw);
  width: 100%;
  max-width: max(375px, 53.3854166667vw);
}
@media screen and (max-width: 580px) {
  .back-in-stock__pop-up--wrap {
    padding-top: 115px;
    max-width: 100%;
    height: calc(var(--screen-height) - 50px);
  }
}
@media screen and (max-width: 580px) and (max-height: 580px) {
  .back-in-stock__pop-up--wrap {
    overflow-y: auto;
  }
}
.back-in-stock__pop-up--underlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.back-in-stock__pop-up--head, .back-in-stock__pop-up--body {
  max-width: 860px;
}
.back-in-stock__pop-up--head {
  display: flex;
  flex-direction: column;
}
.back-in-stock__pop-up--head span:first-child {
  font-family: var(--font-default), sans-serif;
  font-size: max(31px, 2.6041666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
  margin-bottom: max(15px, 1.8229166667vw);
}
.back-in-stock__pop-up--head span:last-child {
  font-family: var(--font-default), sans-serif;
  font-size: max(12.5px, 1.1979166667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
  margin-bottom: max(30px, 1.8229166667vw);
}
.back-in-stock__pop-up--body form > div:first-child, .back-in-stock__pop-up--body form > div:nth-child(2) {
  margin-bottom: 30px;
}
@media screen and (max-width: 580px) {
  .back-in-stock__pop-up--body form > div:nth-child(2) {
    margin-bottom: 50px;
  }
}
.back-in-stock__pop-up--body form .form--consent label {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1.2;
  display: inline-flex;
  text-transform: unset;
}
.back-in-stock__pop-up--body form .form--consent label .checkbox-input, .back-in-stock__pop-up--body form .form--consent label .checkout input[type=checkbox], .checkout .back-in-stock__pop-up--body form .form--consent label input[type=checkbox], .back-in-stock__pop-up--body form .form--consent label .filters__product .bf-cell input[type=checkbox], .filters__product .bf-cell .back-in-stock__pop-up--body form .form--consent label input[type=checkbox] {
  min-width: 17px;
}
.back-in-stock__pop-up--body .icon--submit-arrow {
  display: inline-block;
  width: max(26px, 2.0833333333vw);
}
.back-in-stock__pop-up--body .icon--submit-arrow svg {
  fill: none;
  stroke: black;
}

.back_in_stock--response {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.back_in_stock--response.response--success {
  color: var(--green-dark);
}
.back_in_stock--response.response--danger {
  color: var(--red);
}
.back_in_stock--response.response--warning {
  color: #8a6d3b;
}

.hidden-color-select {
  display: none;
}

.wishlist-fill-icon svg g path:first-child {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  fill: transparent;
}

.wishlist-fill-icon:hover svg g path:first-child, .wishlist-fill-icon.is-favorite svg g path:first-child {
  fill: unset;
}

.prd-size-price {
  display: none !important;
}

.stock-alert {
  color: #ff0000;
  text-decoration: underline;
  font-size: 13px;
  margin-bottom: 0;
}

.product-info.panel-group .panel.panel-default .panel-collapse .panel-body ul {
  padding-inline-start: 48px !important;
}

.product-info.panel-group .panel.panel-default .panel-collapse .panel-body li {
  list-style-type: disc !important;
}

.info-icon {
  position: absolute;
  inset: 0;
  left: auto;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
@media screen and (max-width: 580px) {
  .info-icon {
    margin-right: 17px;
  }
}
@media (hover: hover) {
  .info-icon {
    display: none;
  }
}
.info-content {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  position: absolute;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 1;
  font-size: max(14px, 0.8333333333vw);
  line-height: 1;
  padding: 15px;
  background-color: #DBDBDB;
  color: var(--black);
  text-align: left;
  pointer-events: none;
  opacity: 0;
}
.info-content.add {
  bottom: calc(100% + 1px);
  margin-bottom: 10px;
}
.info-content.book {
  top: calc(100% + 1px);
  margin-top: 10px;
}
@media screen and (max-width: 580px) {
  .info-content.book {
    top: unset;
    margin-top: unset;
    bottom: calc(200% + 1px);
    margin-bottom: 10px;
  }
}

@media (hover: hover) {
  .has-info:hover .info-content {
    opacity: 1;
  }
}
@media (hover: none) {
  .has-info .info-icon:hover ~ span {
    opacity: 1;
  }
}
.or-text {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  font-weight: 100;
  font-size: max(24px, 1.25vw);
  margin: 2px 0 4px;
}
@media screen and (max-width: 580px) {
  .or-text {
    display: none;
  }
}

.tooltip-parent {
  width: 100%;
  max-width: 330px;
}
@media screen and (max-width: 580px) {
  .tooltip-parent:not(#button-cart) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 8;
    width: 100%;
    max-width: 100%;
    background-color: var(--white);
  }
}
.tooltip-parent > div.book-modal-trigger {
  width: 100%;
  border: 1px solid var(--black);
  text-align: center;
  padding: 2px;
  font-weight: 100;
  font-size: max(24px, 1.25vw);
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.tooltip-parent > div.book-modal-trigger:hover {
  background-color: var(--black);
  color: var(--white);
}
.tooltip-parent > div.book-modal-trigger:before {
  display: none;
}
@media screen and (max-width: 580px) {
  .tooltip-parent > div.book-modal-trigger {
    margin-bottom: 0;
  }
}

.contact-container {
  padding: 0 max(25px, 3.6458333333vw) max(50px, 7.8125vw);
}
.contact-container .contact-content-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container {
    margin-bottom: unset;
  }
}
.contact-container .contact-content-container > div {
  width: 33.333%;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container > div {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-info-container {
    margin-bottom: 20px;
  }
}
.contact-container .contact-content-container .contact-info-container .contact-title {
  font-size: max(20px, 1.40625vw);
  font-weight: 300;
  margin-bottom: max(25px, 2.6041666667vw);
}
.contact-container .contact-content-container .contact-info-container > * {
  padding-left: max(0px, 2.0833333333vw);
  margin-bottom: 15px;
}
.contact-container .contact-content-container .contact-info-container > *:last-child {
  margin-bottom: unset;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-info-container > * {
    padding-left: 0;
  }
}
.contact-container .contact-content-container .contact-info-container > * h3 {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.9375vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-info-container > * h3 {
    font-weight: 500;
  }
}
.contact-container .contact-content-container .contact-info-container > * li {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
}
.contact-container .contact-content-container .contact-info-container > * li a:hover {
  color: unset;
}
@media screen and (max-width: 1280px) {
  .contact-container .contact-content-container .contact-map-container {
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-map-container {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .contact-container .contact-content-container .contact-map-container {
    width: 70%;
  }
}
@media screen and (max-width: 580px) {
  .contact-container .contact-content-container .contact-map-container {
    width: 100%;
  }
}
.contact-container .contact-content-container .contact-map-container .contact-map {
  width: max(323px, 30.3125vw);
  height: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-map-container .contact-map {
    height: max(360px, 24.4791666667vw);
  }
}
@media screen and (max-width: 1280px) {
  .contact-container .contact-content-container .contact-map-container .contact-map {
    width: 100%;
  }
}
.contact-container .contact-content-container .contact-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 30px;
  margin-bottom: -8px;
}
@media screen and (max-width: 1024px) {
  .contact-container .contact-content-container .contact-details-container {
    padding-left: unset;
    margin-bottom: unset;
  }
}
.contact-container .contact-content-container .contact-details-container .contact-details h3 {
  display: inline-block;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  font-size: max(13px, 1.25vw);
  text-transform: uppercase;
  font-weight: 100;
  margin-bottom: max(12px, 1.3020833333vw);
}
.contact-container .contact-content-container .contact-details-container .contact-details p {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.25vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-bottom: 10px;
}
.contact-container .contact-content-container .contact-details-container .contact-details p a:hover {
  color: unset;
}
.contact-container .contact-book-appointment-container {
  text-align: center;
}
.contact-container .contact-book-appointment-container a {
  font-weight: 300;
}
.contact-container .contact-book-appointment-container a:hover {
  color: unset;
}

.listing__search .select2-container--default .select2-selection__rendered {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  min-height: 18px;
}
.listing__search .the-search {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
  max-width: calc(50% + 2.6041666667vw);
}
@media screen and (max-width: 767px) {
  .listing__search .the-search {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }
}
.listing__search .the-search > * {
  display: flex;
  margin-bottom: 15px;
}
.listing__search .the-search--criteria > * {
  min-width: calc(50% - max(15px, 1.5625vw));
}
.listing__search .the-search--criteria > *:not(:last-child) {
  margin-right: max(15px, 1.5625vw);
}
.listing__search .the-search--checkboxes {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .listing__search .the-search--checkboxes {
    flex-direction: column;
    align-items: flex-start;
  }
}
.listing__search .the-search--checkboxes label {
  display: inline-flex;
  align-items: center;
}
.listing__search .the-search--checkboxes label:not(:last-child) {
  margin-right: 30px;
}
.listing__search .the-search--cta {
  min-width: 50%;
  max-width: 50%;
}
@media screen and (max-width: 767px) {
  .listing__search .the-search--cta {
    min-width: 100%;
    max-width: 100%;
  }
}
.listing__search .view__selections .selection-wrap .view__selection {
  cursor: pointer;
  margin-left: 5px;
}
.listing__search .view__selections .selection-wrap .view__selection:hover, .listing__search .view__selections .selection-wrap .view__selection.view--selected {
  text-decoration: underline;
}
@media screen and (min-width: 992px) {
  .listing__search .view__selections .selection-wrap .view__selection:not(:nth-child(4), :nth-child(6)) {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .listing__search .view__selections .selection-wrap .view__selection:not(:nth-child(1), :nth-child(2), :nth-child(4)) {
    display: none;
  }
}
.listing__search .category-listing {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
}
.listing__search .category-listing--empty {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.story-container {
  background-color: #d8d8d8;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: max(50px, 11.71875vw);
  padding-bottom: max(40px, 16.1458333333vw);
}
@media screen and (max-width: 767px) {
  .story-container {
    background-position: center;
  }
}
.story-container .story-text-container {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  z-index: 5;
}
@media (max-width: 991px) {
  .story-container .story-text-container {
    max-width: 59.031vw;
  }
}
@media (max-width: 420px) {
  .story-container .story-text-container {
    max-width: 325px;
    padding: 0 10px;
  }
}
.story-container .story-text-container .story-title {
  font-weight: 100;
  font-size: max(30px, 3.28125vw);
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .story-container .story-text-container .story-title {
    margin-bottom: 40px;
  }
}
.story-container .story-text-container .story-text {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.78125vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
}

.jobs-container {
  background-color: #e7d6c8;
  padding: max(50px, 7.03125vw) max(30px, 9.375vw) max(60px, 5.9895833333vw);
}
.jobs-container .jobs-title {
  text-align: center;
  font-family: var(--font-default), sans-serif;
  font-size: max(22px, 3.28125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(25px, 3.125vw);
}
@media screen and (max-width: 991px) {
  .jobs-container .jobs-title {
    font-weight: 300;
  }
}
.jobs-container .jobs-text-desc {
  text-align: center;
  width: 100%;
  max-width: max(290px, 29.6875vw);
  margin: 0 auto 4.167vw auto;
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 0.78125vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
@media screen and (max-width: 991px) {
  .jobs-container .jobs-text-desc {
    font-size: 13px;
    line-height: 16px;
  }
}
.jobs-container .job {
  width: 100%;
  max-width: 15.625vw;
  padding: 2.6041666667vw 0;
}
@media screen and (max-width: 991px) {
  .jobs-container .job {
    max-width: 40%;
  }
}
@media screen and (max-width: 767px) {
  .jobs-container .job {
    margin-bottom: 20px;
    max-width: 300px;
  }
}
@media screen and (min-width: 992px) {
  .jobs-container .job:nth-child(3n+1), .jobs-container .job:nth-child(3n+2) {
    margin-right: 15.625vw;
  }
  .jobs-container .job:nth-child(3n+1):after, .jobs-container .job:nth-child(3n+2):after {
    content: "";
    display: flex;
    width: 1px;
    height: 100%;
    background-color: var(--gray-light);
    position: absolute;
    right: -7.8125vw;
    top: 0;
    transform: rotate(var(--line-rotation));
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .jobs-container .job:nth-child(3n+1):after, .jobs-container .job:nth-child(3n+2):after {
    transform: rotate(calc(var(--line-rotation) + 10deg));
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .jobs-container .job:nth-child(2n+1):after {
    content: "";
    display: flex;
    width: 1px;
    height: 100%;
    background-color: var(--gray-light);
    position: absolute;
    right: -7.8125vw;
    top: 0;
    transform: rotate(calc(var(--line-rotation) + 10deg));
  }
}
.jobs-container .job:last-child {
  margin-right: unset;
}
.jobs-container .job:last-child:after {
  content: unset;
}
.jobs-container .job--wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 78.125vw;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .jobs-container .job--wrap {
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .jobs-container .job--wrap {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.jobs-container .job--heading {
  margin-bottom: max(10px, 1.3020833333vw);
}
.jobs-container .job--title {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 1.4583333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.jobs-container .job--content {
  overflow-wrap: break-word;
}
@media screen and (max-width: 767px) {
  .jobs-container .job--content {
    text-align: center;
  }
}
.jobs-container .job--description {
  margin-bottom: max(15px, 1.5625vw);
  text-align: justify;
  line-height: 18px;
}
@media screen and (max-width: 767px) {
  .jobs-container .job--description {
    text-align: center;
  }
}
.jobs-container .job--cta {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.1458333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
}
.jobs-container .job-more-container {
  text-align: center;
  margin-top: max(30px, 4.6875vw);
  margin-bottom: max(60px, 5.9895833333vw);
}
.jobs-container .job-more-container .job-more-btn {
  background-color: unset;
  border: none;
}

.single-job-container {
  background-color: #e7d6c8;
  padding: max(55px, 5.9895833333vw) max(45px, 5.2083333333vw) max(35px, 20.0520833333vw);
}
@media screen and (max-width: 767px) {
  .single-job-container {
    padding: 50px 45px 35px;
  }
}
.single-job-container .single-job-desc-title {
  text-align: center;
  font-weight: 400;
  font-size: max(20px, 1.5625vw);
  margin-bottom: max(30px, 10.15625vw);
  position: relative;
}
.single-job-container .single-job-inner-container {
  display: flex;
  justify-content: space-between;
  max-width: 1720px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container {
    flex-direction: column;
  }
}
.single-job-container .single-job-inner-container > div {
  width: 33.3333333333%;
  max-width: 335px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container > div {
    width: 100%;
    max-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-desc-container {
    text-align: center;
  }
}
.single-job-container .single-job-inner-container .single-job-desc-container .single-job-overview {
  font-size: max(13px, 1.5625vw);
  font-weight: 400;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-desc-container .single-job-overview {
    text-align: center;
    margin-bottom: 15px;
  }
}
.single-job-container .single-job-inner-container .single-job-desc-container .single-job-text p {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-align: justify;
}
.single-job-container .single-job-inner-container .single-job-req-container {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container {
    margin-bottom: 20px;
  }
}
.single-job-container .single-job-inner-container .single-job-req-container .single-job-req-title {
  font-size: max(13px, 1.5625vw);
  font-weight: 400;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container .single-job-req-title {
    margin-bottom: 20px;
    text-align: center;
  }
}
.single-job-container .single-job-inner-container .single-job-req-container ul {
  font-size: max(13px, 1.25vw);
  margin-left: 15px;
  font-weight: 300;
}
.single-job-container .single-job-inner-container .single-job-req-container ul:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul {
    margin-left: unset;
    padding-left: 30vw;
  }
}
@media screen and (max-width: 420px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul {
    padding-left: 20vw;
  }
}
@media screen and (max-width: 230px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul {
    padding-left: unset;
  }
}
.single-job-container .single-job-inner-container .single-job-req-container ul li {
  text-align: justify;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul li {
    font-size: 13px;
  }
}
.single-job-container .single-job-inner-container .single-job-req-container ul li span {
  width: 11px;
  height: 11px;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul li span {
    width: 7px;
    height: 7px;
    margin-right: 10px;
  }
}
.single-job-container .single-job-inner-container .single-job-req-container ul li span img {
  max-width: unset;
  width: 11px;
  height: 11px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-req-container ul li span img {
    max-width: unset;
    width: 7px;
    height: 7px;
  }
}
.single-job-container .single-job-inner-container .single-job-contact-title {
  font-size: max(13px, 1.5625vw);
  font-weight: 400;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-contact-title {
    text-align: center;
    margin-bottom: 20px;
  }
}
.single-job-container .single-job-inner-container .single-job-mail {
  font-size: max(13px, 1.25vw);
  overflow-wrap: break-word;
  font-weight: 300;
}
@media screen and (max-width: 767px) {
  .single-job-container .single-job-inner-container .single-job-mail {
    font-size: 13px;
    text-align: center;
  }
}

#banner-easy-returns {
  display: none;
  margin-bottom: 0;
}

.checkout-main-title, #quickcheckoutconfirm .checkout__main-title {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

.customer-types, #quickcheckoutconfirm .checkout__wrap .address__type > * {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}

.invoice-types, #quickcheckoutconfirm .checkout__wrap .address__customer-type--wrap label {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-transform: none;
}

.cart-section-title, #quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.875vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

.cart-product-title, .checkout__cart__content .checkout__cart--name > *:first-child {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 1.25vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}

.checkbox-radio-inputs, .checkout input[type=checkbox] + span, .checkout input[type=checkbox] + label {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 300;
  font-style: italic;
  line-height: 1;
  text-transform: none;
}

.popup-login-title, #quickcheckoutconfirm .popup-login__head__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 1.875vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

#button-payment-method {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.875vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}

.checkout-hidden {
  display: none !important;
}

.popup-login-close, #quickcheckoutconfirm .popup-login__head ._close {
  opacity: 0.2;
}
.popup-login-close:focus, #quickcheckoutconfirm .popup-login__head ._close:focus, .popup-login-close:hover, #quickcheckoutconfirm .popup-login__head ._close:hover {
  cursor: pointer;
  opacity: 0.5;
}

#quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  margin-top: max(20px, 2.0833333333vw);
  margin-bottom: max(20px, 1.3020833333vw);
}
#quickcheckoutconfirm .checkout__title--no-top-space {
  margin-top: unset;
}

#quickcheckoutconfirm {
  color: var(--black);
  max-width: 1640px;
  padding-left: max(10px, 4.1666666667vw);
  padding-right: max(10px, 4.1666666667vw);
  padding-bottom: max(30px, 2.6041666667vw);
  margin: auto;
}
#quickcheckoutconfirm .checkout__main-title {
  width: 100%;
  margin-bottom: max(20px, 2.0833333333vw);
}
@media screen and (max-width: 767px) {
  #quickcheckoutconfirm .checkout__main-title {
    text-align: center;
  }
}
#quickcheckoutconfirm .checkout__wrap {
  display: flex;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .checkout__wrap > * {
  width: 50%;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap > * {
    width: 100%;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side {
  background-color: var(--white);
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--left-side {
    margin-right: 2.34375vw;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--left-side {
    background-color: var(--white);
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap {
    padding-bottom: 60px;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap .payment__wrap {
  margin-top: 15px;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    margin-left: 2.34375vw;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side__content--wrap {
    padding-bottom: 60px;
    max-width: 600px;
    margin-left: auto;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type {
  display: flex;
}
@media screen and (max-width: 767px) {
  #quickcheckoutconfirm .checkout__wrap .address__type {
    text-align: center;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type > * {
  background-color: var(--white);
  cursor: pointer;
  flex: 1;
  padding: 0;
  margin-right: 15px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type > * {
    margin-bottom: 15px;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type--active {
  background-color: var(--white);
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type--active {
    background-color: var(--white);
  }
}
#quickcheckoutconfirm .checkout__wrap .address__customer-type--wrap {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .checkout__wrap .terms__wrap {
  background-color: red;
}
#quickcheckoutconfirm label a {
  font-weight: bold;
  text-decoration: underline;
}
#quickcheckoutconfirm .option__wrap:not(:last-child) {
  margin-bottom: 10px;
}
#quickcheckoutconfirm .option__wrap__heading {
  display: none;
}
#quickcheckoutconfirm [type=button] {
  width: 100%;
  background-color: var(--black);
  padding-top: max(10px, 1.0416666667vw);
  padding-bottom: max(10px, 1.0416666667vw);
}
#quickcheckoutconfirm [type=button]:hover {
  color: var(--black) !important;
  background-color: transparent !important;
  border-color: var(--black) !important;
}
#quickcheckoutconfirm .radio__wrap,
#quickcheckoutconfirm .checkbox__wrap {
  display: flex;
  align-items: center;
}
#quickcheckoutconfirm .radio__wrap [type=radio],
#quickcheckoutconfirm .radio__wrap [type=checkbox],
#quickcheckoutconfirm .checkbox__wrap [type=radio],
#quickcheckoutconfirm .checkbox__wrap [type=checkbox] {
  margin-right: 10px;
}
#quickcheckoutconfirm .checkbox__wrap {
  line-height: 1;
  margin-bottom: 10px;
  width: 100%;
}
#quickcheckoutconfirm .checkbox__wrap span {
  cursor: pointer;
}
#quickcheckoutconfirm .checkbox__wrap--show-passwords {
  display: none;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .checkbox__wrap--agree {
    width: 100%;
    margin-bottom: 15px !important;
    justify-content: center;
  }
}
#quickcheckoutconfirm .radio__wrap span,
#quickcheckoutconfirm .radio__wrap input {
  cursor: pointer;
}
#quickcheckoutconfirm .field__wrap {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .field__wrap--input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: calc(50% - max(30px, 1.5625vw) / 2);
}
#quickcheckoutconfirm .field__wrap--input-control {
  width: 100%;
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap--input {
    margin-right: 0 !important;
    width: 100%;
  }
}
#quickcheckoutconfirm .field__wrap--input label {
  display: unset;
}

.checkout__cart {
  padding-top: 15px;
  padding-bottom: 15px;
}
.checkout__cart__head,
.checkout__cart .checkout__cart__content--product {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-dark);
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--white);
}
.checkout__cart__head > *,
.checkout__cart .checkout__cart__content--product > * {
  width: 20%;
}
@media screen and (max-width: 600px) {
  .checkout__cart__head > *,
.checkout__cart .checkout__cart__content--product > * {
    width: 18%;
    font-size: 1.1rem;
  }
}
.checkout__cart__head .checkout__cart--image,
.checkout__cart .checkout__cart__content--product .checkout__cart--image {
  width: 12%;
  min-width: max(60px, 4.1666666667vw);
}
.checkout__cart__head .checkout__cart--name,
.checkout__cart .checkout__cart__content--product .checkout__cart--name {
  width: 40%;
  margin-right: auto;
}
@media screen and (max-width: 767px) {
  .checkout__cart__head .checkout__cart--name,
.checkout__cart .checkout__cart__content--product .checkout__cart--name {
    word-break: break-word;
  }
}
.checkout__cart__head .checkout__cart--name small,
.checkout__cart .checkout__cart__content--product .checkout__cart--name small {
  font-size: 85% !important;
}
.checkout__cart__head .checkout__cart--quantity,
.checkout__cart .checkout__cart__content--product .checkout__cart--quantity {
  text-align: center;
}
.checkout__cart__head .checkout__cart--price,
.checkout__cart .checkout__cart__content--product .checkout__cart--price {
  display: none;
}
.checkout__cart__head .checkout__cart--total,
.checkout__cart .checkout__cart__content--product .checkout__cart--total {
  text-align: right;
}
.checkout__cart__head {
  padding-top: 8px;
  padding-bottom: 18px;
}
.checkout__cart__head .checkout__cart--image {
  opacity: 0;
}
.checkout__cart__content .checkout__cart--name {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--black);
}
.checkout__cart__content .checkout__cart--name--giftwrap {
  margin-top: 10px;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap {
  font-size: 1.2rem;
  margin-bottom: unset;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap span {
  margin-bottom: 3px;
}
.checkout__cart__output--totals {
  padding-top: max(10px, 1.5625vw);
  padding-bottom: max(10px, 1.5625vw);
  display: flex;
  justify-content: space-between;
}
.checkout__cart__output--totals span {
  font-size: 1.4rem;
}
.checkout__cart__output--totals span:first-child {
  font-weight: 500;
}
.checkout__cart__output--totals:first-child {
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child {
  border-top: 1px solid var(--gray-dark);
  border-bottom: 1px solid var(--gray-dark);
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child span {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.checkout__cart--image img {
  mix-blend-mode: multiply;
  border: none;
  width: 100%;
  max-width: 80px;
}
@media screen and (max-width: 600px) {
  .checkout__cart--name .checkbox__wrap {
    font-size: 1.1rem;
  }
}
.checkout__cart--quantity__wrap {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 580px) {
  .checkout__cart--quantity__wrap {
    width: 0;
  }
}
.checkout__cart--quantity__control {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 992px) and (max-width: 1280px) {
  .checkout__cart--quantity__control {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 992px) and (max-width: 1166px) {
  .checkout__cart--quantity__control {
    transform: scale(0.7);
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .checkout__cart--quantity__control {
    transform: scale(0.5);
  }
}
@media screen and (max-width: 580px) {
  .checkout__cart--quantity__control {
    transform: scale(0.7);
  }
}
.checkout__cart--quantity__control > * {
  padding: 12px !important;
  display: flex;
  align-items: center;
  height: 33px;
  border: 1px solid;
}
.checkout__cart--quantity__control input {
  border-left: none !important;
  border-right: none !important;
  border-radius: unset;
  text-align: center;
  min-width: 54px;
}
.checkout__cart--quantity__control button {
  background-color: transparent;
}
.checkout__cart--quantity__update, .checkout__cart--quantity__remove {
  background-color: unset;
  padding: 0;
  margin: 5px;
}
.checkout__cart--quantity__update {
  margin-left: 15px;
}
.checkout__cart--quantity__remove {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: italic;
  line-height: 1;
  position: absolute;
  bottom: -25px;
  text-decoration: underline;
}
.checkout__cart--quantity__remove.giftcard-remove-control {
  bottom: -7px;
}
.checkout__cart--quantity__remove svg {
  display: none;
}
.checkout__cart--textarea {
  margin-top: clamp(30px, 3.125vw, 60px);
  margin-bottom: clamp(30px, 3.125vw, 60px);
}
.checkout__cart--textarea textarea {
  display: flex;
  width: 100%;
  min-height: 140px;
  resize: vertical;
  border-radius: 45px;
  padding: 20px;
}

#quickcheckoutconfirm .cart {
  padding-top: 20px;
}
#quickcheckoutconfirm .text-danger {
  font-size: 1.2rem;
}

#quickcheckoutconfirm .redeem__wrap {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
#quickcheckoutconfirm .redeem--item {
  display: flex;
  flex-direction: column;
}
#quickcheckoutconfirm .redeem--item:not(:last-child) {
  margin-bottom: 25px;
}
#quickcheckoutconfirm .redeem--heading {
  display: none;
}
#quickcheckoutconfirm .redeem--content {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .redeem--content input {
  flex: 1;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content input {
    padding: 20px 15px;
  }
}
#quickcheckoutconfirm .redeem--content button {
  position: absolute;
  height: 100%;
  right: 0;
  background: transparent;
  border: none;
  width: 60px;
  outline: unset;
}
#quickcheckoutconfirm .redeem--content button > i {
  display: block;
}

#quickcheckoutconfirm .popup-login {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
#quickcheckoutconfirm .popup-login > span {
  content: "";
  background-color: rgba(34, 34, 34, 0.75);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
#quickcheckoutconfirm .popup-login.open {
  display: flex;
}
#quickcheckoutconfirm .popup-login__wrap {
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  margin: 20px;
}
#quickcheckoutconfirm .popup-login__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: max(15px, 2.0833333333vw);
  border-bottom: 1px solid #e5e5e5;
}
#quickcheckoutconfirm .popup-login .login-fields {
  width: 100%;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap > *:not(:last-child) {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap a {
  text-decoration: underline;
  text-align: right;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap button {
  border-radius: 100px;
  padding-top: max(10px, 1.0416666667vw);
  padding-bottom: max(10px, 1.0416666667vw);
}
#quickcheckoutconfirm .popup-login .login-fields--wrap button:hover {
  color: var(--black) !important;
  background-color: transparent !important;
  border-color: var(--black) !important;
}

#quickcheckoutconfirm .checkout-header {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 5.2083333333vw;
}
#quickcheckoutconfirm .checkout-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: max(0px, 2.6041666667vw);
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header__wrap {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: max(70px, 7.03125vw);
  }
}
#quickcheckoutconfirm .checkout-header__wrap > * {
  width: 300px;
  color: var(--primary-color);
}
#quickcheckoutconfirm .checkout-header__wrap > * * {
  white-space: nowrap;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header__block {
    order: 2;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--back-link {
    margin-bottom: 5px;
  }
}
#quickcheckoutconfirm .checkout-header--logo {
  display: flex;
  flex: auto;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--logo {
    order: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
  }
}
#quickcheckoutconfirm .checkout-header--logo svg {
  display: flex;
  width: max(135px, 12.5vw);
}
#quickcheckoutconfirm .checkout-header--assist {
  text-align: right;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout-header--assist {
    padding-right: 25px;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--assist {
    display: flex;
    flex-direction: column;
    width: auto;
    text-align: center;
  }
}
#quickcheckoutconfirm .checkout-header ._underline {
  text-decoration: underline;
}
#quickcheckoutconfirm .checkout-header .support-icon {
  position: relative;
  top: 5px;
  margin: 0 5px;
}
#quickcheckoutconfirm #d_social_login {
  width: unset;
  max-width: unset;
}
#quickcheckoutconfirm .alert-danger {
  background-color: unset;
  border-radius: 100px;
  border-color: var(--red);
  color: var(--red);
  text-align: center;
}
#quickcheckoutconfirm #d_social_login {
  background-color: var(--white);
  display: block;
  margin: unset;
  padding-bottom: 25px;
}
#quickcheckoutconfirm #d_social_login .d-social-login__label {
  display: none;
}
#quickcheckoutconfirm #d_social_login .d-social-login__button {
  box-shadow: unset;
  width: 40px;
  height: 40px;
  background-color: unset;
}
#quickcheckoutconfirm #d_social_login .d-social-login__button__icon, #quickcheckoutconfirm #d_social_login .d-social-login__button__label {
  display: none;
}
#quickcheckoutconfirm #d_social_login .d-social-login__button-list {
  display: flex;
  justify-content: center;
}
#quickcheckoutconfirm #d_social_login .d-social-login__button:before {
  content: "";
  display: flex;
  width: 40px;
  height: 40px;
}
#quickcheckoutconfirm #d_social_login .d-social-login__button--facebook:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.5 27.4' style='enable-background:new 0 0 27.5 27.4' xml:space='preserve'%3E%3Cpath d='M15.3 23.9h-3.8v-9.1h-3v-3.9h3V8.4c0-.7.1-1.3.3-1.9.5-1.4 1.5-2.3 3-2.7.4-.1.8-.2 1.3-.2.8 0 1.6 0 2.4.1h.6v3.5h-2c-.3 0-.5 0-.8.1-.5.1-.8.5-.9 1v2.5h3.5l-.5 3.9h-3v1l-.1 8.2zm-3.6-.3H15v-9.1h3.1l.4-3.3H15V8.3c.1-.6.4-1.1 1.1-1.2.3-.1.6-.2.9-.2h1.8v-3h-.3c-.8-.1-1.6-.1-2.4-.1-.4 0-.8.1-1.2.2-1.4.4-2.3 1.2-2.8 2.5-.2.6-.3 1.1-.3 1.8v2.8h-3v3.3h2.9v9.2z'/%3E%3Cpath d='M13.7 27.4C6.1 27.4 0 21.3 0 13.7S6.1 0 13.7 0s13.7 6.2 13.7 13.7-6.1 13.7-13.7 13.7zm0-27.2C6.3.2.2 6.2.2 13.7s6 13.5 13.5 13.5 13.5-6 13.5-13.5S21.1.2 13.7.2z'/%3E%3C/svg%3E");
}
#quickcheckoutconfirm #d_social_login .d-social-login__button--google:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.8 27.8' style='enable-background:new 0 0 27.8 27.8' xml:space='preserve'%3E%3Cpath d='M13.9.1C6.3.1.1 6.3.1 13.9s6.2 13.8 13.8 13.8 13.8-6.2 13.8-13.8S21.5.1 13.9.1zm3.4 13.8c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3S7.5 7.6 11 7.6c1.4 0 2.7.4 3.8 1.3l-1.5 1.9c-.7-.5-1.5-.8-2.4-.8C8.8 10 7 11.7 7 13.9s1.7 3.9 3.9 3.9c1.7 0 3.2-1.1 3.7-2.7h-3.7v-2.4h6.3l.1 1.2zm6.6.8h-1.8v1.8h-1.6v-1.8h-1.8v-1.6h1.8v-1.8h1.6v1.8h1.8v1.6z' style='fill:none;stroke:%23000;stroke-width:.25;stroke-miterlimit:10'/%3E%3C/svg%3E");
}
@media screen and (max-width: 767px) {
  #quickcheckoutconfirm .redeem,
#quickcheckoutconfirm .orphan--giftwrap,
#quickcheckoutconfirm .checkout__cart__output {
    padding-left: 35px;
    padding-right: 35px;
  }
}
#quickcheckoutconfirm .js-select2 + .select2-container {
  min-width: 100%;
  max-width: 100%;
}
#quickcheckoutconfirm .js-select2--full-w + .select2-container {
  width: 100% !important;
  min-width: unset;
  max-width: 450px !important;
  margin-bottom: 5px;
}

.checkout input[type=checkbox] + span, .checkout input[type=checkbox] + label {
  display: inline;
  line-height: 1.5;
}
.checkout .bank-transfer-instructions {
  background-color: transparent;
  border-radius: 45px;
  padding: 20px;
}

.underline-empty {
  margin-top: max(20px, 2.0833333333vw);
  margin-bottom: max(30px, 3.3854166667vw);
  width: 80px;
  height: 1px;
  background-color: var(--gray-dark);
}
@media screen and (max-width: 767px) {
  .underline-empty {
    display: none;
  }
}

.orphan--giftwrap {
  display: flex;
  justify-content: flex-end;
  margin-top: max(20px, 2.6041666667vw);
  margin-bottom: max(5px, 1.8229166667vw);
}
@media screen and (max-width: 767px) {
  .orphan--giftwrap {
    justify-content: center;
  }
}
.orphan--giftwrap label span {
  text-transform: none;
}

#warning-messages {
  margin-left: 20px;
  margin-right: 20px;
}
#warning-messages .alert.alert-danger {
  max-width: 600px;
  margin: auto;
  margin-top: max(80px, 5.2083333333vw);
  border-radius: 100px;
  background-color: transparent;
  border-color: var(--red);
  color: var(--red);
  text-align: center;
}

#checkout-safety-net {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
#checkout-safety-net .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
#checkout-safety-net .lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
#checkout-safety-net .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.bridal-container {
  padding: max(35px, 6.7708333333vw) max(0px, 5.46875vw) 0px;
}
@media screen and (max-width: 991px) {
  .bridal-container {
    padding: 15px 30px 0px;
  }
}
@media screen and (max-width: 767px) {
  .bridal-container {
    padding: 15px 0 0 0;
  }
}
.bridal-container .bridal-title {
  font-weight: 100;
  font-size: max(22px, 3.28125vw);
  text-align: center;
  margin-bottom: 1.9791666667vw;
}
.bridal-container .bridal-intro-text {
  width: 100%;
  max-width: max(300px, 43.2291666667vw);
  margin: 0 auto max(15px, 3.90625vw);
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 100;
}
@media screen and (max-width: 580px) {
  .bridal-container .bridal-intro-text {
    font-size: 13px;
    margin: 0 auto;
  }
}
.bridal-container .bridal-video-container {
  margin-bottom: max(15px, 5.2083333333vw);
}
@media screen and (max-width: 580px) {
  .bridal-container .bridal-video-container {
    margin-bottom: 15px;
  }
}
.bridal-container .bridal-video-container video {
  width: 100%;
}
.bridal-container .bridal-photo-container {
  display: flex;
  gap: 3px;
  margin-bottom: max(35px, 4.4270833333vw);
}
.bridal-container .bridal-photo-container.margin-none {
  margin-bottom: 0;
}
.bridal-container .bridal-photo-container img {
  width: calc((100% - 9px) / 4);
}
@media screen and (max-width: 767px) {
  .bridal-container .slider-wrap {
    padding: 0 3px;
    margin-bottom: 35px;
  }
}

.prev-collection-container {
  padding: max(50px, 3.90625vw) max(15px, 5.46875vw) max(35px, 6.25vw);
  background-color: #e7d6c8;
}
@media screen and (max-width: 991px) {
  .prev-collection-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .prev-collection-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.prev-collection-container .prev-collection-video-container {
  margin-bottom: max(15px, 5.2083333333vw);
}
@media screen and (max-width: 580px) {
  .prev-collection-container .prev-collection-video-container {
    margin-bottom: 15px;
  }
}
.prev-collection-container .prev-collection-video-container video {
  width: 100%;
}
.prev-collection-container .prev-collection-last-items-title {
  font-size: max(22px, 3.28125vw);
  text-align: center;
  font-weight: 100;
  margin-bottom: max(15px, 5.2083333333vw);
}
@media screen and (max-width: 767px) {
  .prev-collection-container .prev-collection-last-items-title {
    text-align: left;
  }
}
@media screen and (max-width: 580px) {
  .prev-collection-container .prev-collection-last-items-title {
    margin-bottom: 15px;
  }
}
.prev-collection-container .prev-collection-slider-wrap:not(.margin-none) {
  margin-bottom: 4.844vw;
}
.prev-collection-container .prev-collection-slider-wrap .prev-collection-slider {
  width: 95%;
}
@media screen and (max-width: 767px) {
  .prev-collection-container .prev-collection-slider-wrap .prev-collection-slider {
    width: 100%;
  }
}
.prev-collection-container .prev-collection-slider-wrap .prev-collection-slider .prev-collection-d__item {
  transition: all 1s;
}
.prev-collection-container .prev-collection-slider-wrap .prev-collection-slider .prev-collection-d__item:hover {
  cursor: pointer;
  scale: 0.8;
}
.prev-collection-container .prev-collection-slider-wrap .prev-collection-slider .prev-collection-d__item .card_view--footer-top a:hover {
  color: unset;
}
.prev-collection-container .prev-collection-slider-wrap .slider-handle-prev-collection {
  top: 0;
}

#information-information {
  font-family: var(--font-default), sans-serif !important;
  padding: max(55px, 4.6875vw) max(15px, 13.0208333333vw) 35px max(15px, 5.9895833333vw);
}
@media screen and (max-width: 767px) {
  #information-information {
    padding: 55px 15px 35px;
  }
}
#information-information .legals-container {
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  #information-information .legals-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  #information-information .legals-container .information-menu {
    margin-bottom: 50px;
  }
}
#information-information .legals-container .information-menu h2 {
  display: none;
}
#information-information .legals-container .information-menu .info-menu li {
  margin-bottom: 15px;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}
#information-information .legals-container .information-menu .info-menu li a {
  display: inline-block;
  text-transform: uppercase;
}
#information-information .legals-container .information-menu .info-menu li a.active {
  font-weight: 500;
  border-bottom: 1px solid #909090;
}
@media screen and (max-width: 767px) {
  #information-information .legals-container .information-menu .info-menu li a.active {
    font-weight: 500;
  }
}
#information-information .legals-container .information-menu .info-menu li a:hover, #information-information .legals-container .information-menu .info-menu li a:active, #information-information .legals-container .information-menu .info-menu li a:visited {
  color: unset;
}
#information-information .legals-container .legals-content-container #content {
  width: 100%;
  max-width: 560px;
}
@media screen and (max-width: 991px) {
  #information-information .legals-container .legals-content-container #content {
    max-width: 450px;
    margin: 0 auto;
  }
}
#information-information .legals-container .legals-content-container #content .legals-text-title {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-family: var(--font-default), sans-serif;
  font-size: max(17px, 0.8854166667vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
}
#information-information .legals-container .legals-content-container #content .legals-text {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
}

.single-post-container {
  background-color: #e7d6c8;
  padding: max(15px, 3.125vw) max(0px, 5.5729166667vw) max(35px, 4.6875vw);
}
@media screen and (max-width: 767px) {
  .single-post-container {
    padding: 15px 0 35px;
  }
}
.single-post-container .go-back {
  position: unset;
  display: inline-flex;
  margin-bottom: 7px;
}
@media screen and (max-width: 767px) {
  .single-post-container .go-back {
    margin-left: 3px;
  }
}
.single-post-container .single-post-video-container {
  margin-bottom: max(10px, 5.2083333333vw);
}
.single-post-container .single-post-video-container video {
  width: 100%;
}
.single-post-container .single-post-slider-wrap:not(.margin-none) {
  margin-bottom: max(35px, 4.8958333333vw);
}
@media screen and (max-width: 767px) {
  .single-post-container .single-post-slider-wrap {
    padding: 0 3px;
  }
}
.single-post-container .single-post-slider-container {
  width: 95%;
}
@media screen and (max-width: 767px) {
  .single-post-container .single-post-slider-container {
    width: 100%;
  }
}
.single-post-container .slider-handle-single-post {
  top: 0;
}
@media screen and (max-width: 767px) {
  .single-post-container .slider-handle-single-post {
    display: none;
  }
}
.single-post-container .default-info p {
  margin-bottom: unset;
}

.stocklist-container {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 max(50px, 6.25vw) max(45px, 5.9895833333vw);
}
@media screen and (max-width: 767px) {
  .stocklist-container {
    background-image: unset !important;
    background-color: var(--beige);
  }
}
.stocklist-container .location-place {
  font-family: var(--font-default), sans-serif;
  font-size: max(15px, 1.40625vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 2.34375vw);
}
.stocklist-container .shop {
  margin-bottom: max(20px, 2.0833333333vw);
}
.stocklist-container .shop h3 {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 1.40625vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
}
.stocklist-container .desktop-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media screen and (max-width: 767px) {
  .stocklist-container .desktop-container {
    display: none;
  }
}
.stocklist-container .desktop-container .stocklist-shops-container h2 {
  font-size: 27px;
  font-weight: 400;
  margin-bottom: 40px;
}
.stocklist-container .desktop-container .stocklist-shops-container .greek-locations-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-right: 2%;
}
.stocklist-container .mobile-container {
  min-height: 50vh;
}
@media screen and (min-width: 768px) {
  .stocklist-container .mobile-container {
    display: none;
  }
}
.stocklist-container .mobile-container #select2-stockilist-region-container {
  font-size: 15px;
  padding: 10px;
}
.stocklist-container .mobile-container select + span {
  margin-bottom: 30px;
  width: 100% !important;
}
.stocklist-container .mobile-container .stocklist-shops-container {
  display: none;
}
.stocklist-container .mobile-container .stocklist-shops-container .location {
  max-width: 200px;
  margin: 0 auto;
}

.the-team > * {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
}
.the-team > * .swiper-slide {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  overflow: hidden;
}
.the-team__intro {
  margin-top: max(70px, 5.7291666667vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.the-team__intro--title {
  margin-bottom: max(25px, 2.34375vw);
}
.the-team__intro--content {
  max-width: max(275px, 38.0208333333vw);
  margin-bottom: max(25px, 3.125vw);
}
.the-team__arc {
  margin-bottom: max(40px, 6.25vw);
}
.the-team__arc--slider {
  display: flex;
  align-items: center;
  min-height: 41.71875vw;
}
.the-team__arc--slider .arc {
  display: flex;
  justify-self: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.the-team__arc--slider .arc img {
  width: max(332px, 36.40625vw);
  height: 100%;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .the-team__arc--slider .arc img {
    width: 100%;
    max-width: 90%;
    content: var(--arc-mob);
  }
}
@media screen and (max-width: 991px) {
  .the-team__arc .slider-wrap {
    max-width: 515px;
    margin: auto;
  }
}
.the-team__arc__sliderwrap {
  align-items: center;
  left: -10.9375vw;
}
@media screen and (max-width: 991px) {
  .the-team__arc__sliderwrap {
    left: unset;
  }
}
.the-team__arc .swiper-slide {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
}
@media screen and (max-width: 991px) {
  .the-team__arc .swiper-slide .card_view .card_view--image {
    margin-top: 13.3333333333vw;
  }
}
@media screen and (min-width: 992px) {
  .the-team__arc .swiper-slide .card_view .card_view--image {
    margin-top: 50px;
  }
  .the-team__arc .swiper-slide .card_view .card_view--image > img {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    min-width: 265px;
    max-width: 265px;
    min-height: max(165px, 18.0729166667vw);
    max-height: max(165px, 18.0729166667vw);
    object-fit: cover;
  }
  .the-team__arc .swiper-slide-active .card_view .card_view--image > img {
    min-width: max(257px, 23.2291666667vw);
    max-width: max(257px, 23.2291666667vw);
    min-height: max(337px, 30.3645833333vw);
    max-height: max(337px, 30.3645833333vw);
    object-fit: cover;
  }
}
.the-team__arc .swiper-slide-active {
  opacity: 1;
}
@media screen and (min-width: 992px) {
  .the-team__arc .swiper-slide-active {
    width: 23.2291666667vw !important;
    margin-left: 6.6145833333vw;
    margin-right: 6.6145833333vw !important;
  }
}
.the-team__arc .swiper-slide .card_view a:hover {
  color: inherit;
  text-decoration: none;
}
.the-team__arc .swiper-slide .card_view--active {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.the-team__arc .swiper-slide .card_view--active-description {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 14.0625vw;
  width: 23.2291666667vw;
  margin: auto;
  color: var(--white);
  width: 100%;
  padding-left: 4.8958333333vw;
  padding-right: 4.8958333333vw;
  background-color: rgba(51, 51, 51, 0.65);
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 1280px) {
  .the-team__arc .swiper-slide .card_view--active-description {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .the-team__arc .swiper-slide .card_view--active-description {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 580px) {
  .the-team__arc .swiper-slide .card_view--active-description {
    justify-content: unset;
  }
}
.the-team__arc .swiper-slide .card_view--active-description .text--light--big, .the-team__arc .swiper-slide .card_view--active-description .account-texts, .the-team__arc .swiper-slide .card_view--active-description .account-text {
  margin-bottom: 25px;
}
@media screen and (max-width: 580px) {
  .the-team__arc .swiper-slide .card_view--active-description .text--light--big, .the-team__arc .swiper-slide .card_view--active-description .account-texts, .the-team__arc .swiper-slide .card_view--active-description .account-text {
    padding-top: 30px;
  }
}
.the-team__arc .swiper-slide .card_view--active-options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1.0416666667vw;
  padding-left: 1.8229166667vw;
  padding-right: 1.8229166667vw;
}
.the-team__arc .swiper-slide .card_view--active-options > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
}
.the-team__arc .swiper-slide .card_view--active-options > *:not(:nth-child(2)) {
  opacity: 1;
}
.the-team__arc .swiper-slide .card_view--active-options > *:nth-child(2) {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.the-team__arc .swiper-slide .card_view--active.content-open .card_view--active-description {
  opacity: 1;
  pointer-events: all;
}
.the-team__arc .swiper-slide .card_view--active.content-open .card_view--active-options > *:nth-child(2) {
  opacity: 1;
  pointer-events: all;
}
.the-team__arc .swiper-slide .card_view--active-center {
  text-align: right;
  flex: 2;
  margin-bottom: 10px;
  position: absolute;
  right: 1.8229166667vw;
  bottom: max(25px, 1.8229166667vw);
}
@media screen and (max-width: 400px) {
  .the-team__arc .swiper-slide .card_view--active-center {
    right: 1.8229166667vw;
    left: 1.8229166667vw;
    text-align: center;
    bottom: 40px;
  }
}
.the-team__arc .swiper-slide .card_view--active-right {
  text-align: right;
}
.the-team__arc .swiper-slide .card_view--active-right .details {
  font-size: 13px;
}
.the-team__arc .swiper-slide .card_view--active-right .details .underline--team:before {
  bottom: -1px;
}
.the-team__arc .swiper-slide .card_view--active-available-sizes span:not(:last-child) {
  margin-right: 15px;
}
.the-team__arc .swiper-slide .card_view--active-cta-pre {
  margin-bottom: 10px;
  cursor: pointer;
}
.the-team__arc .swiper-slide .card_view--active-pre-available-sizes span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--white);
  cursor: pointer;
}
.the-team__arc .swiper-slide .card_view--active-pre-available-sizes span:hover, .the-team__arc .swiper-slide .card_view--active-pre-available-sizes span.selected-size {
  background-color: var(--black);
  color: var(--white);
}
.the-team__arc .swiper-slide .card_view--active-cta-add {
  cursor: pointer;
}
.the-team__arc .swiper-slide .card_view--active-cta-add:hover {
  color: var(--white);
}
.the-team__arc .swiper-slide .card_view--active-cta-view {
  cursor: pointer;
  position: absolute;
  bottom: -35px;
  margin: auto;
  align-self: center;
}
.the-team__arc .swiper-slide-active .card_view--footer {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  padding: 0;
}

.blog-container {
  padding: max(50px, 7.8125vw) max(15px, 5.2083333333vw) max(35px, 4.9479166667vw);
  width: 100%;
}
@media screen and (max-width: 991px) {
  .blog-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .blog-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.blog-container .blog {
  margin-bottom: unset;
}
.blog-container .blog .blog_grid_holder {
  margin: unset;
  padding-top: unset;
}
.blog-container a:hover {
  color: unset;
}
.blog-container .blog-title {
  font-size: max(22px, 3.28125vw);
  font-weight: 100;
  text-align: center;
  margin-bottom: max(20px, 3.6458333333vw);
}
.blog-container .blog_item {
  width: 33.333%;
  transition: all 0.5s;
  padding: 0 1px;
  margin-bottom: unset;
}
@media screen and (max-width: 1024px) {
  .blog-container .blog_item {
    width: 50%;
  }
}
@media screen and (max-width: 580px) {
  .blog-container .blog_item {
    width: 100%;
  }
}
.blog-container .blog_item:hover {
  scale: 0.85;
}
@media screen and (max-width: 767px) {
  .blog-container .blog_item:hover {
    scale: unset;
  }
}
.blog-container .blog_item:hover .summary p {
  padding-right: 0;
}
.blog-container .blog_item h2 {
  font-size: max(22px, 3.28125vw);
  font-weight: 100;
  margin-bottom: max(15px, 1.5625vw);
}
.blog-container .blog_item p {
  font-size: 15px;
  padding-right: max(0px, 4.1666666667vw);
}
@media screen and (max-width: 580px) {
  .blog-container .blog_item p {
    font-size: 13px;
    padding-right: 0;
  }
}

.store > * .swiper-slide {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  overflow: hidden;
}
.store__landing {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
}
.store__landing--banner {
  width: 88.8020833333vw;
  height: 50vw;
  margin: auto;
  margin-bottom: max(20px, 2.0833333333vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .store__landing--banner {
    width: 86.9333333333vw;
    height: 49.0666666667vw;
  }
}
.store__landing--banner img,
.store__landing--banner video {
  object-fit: cover;
  width: 88.8020833333vw;
  height: 50vw;
}
@media screen and (max-width: 767px) {
  .store__landing--banner img,
.store__landing--banner video {
    width: 86.9333333333vw;
    height: 49.0666666667vw;
  }
}
.store__intro {
  padding-left: max(15px, 2.6041666667vw);
  padding-right: max(15px, 2.6041666667vw);
  margin-top: max(70px, 5.7291666667vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.store__intro--title {
  margin-bottom: max(25px, 2.34375vw);
}
.store__intro--content {
  max-width: max(275px, 43.2291666667vw);
  margin-bottom: max(25px, 3.125vw);
}
.store__slider-section {
  padding-left: max(50px, 7.2916666667vw);
  padding-right: max(50px, 7.2916666667vw);
  margin-bottom: max(30px, 3.125vw);
}
.store__info {
  padding-left: max(15px, 7.2916666667vw);
  padding-right: max(15px, 7.2916666667vw);
  padding-bottom: max(50px, 3.6458333333vw);
  display: flex;
}
@media screen and (max-width: 767px) {
  .store__info {
    flex-direction: column;
  }
}
.store__info > * {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .store__info > * {
    width: 100%;
  }
}
.store__info > *:first-child {
  align-items: center;
}
.store__info > *:first-child .map-wrap {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .store__info > *:first-child {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .store__info > *:last-child {
    margin-bottom: 47px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 767px) {
  .store__info > *:last-child {
    align-items: center;
  }
}
.store__info > *:last-child > *:not(:first-child) {
  margin-top: max(10px, 1.0416666667vw);
}

.map-wrap #map {
  width: 42.265625vw;
  height: 42.265625vw;
}
@media screen and (max-width: 767px) {
  .map-wrap #map {
    width: calc(100vw - 40px);
    height: 69.6vw;
  }
}

.main-size-guide-container {
  padding: max(65px, 8.8541666667vw) max(35px, 4.9479166667vw) max(35px, 2.34375vw);
}
@media screen and (max-width: 1140px) {
  .main-size-guide-container .size-guide-tabs > * {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

.size-guide-container-bridal {
  min-height: 578px;
  border: 1px solid #c7c7c7;
}
@media screen and (max-width: 1140px) {
  .size-guide-container-bridal {
    border: none;
  }
}
.size-guide__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 3.28125vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-bottom: 30px;
  text-align: center;
}
.size-guide__title.space-up {
  margin-top: max(30px, 3.125vw);
}
@media screen and (max-width: 1140px) {
  .size-guide__title.space-up {
    padding-top: 30px;
    border-top: 1px solid #c7c7c7;
  }
}

.size-guide-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid #c7c7c7;
  padding-top: 40px;
  padding-bottom: 90px;
  position: relative;
}
@media screen and (max-width: 1140px) {
  .size-guide-container {
    border: none;
    padding-top: unset;
    padding-bottom: unset;
  }
}
@media screen and (max-width: 767px) {
  .size-guide-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1140px) {
  .size-guide-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.size-guide-container .size-guide-table-container {
  margin-bottom: max(15px, 4.4270833333vw);
  margin-right: 10px;
}
.size-guide-container .size-guide-table-container h1 {
  font-size: 17px;
  font-weight: 100;
  margin-bottom: 25px;
  text-align: center;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container h1 {
    font-size: 14px;
    font-weight: 400;
  }
}
.size-guide-container .size-guide-table-container p {
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container p {
    font-size: 14px;
  }
}
.size-guide-container .size-guide-table-container .units-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container .units-container {
    gap: 5px;
  }
}
.size-guide-container .size-guide-table-container .units-container div:hover {
  cursor: pointer;
}
.size-guide-container .size-guide-table-container .units-container .active {
  border-bottom: 1px solid #707070;
}
.size-guide-container .size-guide-table-container .tables-container h2 {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 20px;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container .tables-container h2 {
    font-size: 14px;
    margin-bottom: 40px;
  }
}
.size-guide-container .size-guide-table-container .tables-container table {
  border: 1px solid #ccc;
}
.size-guide-container .size-guide-table-container .tables-container table th {
  font-size: 17px;
  font-weight: 100;
  color: #fff;
  background: #333;
  border: 1px solid #ccc;
  width: 75px;
  height: 40px;
  padding-left: 5px;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container .tables-container table th {
    font-size: 14px;
    width: 75px;
    height: 30px;
  }
}
.size-guide-container .size-guide-table-container .tables-container table td {
  font-size: 17px;
  font-weight: 100;
  width: 85px;
  height: 40px;
  padding-left: 5px;
  border-right: 1px solid #ccc;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-table-container .tables-container table td {
    font-size: 14px;
    width: 60px;
    height: 30px;
  }
}
.size-guide-container .size-guide-table-container .tables-container .table-container.in {
  display: none;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-img-container .title-container {
    width: 100%;
    max-width: 300px;
  }
}
.size-guide-container .size-guide-img-container .title-container h2, .size-guide-container .size-guide-img-container .title-container h3 {
  font-size: 17px;
  font-weight: 100;
  margin-bottom: max(12px, 1.8229166667vw);
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-img-container .title-container h2, .size-guide-container .size-guide-img-container .title-container h3 {
    font-size: 12px;
    font-weight: 400;
  }
}
.size-guide-container .size-guide-img-container .title-container h3 {
  text-transform: uppercase;
  margin-bottom: unset;
}
.size-guide-container .size-guide-img-container .img-text-container {
  display: flex;
  padding-top: 3.6458333333vw;
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-img-container .img-text-container {
    flex-direction: column;
    align-items: center;
  }
}
.size-guide-container .size-guide-img-container .img-text-container .img-container {
  max-width: max(180px, 15.625vw);
}
.size-guide-container .size-guide-img-container .img-text-container .text-container {
  padding-left: 2.34375vw;
  font-size: 12px;
  font-weight: 100;
}
@media (max-width: 767px) {
  .size-guide-container .size-guide-img-container .img-text-container .text-container p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1140px) {
  .size-guide-container .size-guide-img-container .img-text-container .text-container {
    padding-left: unset;
  }
}

.book-block.in-page .book-block__introduction,
.book-block.in-page form {
  width: 100%;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
}

.book-block.in-modal .book-block__introduction,
.book-block.in-modal form {
  width: 100%;
  max-width: 569px;
  margin: auto;
}

.book-block .checkbox-label {
  align-items: center;
}
.book-block .form-element-book {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .book-block .form-element-book {
    margin-bottom: 20px;
  }
}
.book-block .form-element-book .select2 {
  width: 100% !important;
}
.book-block .form-element-book .select2 .select2-selection__arrow {
  height: 100%;
}
.book-block .form-element-book .text-danger {
  padding-left: 10px;
  padding-top: 5px;
}
.book-block form input[type=text] {
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  padding: max(10px, 1.0416666667vw) 20px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  color: var(--gray-dark);
  border-color: var(--gray-dark);
}
.book-block form input[type=text]::placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
  opacity: 1;
}
.book-block form input[type=text]:-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
}
.book-block form input[type=text]::-ms-input-placeholder {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: var(--gray-dark);
}
.book-block form input[type=text]:hover, .book-block form input[type=text]:active {
  color: var(--gray-dark);
  border-color: var(--gray-dark);
}
.book-block form input[type=text]:hover::placeholder, .book-block form input[type=text]:active::placeholder {
  color: var(--gray-dark);
  opacity: 1;
}
.book-block form input[type=text]:hover:-ms-input-placeholder, .book-block form input[type=text]:active:-ms-input-placeholder {
  color: var(--gray-dark);
}
.book-block form input[type=text]:hover::-ms-input-placeholder, .book-block form input[type=text]:active::-ms-input-placeholder {
  color: var(--gray-dark);
}
.book-block form input[type=text]:focus::placeholder {
  color: transparent;
  opacity: 1;
}
.book-block form input[type=text]:focus:-ms-input-placeholder {
  color: transparent;
}
.book-block form input[type=text]:focus::-ms-input-placeholder {
  color: transparent;
}
.book-block form input[type=text].wpcf7-not-valid, .book-block form input[type=text].has-error {
  color: var(--red);
  border-color: var(--red);
}
.book-block form input[type=text].wpcf7-not-valid::placeholder, .book-block form input[type=text].has-error::placeholder {
  color: var(--red);
  opacity: 1;
}
.book-block form input[type=text].wpcf7-not-valid:-ms-input-placeholder, .book-block form input[type=text].has-error:-ms-input-placeholder {
  color: var(--red);
}
.book-block form input[type=text].wpcf7-not-valid::-ms-input-placeholder, .book-block form input[type=text].has-error::-ms-input-placeholder {
  color: var(--red);
}
.book-block form .book-submit {
  display: flex;
  justify-content: center;
}
.book-block form .book-submit button {
  width: 100%;
  max-width: 300px;
  border-radius: 100px;
  font-size: 17px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .book-block form .book-submit button {
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 13px;
  }
}
@media screen and (max-width: 420px) {
  .book-block form .book-submit button {
    max-width: unset;
    width: 100%;
  }
}
.book-block.in-page {
  margin-top: max(50px, 3.3854166667vw);
  margin-bottom: max(165px, 9.375vw);
}
@media screen and (max-width: 991px) {
  .book-block.in-page {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.book-block.in-page .book-block__introduction .title {
  font-weight: 400;
  font-size: 17px;
  margin-bottom: max(0px, 1.4583333333vw);
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .book-block.in-page .book-block__introduction .title {
    font-size: 13px;
    margin-bottom: 0;
  }
}
.book-block.in-page .book-block__introduction .description {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .book-block.in-page .book-block__introduction .description {
    font-size: 12px;
    width: 100%;
  }
}
.book-block.in-page .book-block__introduction .subtitle {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .book-block.in-page .book-block__introduction .subtitle {
    display: none;
  }
}
.book-block.in-modal form .book-submit button {
  max-width: unset;
}
.book-block.in-modal .book-block__introduction .title {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: max(19px, 0.9895833333vw);
  text-transform: uppercase;
  margin-bottom: 30px;
}
.book-block.in-modal .book-block__introduction .description {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: normal;
  line-height: max(15px, 0.78125vw);
  margin-bottom: 6px;
  text-align: initial;
}
.book-block.in-modal .book-block__introduction .subtitle {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 100;
  font-style: normal;
  line-height: max(15px, 0.78125vw);
  margin-bottom: 28px;
}

.account-voucher-container {
  padding: max(35px, 4.1666666667vw) max(15px, 4.6875vw) max(35px, 7.03125vw);
}
@media (max-width: 991px) {
  .account-voucher-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .account-voucher-container {
    padding: 0 15px 55px;
  }
}
.account-voucher-container .breadcrumbs-wrap {
  padding: 0;
}
.account-voucher-container .account-voucher-wrapper {
  display: flex;
  max-width: 1380px;
}
@media (max-width: 991px) {
  .account-voucher-container .account-voucher-wrapper {
    flex-direction: column;
  }
}
.account-voucher-container .account-voucher-col-left {
  width: 100%;
  max-width: 760px;
}
.account-voucher-container .account-voucher-col-left .gift-slider {
  display: flex;
  padding-top: max(5px, 2.6041666667vw);
}
.account-voucher-container .account-voucher-col-left .gift-slider .mz-hint {
  display: none;
}
@media (max-width: 1440px) {
  .account-voucher-container .account-voucher-col-left .gift-slider {
    max-width: 610px;
  }
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-left .gift-slider {
    max-width: 500px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-left .gift-slider {
    max-width: 347px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider a img {
  border-radius: 13px;
}
@media (max-width: 1440px) {
  .account-voucher-container .account-voucher-col-left .gift-slider a img {
    border-radius: 7px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom {
  margin-right: 15px;
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom {
    margin-right: 5px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom {
    max-width: 275px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom img {
  object-fit: cover;
  object-position: center;
  height: 410px;
}
@media (max-width: 1440px) {
  .account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom img {
    height: 328px;
  }
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom img {
    height: 270px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-left .gift-slider #gift-zoom img {
    height: 197px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider {
  display: flex;
  flex-direction: column;
  max-width: 140px;
}
@media (max-width: 1440px) {
  .account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider {
    max-width: 110px;
  }
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider {
    max-width: 94px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider {
    max-width: 67px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider .gift-thumb {
  border: none;
}
.account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider .gift-thumb:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider .gift-thumb:not(:last-child) {
    margin-bottom: 5px;
  }
}
.account-voucher-container .account-voucher-col-left .gift-slider .vertical-slider .gift-thumb:last-child {
  margin-bottom: 0;
}
.account-voucher-container .account-voucher-col-right {
  width: 100%;
  max-width: 600px;
}
@media (max-width: 991px) {
  .account-voucher-container .account-voucher-col-right {
    padding-top: 30px;
  }
}
.account-voucher-container .account-voucher-col-right .gift-card-details {
  width: 100%;
  padding-left: max(30px, 11.4583333333vw);
}
.account-voucher-container .account-voucher-col-right .gift-card-details .product-price-list {
  margin-bottom: 35px;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right {
  display: none;
}
@media (max-width: 991px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (max-width: 991px) and (max-width: 480px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info {
    max-width: 360px;
  }
}
@media (max-width: 991px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right {
    display: block;
  }
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right .share-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right .share-section .share-choices {
    padding: 5px 0 0;
  }
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right .wishlist-btn {
    margin-top: 0;
  }
  .account-voucher-container .account-voucher-col-right .gift-card-details .main-info .right .wishlist-btn .desktop-only {
    display: none;
  }
}
@media (max-width: 1280px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details {
    padding-left: 50px;
  }
}
@media (max-width: 991px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details {
    padding-left: 0;
    max-width: 500px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details {
    max-width: 335px;
  }
}
@media (max-width: 480px) {
  .account-voucher-container .account-voucher-col-right .gift-card-details {
    max-width: 100%;
  }
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift_card_description {
  width: 100%;
  max-width: 190px;
  margin: 25px 0 20px;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift_card_description h4 {
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  margin-bottom: 10px;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift_card_description p {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift_card_description ul {
  padding-left: 15px;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift_card_description ul li {
  list-style-type: circle !important;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form-wrapper {
  max-width: 330px;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element {
  margin-bottom: 5px;
  border: none;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element.voucher_theme {
  display: none;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element .form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid var(--black);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: unset;
  padding: 10px 0 25px;
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 300;
  font-style: normal;
  line-height: 1;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari/Edge */
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--black);
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element .form-control::-ms-input-placeholder { /* Microsoft Edge */
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--black);
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .form-element .form-control:-ms-input-placeholder { /* IE 10+ */
  font-family: var(--font-default), sans-serif;
  font-size: max(16px, 0.8333333333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  color: var(--black);
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .terms {
  margin-top: 15px;
}
.account-voucher-container .account-voucher-col-right .gift-card-details .gift-card-form .cta--outlined {
  width: 100%;
  max-width: 100%;
  font-size: max(24px, 1.25vw);
  font-weight: 300;
  padding: 8px;
  margin-top: 15px;
}

.desktop-only {
  display: flex;
}
@media (max-width: 991px) {
  .desktop-only {
    display: none;
  }
}

.alert {
  margin-top: 25px;
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.not-found-container {
  padding: max(75px, 7.8125vw) max(30px, 19.53125vw);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .not-found-container {
    padding: 75px 15px;
  }
}
.not-found-container h1 {
  font-size: 3.6458333333vw;
  font-weight: 400;
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 767px) {
  .not-found-container h1 {
    font-size: 30px;
    margin-bottom: 40px;
  }
}
.not-found-container h2 {
  font-size: 32px;
  font-weight: 100;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .not-found-container h2 {
    font-size: 26px;
    margin-bottom: 35px;
  }
}
.not-found-container a {
  border-radius: 50px;
  color: #fff;
}

.kTabs {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media screen and (min-width: 992px) {
  .kTabs {
    padding: max(15px, 3.6458333333vw) max(15px, 5.9895833333vw);
  }
}
@media screen and (max-width: 991px) {
  .kTabs {
    width: max(300px, 37.8405650858vw);
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.kTabs__decor {
  position: sticky;
  top: var(--header-height);
  z-index: 1;
  background-color: white;
}
@media screen and (min-width: 992px) {
  .kTabs__wrap {
    display: flex;
    align-items: flex-start;
  }
}
.kTabs__placeholder {
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}
@media screen and (min-width: 992px) {
  .kTabs__placeholder {
    display: none;
  }
}
.kTabs__placeholder:after {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10.8 6.6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:none;stroke:%23999;stroke-width:1.0006;stroke-linejoin:bevel;stroke-miterlimit:10' d='m10.4.4-5 5.9-5-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 12px;
  height: 7px;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(50%);
}
.kTabs__placeholder.kTabs__dropdown:after {
  transform: translateY(50%) rotate(180deg);
}
.kTabs__trigger--wrap {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-family: var(--font-default), sans-serif;
  font-size: max(12px, 0.625vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  display: flex;
  flex-direction: column;
  min-width: 275px;
}
@media screen and (max-width: 991px) {
  .kTabs__trigger--wrap {
    border-top: 1px solid;
    border-color: black;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    background-color: white;
    z-index: 1;
    overflow: hidden;
    max-height: 0%;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 2;
  }
  .kTabs__trigger--wrap.kTabs__dropdown {
    bottom: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    max-height: 100vh;
  }
}
.kTabs__trigger--item {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-right: auto;
}
.kTabs__trigger--item:last-child {
  display: none;
}
.kTabs__trigger--item:not(:last-child) {
  margin-bottom: 15px;
}
.kTabs__trigger--item:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 0%;
  height: 1px;
  background-color: black;
  opacity: 0;
}
.kTabs__trigger--item:hover, .kTabs__trigger--item--current {
  color: black;
}
.kTabs__trigger--item:hover:before, .kTabs__trigger--item--current:before {
  opacity: 1;
  width: 100%;
}
.kTabs__content--wrap {
  padding: max(15px, 3.6458333333vw) max(15px, 5.9895833333vw);
  flex: auto;
}
@media screen and (min-width: 992px) {
  .kTabs__content--wrap {
    margin-left: 2.6041666667vw;
  }
}
.kTabs__content--item {
  width: 100%;
  text-align: justify;
  min-height: 30vh;
}
@media screen and (min-width: 992px) {
  .kTabs__content--item {
    text-align: unset;
    max-width: 560px;
  }
}

.legal-info h1 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info h2 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info h3 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info h4 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info h5 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info h6 {
  font-family: var(--font-default), sans-serif;
  font-size: max(20px, 1.1458333333vw);
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(20px, 1.3020833333vw);
}
.legal-info > *:not(:first-child) h1 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info > *:not(:first-child) h2 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info > *:not(:first-child) h3 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info > *:not(:first-child) h4 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info > *:not(:first-child) h5 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info > *:not(:first-child) h6 {
  margin-top: max(35px, 2.0833333333vw);
}
.legal-info p {
  text-align: justify;
}
.legal-info p:not(:last-child) {
  margin-bottom: max(25px, 1.5625vw);
}
.legal-info p:last-child {
  margin-bottom: unset;
}
.legal-info ol,
.legal-info ul {
  margin-bottom: max(25px, 1.5625vw);
}
.legal-info ol li,
.legal-info ul li {
  margin-left: 22px;
}
.legal-info ol li.depth-control,
.legal-info ul li.depth-control {
  list-style-type: none;
  margin-top: max(15px, 1.0416666667vw);
}
.legal-info ul li {
  list-style-type: initial;
}
.legal-info a {
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}
.legal-info > *:last-child {
  margin-bottom: unset;
}

ul.type--disc li {
  margin-left: 22px;
  list-style-type: initial;
}

#privacy-policy-terms-conditions {
  display: none;
}

.modal-dialog a {
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}
.modal-dialog p {
  text-align: justify;
}

.offwhite-collections__wrap {
  padding: max(50px, 3.90625vw) max(15px, 5.46875vw) max(35px, 6.25vw);
}
@media screen and (max-width: 991px) {
  .offwhite-collections__wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .offwhite-collections__wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.offwhite-collections__hero {
  margin-bottom: max(15px, 5.2083333333vw);
  height: max(140px, 32.65625vw);
}
@media screen and (max-width: 580px) {
  .offwhite-collections__hero {
    margin-bottom: 15px;
  }
}
.offwhite-collections__hero video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.offwhite-collections__hero .image {
  width: 100%;
  height: 100%;
}
.offwhite-collections__hero .image img {
  height: 100%;
  object-fit: cover;
}
.offwhite-collections__hero .text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  font-family: var(--font-default), sans-serif;
  font-size: max(30px, 17.7083333333vw);
  font-weight: 300;
}
.offwhite-collections--slider__navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.offwhite-collections--slider__navigation > div {
  width: 23px;
}
.offwhite-collections--slider-wrap {
  width: 95%;
  margin: 0 auto 4.844vw;
}
@media screen and (max-width: 767px) {
  .offwhite-collections--slider-wrap {
    width: 100%;
  }
}
.offwhite-collections__visit {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit {
    flex-direction: column;
  }
}
.offwhite-collections__visit .map {
  flex: 1;
  width: max(300px, 46.6666666667vw);
  height: max(350px, 46.6666666667vw);
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .map {
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
}
.offwhite-collections__visit .details {
  flex: 1;
  margin-left: max(0px, 3.1770833333vw);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details {
    margin-left: 0;
    width: 75%;
  }
}
@media screen and (max-width: 580px) {
  .offwhite-collections__visit .details {
    width: 100%;
  }
}
.offwhite-collections__visit .details__helper {
  max-width: 20.3125vw;
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details__helper {
    max-width: 350px;
  }
}
.offwhite-collections__visit .details__title {
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details__title {
    max-width: 500px;
    margin: 0 0 30px;
  }
}
@media screen and (max-width: 580px) {
  .offwhite-collections__visit .details__title {
    max-width: 240px;
  }
}
.offwhite-collections__visit .details__subtitle {
  display: inline-flex;
  font-size: max(16px, 1.5104166667vw);
  border-bottom: 1px solid var(--black);
  margin-bottom: max(23px, 1.1979166667vw);
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details__subtitle {
    line-height: 1.3;
  }
}
@media screen and (max-width: 580px) {
  .offwhite-collections__visit .details__subtitle {
    max-width: 220px;
  }
}
.offwhite-collections__visit .details__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.offwhite-collections__visit .details__text span, .offwhite-collections__visit .details__text a {
  font-size: max(14px, 1.25vw);
  display: inline-flex;
  margin-bottom: 7.5px;
}
.offwhite-collections__visit .details__text .book-modal-trigger {
  display: inline-flex;
  margin-top: max(15px, 1.9791666667vw);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details__text .book-modal-trigger {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}
@media screen and (max-width: 767px) {
  .offwhite-collections__visit .details__text .book-modal-trigger:before {
    display: none;
  }
}

/*========================================================*/
/*** MENU ***/
/*========================================================*/
@media (max-width: 1200px) {
  #mega_menu {
    position: static;
  }
  #mega_menu .responsive.megamenu-style-dev {
    position: static;
  }
  #mega_menu .responsive.megamenu-style-dev > .navbar-default {
    position: static;
  }
  #mega_menu .responsive.megamenu-style-dev > .navbar-default > .container-megamenu.horizontal {
    position: static;
  }
  #mega_menu .responsive.megamenu-style-dev > .navbar-default > .container-megamenu.horizontal .navbar-header {
    position: absolute;
    top: 50%;
    left: 30px;
    z-index: 999;
    transform: translate(0, -50%);
  }
}
@media (max-width: 1200px) and (max-width: 767px) {
  #mega_menu .responsive.megamenu-style-dev > .navbar-default > .container-megamenu.horizontal .navbar-header {
    left: 15px;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
    padding: 0 30px;
    height: calc(var(--screen-height) - var(--header--upper));
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu {
    padding: 0 15px;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu .subcategory .main-menu, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu .subcategory .title-submenu {
  color: var(--gray-light) !important;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu .subcategory .main-menu:hover, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu .subcategory .title-submenu:hover {
  color: var(--white) !important;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu li a {
  color: var(--white) !important;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu li a:hover {
  color: var(--white) !important;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu li a strong:after {
  background-color: var(--white);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.white-menu li .sub-menu .content .menu-banner-image .title-submenu {
  color: var(--white);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li {
    position: relative;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.active-show.click {
    position: fixed;
    top: var(--header-upper);
    left: 0;
    z-index: 9;
    height: calc(var(--screen-height) - var(--header--height));
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.active-show.click a.clearfix {
    padding: 15px 30px;
  }
}
@media (max-width: 1200px) and (max-width: 767px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.active-show.click a.clearfix {
    padding: 15px !important;
  }
}
@media (max-width: 767px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.active-show.click {
    height: calc(var(--screen-height) - var(--header--upper));
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li:hover, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a:hover, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.active > a, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li:hover > a {
  background-color: transparent;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu {
  background-color: var(--beige);
  left: 0;
  padding-top: 0;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
  background-color: unset;
  padding: 10px 100px 20px 50px !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
    margin: 15px 30px !important;
    flex-direction: column;
    height: calc(var(--screen-height) - var(--header-height) - 80px);
    overflow-y: auto;
    padding: 0 !important;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content::-webkit-scrollbar {
    width: 6px;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    -moz-box-shadow: inset 0 0 6px transparent;
    box-shadow: inset 0 0 6px transparent;
    border-radius: 4px;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px var(--gray-light);
    -moz-box-shadow: inset 0 0 6px var(--gray-light);
    box-shadow: inset 0 0 6px var(--gray-light);
    background-color: var(--gray-light);
    border-radius: 4px;
  }
}
@media (max-width: 767px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
    margin: 15px !important;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .flex-row {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .flex-row {
    flex-direction: column;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .submenu-col {
  flex: 1;
  max-width: 200px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image {
  padding-top: 30px;
  display: flex;
  flex-basis: 25%;
  align-items: flex-end;
  justify-content: flex-end;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image a {
  padding: 0;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image .title-submenu {
  font-family: var(--font-default), sans-serif;
  font-size: max(13px, 0.6770833333vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  max-width: 160px;
  text-align: right;
  margin-right: 10px;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image .title-submenu {
    max-width: 100%;
    text-align: left;
    margin-right: 0;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image video, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-banner-image img {
  max-width: 225px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-category-link {
  display: inline-block;
  color: var(--gray-medium);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-category-link:hover {
  color: var(--black);
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .menu-category-link {
    font-size: 16px;
    padding: 10px 0;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .hover-menu .menu ul {
  line-height: 1;
  padding-left: 25px;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content .hover-menu .menu ul {
    padding-left: 0;
  }
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .row {
    display: none;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory li._open .row {
    display: block;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .title-submenu {
  z-index: 99;
  padding: 7px 0 0;
  color: var(--gray-medium);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .title-submenu:hover {
  color: var(--black);
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .title-submenu {
    font-size: 16px;
    display: inline-block;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 0;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .menu .main-menu {
  width: unset;
  padding: 3px 0;
  text-transform: unset;
  color: var(--gray-medium);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory .menu .main-menu:hover {
  color: var(--black);
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .subcategory img {
  display: none;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a {
  padding: 20px 0 20px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a {
    font-size: 20px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a.viewAll {
  font-size: 16px;
  padding: 10px 0;
  font-style: italic;
  text-transform: capitalize;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a strong {
  position: relative;
  font-weight: 300;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a strong:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: -7px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a strong:after {
    display: none;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a:after {
  display: none;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a:hover {
  text-decoration: none;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a:hover strong:after {
  width: 100%;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a .caret {
  display: none;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li a .caret {
    display: flex;
    float: right;
    width: 24px;
    height: 14px;
    margin: 0;
    border: none;
    vertical-align: unset;
    content: "" !important;
    background-image: url("../../../icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 15px;
    transition: transform 0.3s ease;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.with-sub-menu.active-show.click .caret {
  display: block;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content {
  padding-bottom: 80px !important;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row {
  flex: 1;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col {
  flex: 1;
  margin-right: 10px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(1) {
  max-width: 200px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(2) {
  max-width: 295px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(3) {
  max-width: 250px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(4) {
  max-width: 180px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(5) {
  max-width: 210px;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col:nth-child(6) {
  max-width: 210px;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width-expanded .sub-menu .content .flex-row .submenu-col {
    flex: unset;
  }
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .menu__sub-category, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .content .menu-category-link, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .subcategory .title-submenu, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .subcategory .menu .main-menu {
    font-size: 16px;
    margin-bottom: 7px;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .content .flex-row {
  width: 100%;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .content .flex-row .submenu-col {
  flex-basis: 50%;
  max-width: unset !important;
}
@media (max-width: 1200px) {
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .content .flex-row .submenu-col {
    margin-bottom: 20px;
  }
  #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.full-width .sub-menu .content .flex-row .submenu-col .title-submenu {
    font-weight: 400;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.off-white .sub-menu .content .flex-row {
  flex: 1;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.off-white .sub-menu .content .flex-row .submenu-col {
  flex: unset;
  flex-basis: 15%;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.off-white .sub-menu .content .flex-row .submenu-col:nth-child(2) .subcategory ul {
  padding-left: unset;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu.slide li.active .sub-menu .container .content {
  border: none;
  padding: 50px 35px 40px 35px !important;
}
#mega_menu .mobile-enabled a,
#mega_menu .mobile-enabled h3 {
  font-family: !important;
}
#mega_menu .mobile-enabled.container {
  padding-bottom: 40px;
}
#mega_menu .main_link h3 {
  border-bottom: none;
  padding: 0;
  font-size: 1.6rem;
  line-height: 3.6rem;
  letter-spacing: 0.025em;
}
#mega_menu #menu {
  margin-bottom: 0;
}
#mega_menu .container-sub-menu {
  width: 100%;
}
#mega_menu i.fa.fonticon {
  display: none;
}

li.with-sub-menu.hover.active a.menutitle::after {
  width: 100%;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a {
  position: relative;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 3px;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

ul.list-unstyled.subcategory li.subtitle2:hover .newhover a::after {
  width: 100%;
}

.sub-menu-banner {
  position: relative;
  transition: linear 0.3s;
}
.sub-menu-banner:hover {
  opacity: 0.9;
  transition: linear 0.3s;
}
.sub-menu-banner img {
  position: relative;
}
.sub-menu-banner a.shop-now-btn {
  position: absolute;
  bottom: 10px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: 0.025em;
}

.megamenu-style-dev.responsive {
  margin-bottom: 0;
}

.megamenu-style-dev .navbar-default {
  background-color: transparent;
  border-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper {
  background-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1201px) {
  .megamenu > li.off-white .menu__sub-category, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu .megamenu > li.off-white .sub-menu .content .menu-category-link, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu .megamenu > li.off-white .sub-menu .subcategory .title-submenu, #mega_menu .container-megamenu .megamenu-wrapper ul.megamenu .megamenu > li.off-white .sub-menu .subcategory .menu .main-menu {
    display: flex;
  }
  .megamenu > li.off-white .sub-menu .flex-row {
    justify-content: space-between;
  }
  .megamenu > li.off-white .sub-menu .flex-row .submenu-col {
    max-width: unset !important;
    flex-basis: unset !important;
  }
  .megamenu > li.off-white .sub-menu .flex-row .submenu-col > *:first-child {
    margin-right: 15px;
  }
}
.megamenu-style-dev .container-megamenu.horizontal ul.megamenu > li > a:before {
  content: none;
}

/*submenu*/
.megamenu-wrapper ul.megamenu > li > .sub-menu {
  left: 0;
  padding-top: 35px;
}

.megamenu-wrapper ul.megamenu .title-submenu:before {
  content: none;
}

.megamenu-wrapper ul.megamenu .title-submenu {
  margin-bottom: 0;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 15px;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu a.main-menu {
  font-size: 1.4rem;
  padding: 0;
  width: auto;
  display: inline-block;
  position: relative;
}

ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 20px;
}

.megamenu-wrapper ul.megamenu .title-submenu {
  display: inline-block;
  width: auto;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.megamenu-wrapper .menu-banner .title-submenu {
  position: relative;
}

.megamenu-wrapper .categories li ul li a:hover,
.megamenu-wrapper .subcategory li ul li a:hover {
  margin-left: 0;
}

.megamenu-wrapper .subcategory .title-submenu:after,
.megamenu-wrapper .categories li ul li a:after,
.megamenu-wrapper .subcategory li ul li a:after,
.megamenu-wrapper .manufacturer li a:after {
  content: "";
  width: 0;
  height: 2px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.megamenu-wrapper .subcategory .title-submenu:hover:after,
.megamenu-wrapper .categories li ul li a:hover:after,
.megamenu-wrapper .subcategory li ul li a:hover:after,
.megamenu-wrapper .manufacturer li a:hover:after {
  width: 100%;
  margin-left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.megamenu-wrapper .menu-banner-link {
  position: relative;
}

.megamenu-wrapper .menu-banner-link .text {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.megamenu-wrapper ul.megamenu .menu-banner .title-submenu {
  text-align: center;
  width: 100%;
}

.megamenu-wrapper .menu-banner a {
  display: inline-block;
}

.megamenu-wrapper .menu-banner-link .text {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
}

.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu .menu ul {
  margin-top: 10px;
}
.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu .menu ul li {
  padding-top: 20px;
}
.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu a.main-menu {
  font-size: 1.4rem;
  padding: 0;
}
.megamenu-wrapper ul.megamenu .title-submenu {
  margin-bottom: 0;
}
.megamenu-wrapper ul.megamenu .title-submenu:before {
  content: none;
}
.megamenu-wrapper .categories li ul li a,
.megamenu-wrapper .subcategory li ul li a {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.megamenu-wrapper .menu-banner-link {
  position: relative;
}
.megamenu-wrapper .menu-banner-link .text {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
}
.megamenu-wrapper .menu-banner .title-submenu {
  text-align: center;
}
.megamenu-wrapper .menu-banner a {
  display: inline-block;
}
.megamenu-wrapper .manufacturer li {
  display: block;
}
.megamenu-wrapper .manufacturer li a {
  border: 0;
  display: inline-block;
  width: auto;
  position: relative;
  padding: 0;
}

@media (max-width: 1200px) {
  .megamenu-style-dev {
    position: fixed;
    z-index: 99;
    left: 15px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle {
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 30px;
    padding: 0;
    margin: 0;
    z-index: 999;
    display: inline-block;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--black);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle span:not(:last-child) {
    margin-bottom: 6px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.white-burger span {
    background-color: var(--white);
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(2) {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 6px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: 10px;
  }
  .megamenu-style-dev .megamenu-wrapper {
    width: 45%;
    position: fixed;
    top: var(--header--upper);
    left: -100%;
    z-index: 9;
    height: calc(var(--screen-height) - var(--header--upper));
    max-height: unset;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
    overflow-y: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .megamenu-style-dev .megamenu-wrapper.so-megamenu-active {
    left: 0;
    opacity: 1;
    visibility: visible;
  }
  /*submenu*/
  /*menu*/
}
@media (max-width: 1200px) and (max-width: 767px) {
  .megamenu-style-dev .megamenu-wrapper.so-megamenu-active {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .megamenu-style-dev .megamenu-wrapper .megamenu {
    flex-direction: column;
  }
  .megamenu-style-dev .megamenu-wrapper .megamenu li a {
    color: #000;
  }
}
@media (max-width: 1200px) {
  .megamenu-wrapper .megamenu .sub-menu .content > .row > div {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .megamenu-wrapper ul.megamenu > li > .sub-menu {
    padding-top: 0;
  }
}
@media (max-width: 1200px) {
  .megamenu-wrapper .sub-menu {
    padding-bottom: 20px;
  }
  .megamenu-wrapper .sub-menu .discount-bar {
    display: none;
  }
  .megamenu-wrapper .sub-menu .content .static-menu .menu ul li {
    padding-top: 5px;
  }
}
@media (max-width: 1200px) {
  .mobile-account-items {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    max-height: 51px;
    height: 100%;
  }
  .mobile-account-items a {
    display: inline-block;
    float: left;
    width: 33.333333%;
    background-color: #eaeaea;
    padding: 15px 15px 10px;
    text-align: center;
    color: #000;
  }
  .mobile-account-items a:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.7);
    border-right: 1px solid rgba(0, 0, 0, 0.7);
  }
  .mobile-account-items a .text {
    vertical-align: top;
  }
}
@media (max-width: 1200px) {
  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
    margin-top: 0;
  }
}
.megamenu-pattern-header {
  display: none;
}
@media (max-width: 1200px) {
  .megamenu-pattern-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--header--logo);
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .megamenu-pattern-header {
    padding-right: 15px;
  }
}
.megamenu-pattern-header .logo--icon {
  display: none;
  width: 55px;
  height: 25px;
}
.megamenu-pattern-header .logo--icon svg .st0 {
  fill: var(--black);
}

.only-mobile {
  display: none !important;
  padding: 0 !important;
}
@media (max-width: 1200px) {
  .only-mobile {
    display: block !important;
  }
  .only-mobile > a {
    text-transform: capitalize !important;
    font-style: italic !important;
  }
}

.offwhite-row,
.sales-row {
  display: flex;
}
@media (max-width: 1200px) {
  .offwhite-row,
.sales-row {
    flex-direction: column;
  }
}
.offwhite-row .subcategory,
.sales-row .subcategory {
  max-width: 200px;
}

@media screen and (min-width: 1201px) {
  .offwhite-row > * {
    flex: 1;
  }
}
#mega_menu .container-megamenu .megamenu-wrapper ul.megamenu li.custom-black-week a strong {
  font-weight: bold;
}

.hero-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.hero-slider .slide-info {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: var(--white);
}
@media (max-width: 580px) {
  .hero-slider .slide-info {
    top: unset;
    transform: unset;
    bottom: 10%;
  }
}
.hero-slider .slide-info .super-hero,
.hero-slider .slide-info .hero-btn {
  font-weight: 600;
  text-transform: uppercase;
}
.hero-slider .slide-info .hero-btn {
  position: relative;
}
.hero-slider .slide-info .hero-btn:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 1px;
  background-color: var(--white);
}
.hero-slider .slide-info .hero-title {
  margin: 25px 0 40px;
}
.hero-slider .swiper-slide img {
  margin: 0 auto;
}
.hero-slider .hero-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  max-width: 22px;
}
@media (max-width: 580px) {
  .hero-slider .hero-arrow {
    max-width: 15px;
  }
}
.hero-slider .hero-arrow.hero-button-prev {
  left: 2%;
}
.hero-slider .hero-arrow.hero-button-next {
  right: 2%;
}

.discount-bar {
  text-align: center;
  padding: 10px 15px;
}

.size-guide-modal {
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
  width: calc(100% - 14.84375vw);
  max-height: 70vh;
  margin: auto;
  margin-top: 200px;
  background-color: #fff;
  color: #000;
  z-index: 9999;
}
@media screen and (max-width: 1200px) {
  .size-guide-modal {
    margin-top: 140px;
  }
}
@media screen and (max-width: 767px) {
  .size-guide-modal {
    width: calc(100% - 20px);
    max-height: unset;
    margin-top: 120px;
    margin-bottom: 10px;
  }
}
.size-guide-modal::-webkit-scrollbar {
  width: 6px;
}
.size-guide-modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  -moz-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 4px;
}
.size-guide-modal::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--gray-light);
  -moz-box-shadow: inset 0 0 6px var(--gray-light);
  box-shadow: inset 0 0 6px var(--gray-light);
  background-color: var(--gray-light);
  border-radius: 4px;
}
.size-guide-modal.active {
  display: block;
}
.size-guide-close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 99;
}
@media screen and (max-width: 1280px) {
  .size-guide-close {
    width: 20px;
    height: 20px;
  }
  .size-guide-close svg {
    width: 20px;
    height: 20px;
  }
}

.size-guide-tabs .nav {
  margin: unset;
  list-style: none;
  padding: max(15px, 2.8645833333vw) max(15px, 4.6875vw) 0;
}
.size-guide-tabs .nav-tabs {
  border: unset;
}
.size-guide-tabs .nav-tabs li:not(:last-child) {
  margin-right: max(15px, 1.5625vw);
}
.size-guide-tabs .nav-tabs li.active {
  text-decoration: underline;
  text-underline-offset: 7px;
}
.size-guide-tabs .nav-tabs li a {
  padding: unset !important;
  border: unset !important;
  color: unset !important;
  background-color: unset !important;
}
.size-guide-tabs .tab-content {
  padding: max(46px, 2.8645833333vw) max(15px, 4.6875vw) max(30px, 2.1875vw);
  min-height: 578px;
}

.size-guide,
.measuring-guide {
  display: flex;
}
@media screen and (max-width: 1280px) {
  .size-guide,
.measuring-guide {
    flex-direction: column;
  }
}

.size-guide-block {
  margin-bottom: max(15px, 4.4270833333vw);
}
@media screen and (max-width: 1280px) {
  .size-guide-block:last-child {
    margin-bottom: unset;
  }
}
.size-guide-block:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 1280px) {
  .size-guide-block:not(:last-child) {
    display: flex;
    flex-direction: column;
    margin-right: unset;
  }
}
.size-guide-block .unit-btn {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.size-guide-block__title {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  margin-bottom: max(32px, 2.1875vw);
}
.size-guide-block__title.space-left {
  margin-left: 4.6875vw;
}
@media screen and (max-width: 1280px) {
  .size-guide-block__title.space-left {
    margin-left: unset;
  }
}
.size-guide-block__wrap .title {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  margin-bottom: 15px;
}
@media screen and (max-width: 1280px) {
  .size-guide-block__wrap .title {
    margin-bottom: 38px;
  }
}
.size-guide-block__wrap table {
  border: 1px solid #000000;
}
@media screen and (max-width: 1280px) {
  .size-guide-block__wrap table {
    width: 100%;
  }
}
.size-guide-block__wrap table th {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  border: 1px solid #000000;
  min-width: max(98px, 5.7291666667vw);
  padding: 7px 5px;
}
.size-guide-block__wrap table td {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  padding: 7px 5px;
  width: 85px;
  border-right: 1px solid #000000;
}
.size-guide-block__wrap .content {
  display: flex;
}
.size-guide-block__wrap .content > .image {
  max-width: max(180px, 11.0416666667vw);
}
.size-guide-block__wrap .content > .details {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1;
  padding-left: 2.34375vw;
}
@media screen and (max-width: 1140px) {
  .size-guide-block__wrap .content > .details {
    padding-left: unset;
  }
}
.size-guide-block__wrap .content > .details > *:not(:last-child) {
  margin-bottom: 13px;
}
.size-guide-block__wrap.right {
  display: flex;
  padding-top: 29px;
}
@media screen and (max-width: 1280px) {
  .size-guide-block__wrap.right {
    justify-content: center;
  }
  .size-guide-block__wrap.right .content {
    flex-direction: column;
    align-items: center;
  }
  .size-guide-block__wrap.right .content .details {
    margin-top: 40px;
  }
}

.measuring-guide-block {
  flex: 1;
}
.measuring-guide-block:first-child {
  margin-right: max(30px, 3.125vw);
}
@media screen and (max-width: 1280px) {
  .measuring-guide-block:first-child {
    margin-top: 15px;
    margin-right: unset;
    order: 2;
  }
}
.measuring-guide-block .details {
  font-family: var(--font-default), sans-serif;
  font-size: max(14px, 0.7291666667vw);
  font-weight: 100;
  font-style: normal;
  line-height: 1.5;
}
.measuring-guide-block .details > *:not(:last-child) {
  margin-bottom: 43px;
}
.measuring-guide-block img,
.measuring-guide-block video {
  width: 37.9166666667vw;
  height: 21.3541666667vw;
  object-fit: cover;
}
@media screen and (max-width: 1280px) {
  .measuring-guide-block img,
.measuring-guide-block video {
    width: 100%;
    height: auto;
  }
}

.js-play-measuring-bridal-video {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.js-play-measuring-bridal-video img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: auto;
  height: auto;
  opacity: 1;
}
.js-play-measuring-bridal-video.video-playing img {
  opacity: 0;
}