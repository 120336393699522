.category {
    &>* {
        padding-left: max(15px, vw(50px));
        padding-right: max(15px, vw(50px));
    }

    &__intro {
        margin-top: max(70px, vw(110px));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--title {
            margin-bottom: max(25px, vw(45px));
        }

        &--content {
            max-width: max(275px, vw(730px));
            margin-bottom: max(25px, vw(60px))
        }
    }

    &-listing {
        display: flex;
        flex-wrap: wrap;
        margin-top: vw(20px);
        padding-left: max(15px, vw(60px));
        padding-right: max(15px, vw(60px));
        @media screen and (max-width: $tablet-sm) {
            padding-left: 12px;
            padding-right: 12px;
        }
        &__item {
            @include transition($time: 1s);
            width: var(--listing-view);
            margin-bottom: 45px;
            padding: 3px;
            opacity:0;

            // @media screen and (min-width: ($tablet + 1)) {
            //     width: 25%;
            // }

            // @media screen and (max-width: $tablet) {
            //     width: 50%;
            // }

            a {
                display: flex;
                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }

            &:hover {
                @media (hover: hover) {
                    scale: .8;
                }
            }

            &.out-of-stock {
                .card_view {
                    &--image {
                        &:after {
                            @extend .title--out-of-stock;
                            content: "OUT OF STOCK";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(#ffffff, .35);
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $tablet-sm) {
            &>* {
                // in case of perpetual loop
                //&:nth-child(18n+2) {
                &:nth-child(2) {
                    margin-left: 50%;
                }
                // in case of perpetual loop
                //&:nth-child(18n+9) {
                &:nth-child(9) {
                    width: 100%;
                }
            }
        }

        &__group {
            display: flex;
            width: 100%;
            align-items: flex-start;

            @media screen and (max-width: $tablet-sm) {
                flex-wrap: wrap;
            }

            &__display {
                position: absolute;
                bottom: 3px;
                right: 3px;
                width: calc((100% / 2) - 6px);
                height: calc((100% / 2) - 6px - 51px);

                @media screen and (max-width: $tablet-sm) {
                    height: calc((100% / 3) - 6px);
                    left: 3px;
                    right: unset;
                    top: calc(100% + 3px);
                    bottom: unset;
                    display: flex;
                    align-items: flex-end;
                    padding-bottom: 72px;
                }

                .underline--black {
                    white-space: nowrap;

                    @media screen and (max-width: $tablet-sm) {
                        font-size: 12px;
                        font-weight: $weight-medium;
                    }
                }

                &__wrap {
                    width: max(150px, vw(311px));
                    margin-left: vw(215px);
                    margin-top: vw(80px);

                    @media screen and (max-width: $tablet) {
                        margin-top: unset;
                    }

                    @media screen and (max-width: $tablet-sm) {
                        margin-left: unset;
                    }

                    .title {
                        @extend .title--big;

                        @media screen and (max-width: $tablet-sm) {
                            font-size: 40px;
                        }
                    }
                }
            }

            & > * {
                &:first-child {
                    width: 50%;

                    @media screen and (max-width: $tablet) {
                        width: 100%;
                    }
                }
            }

            &.reversed {
                align-items: flex-end;

                & > * {
                    &:first-child {
                        width: 25%;

                        @media screen and (max-width: $tablet) {
                            width: 50%;
                        }

                        @media screen and (max-width: $tablet-sm) {
                            margin-right: 50%;
                        }
                    }

                    &:nth-child(3) {
                        width: 50%;

                        @media screen and (min-width: $tablet-sm + 1) and (max-width: $tablet) {
                            width: 100%;
                        }
                    }
                }
            }

            &.reversed & {
                &__display {
                    top: 3px;
                    left: 3px;
                    bottom: unset;
                    right: unset;

                    @media screen and (max-width: $tablet-sm) {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 10px;
                        left: unset;
                        right: 3px;
                        padding-bottom: 110px;
                        bottom: 50%;
                        top: unset;
                    }
                }
            }
        }
    }

    &-bridal {
        &__wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: vw(1796px);
            margin: auto;
        }

        &__introduction {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 50px;
            margin-top: 33px;
            padding-top: 57px;

            &__title {
                @include font($font-default, #{max(30px, vw(60px))}, $weight-thin, normal, #{max(42px, vw(72px))});
                text-transform: uppercase;
                margin-bottom: 36px;
            }

            &__content {
                @include font($font-default, #{max(14px, vw(14px))}, $weight-light, normal, 1);
                color: $gray-medium;
                width: 100%;
                max-width: max(340px, vw(890px));
            }
        }
    }
}

.filters {
    &-wrap {
        padding-left: max(15px, vw(60px));
        padding-right: max(15px, vw(60px));
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $tablet-sm) {
            padding-top: 15px;
            padding-left: 15px;
        }
        &--left {
            display: inline-flex;
            align-items: center;
            .filters--label {
                cursor: pointer;

                @media screen and (max-width: $mobile) {
                    margin-right: 10px;

                    span {

                        &:first-child,
                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }

        &--right {
            .view__selection {
                cursor: pointer;
                margin-left: 5px;

                &:hover,
                &.view--selected {
                    text-decoration: underline;
                }

                @media screen and (min-width: ($tablet + 1)) {
                    &:not(:nth-child(4), :nth-child(6)) {
                        display: none
                    }
                }

                @media screen and (max-width: $tablet) {
                    &:not(:nth-child(1), :nth-child(2), :nth-child(4)) {
                        display: none
                    }
                }
            }
        }
    }

    &__product {

        @include transition;
        position: absolute;
        top: 25px;
        width: max(375px, vw(500px));
        max-width: max(375px, vw(500px));
        z-index: 1;
        box-shadow: 10px 10px 20px -10px $black;
        padding: 10px;
        background-color: $white;
        opacity: 0;
        pointer-events: none;
        margin-left: min(-5px, vw(-10px));
        overflow-y: auto;
        max-height: max(375px, vw(760px));

        @media screen and (max-width: $mobile) {
            position: fixed;
            top: var(--header-height);
            left: 0;
            width: 100%;
            max-width: 100%;
            max-height: calc(var(--screen-height) - var(--header-height));
            margin-left: unset;
        }

        &.open {
            opacity: 1;
            pointer-events: all;
        }

        .list-group,
        .swiper-viewport {
            display: none;
        }

        .box {
            border: none;
            background-color: transparent;
            border-radius: 0;
            margin-bottom: 0;
        }

        .box-heading {
            display: none;
        }

        .ui-slider-range {
            &.ui-widget-header {
                background: $black;
            }
        }

        span.ui-slider-handle {
            background: $white !important;
            color: $black;
            width: auto !important;
            height: 20px !important;
            border: 1px solid $black !important;
            border-radius: 8px !important;
            text-align: center;
            padding: 0 7px;
            font-size: 1.1rem;
            top: -8px;
            display: flex;
            align-items: center;

            &:last-child {
                -webkit-transform: translate(-69%, 0);
                -moz-transform: translate(-69%, 0);
                -o-transform: translate(-69%, 0);
                transform: translate(-69%, 0);
            }
        }

        .bf {
            &-clickable {
                @include font($font-default, #{max(13px, vw(13px))}, normal, normal, 1);
                background-color: transparent !important;
                padding: 7px 0;
            }

            &-attr-header {
                border: unset;
            }

            &-count {
                background-color: $black !important;
            }

            &-buttonclear-box {
                display: none;
                border: 1px solid $black;
                background-color: $black;

                @media (max-width: $tablet-sm) {
                    position: fixed;
                    bottom: 15px;
                    left: 15px;
                    width: calc(100% - 30px);
                    z-index: 9;
                }

                .bf-buttonclear {
                    background: none !important;
                    background-repeat: no-repeat;
                    text-decoration: none;
                    padding-left: 0;
                    line-height: initial;
                    color: $white;
                }

                .bf-buttonclear:hover {
                    background: none !important;
                }
            }

            &-slider-container.ui-slider-horizontal,
            &-slider-range.ui-slider-horizontal {
                height: 3px;
            }

            // &-attr-block {
            //     &.bf-price-filter {
            //         display: none;
            //     }
            // }

            &-price-container.box-content.bf-attr-filter {
                &>.bf-cur-symb {
                    display: none;
                }
            }

            &-attr-block {

                .bf-close-btn,
                .bf-arrow {
                    position: absolute;
                    top: 40%;
                    right: 0;
                    -webkit-transform: translateX(-40%);
                    -moz-transform: translateX(-40%);
                    -o-transform: translateX(-40%);
                    transform: translateX(-40%);
                }
            }

            &-cell {
                border-bottom: none !important;

                &.bf-cascade-1 {
                    background-image: none;
                    padding-left: 0;
                }
                &.bf-cascade-2 {
                    background-image: none!important;
                }

                input[type=checkbox] {
                    @extend .checkbox-input;

                    &:disabled {
                        opacity: .1;
                    }
                }
            }

            &-attr-block-cont {
                padding: 0;
            }

            &-attr-filter {
                display: block;
                margin-bottom: 15px;

                // .bf-cell:first-child {
                //     display: none;
                // }

                .bf-cell:last-child {
                    display: none;
                }
            }

            &-attr-filter {
                .bf-cell label {
                    position: relative;
                    display: inline-block;
                    font-size: 1.1rem;
                    line-height: initial;
                    font-weight: 400;
                    color: #000;
                    text-transform: uppercase;
                    margin-bottom: unset;
                }

                .bf-cell label:after {
                    display: inline-block;
                    content: '';
                    width: 0;
                    background-color: #000000;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }

                .bf-cell label:hover:after {
                    width: 100%;
                    -webkit-transition: width 0.3s ease;
                    -moz-transition: width 0.3s ease;
                    -o-transition: width 0.3s ease;
                    transition: width 0.3s ease;
                }
            }

            &-attr-block {
                .bf-attr-block-cont {
                    padding: 10px 0;
                }
            }

            @media (max-width: $tablet-sm) {

                &-cross,
                &-close-btn,
                &-arrow {
                    background-image: url(../../../image/brainyfilter/sprite.png) !important;
                }

                // &-panel-wrapper {
                //     height: calc(100vh - 50px);
                //     overflow-y: auto;
                //     padding-top: 10px;
                // }
            }
        }

        input[type='reset'] {
            @include font($font-default, #{max(13px, vw(13px))}, normal, normal, 1);
            background-color: transparent;
            color: $white;
            border-color: $black;
            text-align: center;
            width: auto;
            margin: auto;
        }

        input[type='text'] {
            border: 1px solid #000000;
        }

    }

    &--underlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: $mobile) {
            position: absolute;
            left: unset;
            top: 0;
            right: 0;
            width: auto;
            height: auto;
            z-index: 99;
        }

        .icon-x {
            display: block;
            width: 35px;
            height: 35px;
            padding: 10px;

            @media screen and (min-width: ($mobile + 1)) {
                display: none;
            }
        }
    }
}

// PAGINATION
.pagination {
    &>li {

        &:first-child {

            &>a,
            &>span {
                margin-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:last-child {

            &>a,
            &>span {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &>a,
        &>span {
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 400;
            color: #000000;
            background-color: transparent;
            border: none;
            padding: 12px;
        }

        &>a {

            &:focus,
            &:hover {
                color: #000000;
                background-color: #eaeaea;
                border-color: #eaeaea;
            }
        }

        &>span {

            &:focus,
            &:hover {
                color: #000000;
                background-color: #eaeaea;
                border-color: #eaeaea;
            }
        }
    }

    &>.active {

        &>a,
        &>a:focus,
        &>a:hover,
        &>span,
        &>span:focus,
        &>span:hover {
            font-size: 1.4rem;
            line-height: 1.2rem;
            font-weight: 800;
            letter-spacing: 0.025em;
            color: #000;
            background-color: transparent;
            border-color: transparent;
        }
    }
}

.flex-row.category-pagination {
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.category-pagination span.arrow-left-start {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-left-first.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-right-end {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-right-last.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-next {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-next.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

.category-pagination span.arrow-prev {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-prev.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}

// PAGINATION END

@media (max-width: $tablet-sm) {

    .close {
        &-filters {
            display: none !important;
            position: absolute;
            top: 0;
            right: 0;

            &-icon {
                svg {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin: 5px;

                    .st0 {
                        fill: $black;
                    }
                }
            }
        }
    }
}

ul.pagination,
.load-more {
    display: none;
}

.results-info {
    color: white;
    text-align: center;
}

.basel-spinner:after {
    content: "";
    display: inline-block;
    border: 2px solid #cccccc;
    border-top: 2px solid #333333;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 500ms linear infinite;
}

.basel-spinner.light:after {
    border: 2px solid #cccccc;
    border-top: 2px solid #ffffff;
}

.basel-spinner.ajax-call:after {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -18px auto auto -18px;
}

.basel-spinner.ajax-call {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    display: inline-block;
    background: transparent;
    border-radius: 3px;
    margin: -40px auto auto -40px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: $tablet-sm) {
    body.strip--products {
        .category-listing {
            &__item {
                margin-bottom: 0;

                .card_view {
                    &--image {
                        .overlay_card {
                            display: none;
                        }
                    }

                    &--footer {
                        display: none;
                    }
                }
            }
        }
    }
}

.modal {
    &#bookModal & {
        &-dialog {
            @media screen and (min-width: $tablet){
                width: vw(1635px);
            }
        }

        &-content {
            padding-top: 53px;
            padding-bottom: 20px;

            @media screen and (max-width: $tablet){
                padding-left: 20px;
                padding-right: 20px;
            }

            .modal-close {
                @include transition;
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }

                span {
                    &.icon-close {
                        display: flex;
                        flex-shrink: 0;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.843 23.486'%3E%3Cg data-name='Group 833' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='1.015'%3E%3Cpath data-name='Line 89' d='m.364.354 22.114 22.779'/%3E%3Cpath data-name='Line 90' d='M22.478.354.364 23.133'/%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 22.843px;
                        height: 23.486px;
                    }
                }
            }
        }
    }
}